import { useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";

import { DateSelectArg, EventApi, EventClickArg, EventContentArg } from "@fullcalendar/core";
import CalendarEventModal from "../../lib/organisms/calendarEventModal";
import { apiUrl } from "../../layouts/adminLayout";
import { decodeErrorResponse } from "../../utils/errors";
import { errorPopup, successPopup } from "../../utils/alerts";
import { AdminContextType, useUserContext } from "../../hooks/userUserContext";
import { fetchOptions } from "../../hooks/useAuth";

const BlockedCalendarPage: React.FC = () => {
  const { apartmentOptions } = useUserContext().context as AdminContextType;

  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [currentEvent, setCurrentEvent] = useState<EventApi | null>(null);
  const calendarRef = useRef<FullCalendar>(null);

  const handleCloseModal = () => {
    setCurrentEvent(null);
    setShowModal(false);
  };

  const handleEventDeleted = () => {
    fetch(`${apiUrl}/api/admin/apartments/blocks/${currentEvent?.id}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((response) => {
        if (!response) {
          throw new Error("No response received from the server.");
        }
        if (!response.ok) {
          // if HTTP-status is 404, 500, etc
          throw new Error("" + response.status);
        }
        currentEvent?.remove();
        setCurrentEvent(null);
        setShowModal(false);
        successPopup("Event deleted successfully.");
      })
      .catch((error) => {
        errorPopup(error.message);
      });
  };

  function handleNewEventAdded(apartmentId: number, start: string, end: string, notes: string) {
    fetch(`${apiUrl}/api/admin/apartments/blocks`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        apartmentId,
        start,
        end,
        notes,
      }),
      credentials: "include",
    })
      .then((response) => {
        if (!response) {
          throw new Error("No response received from the server.");
        }
        if (!response.ok) {
          // if HTTP-status is 404, 500, etc
          return decodeErrorResponse(response).then((error) => {
            throw error;
          });
        }
        return response.json();
      })
      .then((data) => {
        const newEvent = {
          id: data.id,
          title: data.apartment,
          start: data.start,
          end: data.end,
          extendedProps: {
            apartmentId: data.apartmentId,
            notes: data.notes,
          },
        };
        let calendarApi = calendarRef.current;
        if (calendarApi) {
          calendarApi.getApi().addEvent(newEvent);
        }
        setShowModal(false);
        successPopup("Event added successfully.");
      })
      .catch((error) => {
        errorPopup("An error occurred while adding the event: " + error.message);
      });
  }

  function handleEventUpdated(eventId: string, apartmentId: number, start: string, end: string, notes: string) {
    fetch(`${apiUrl}/api/admin/apartments/blocks/${eventId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        apartmentId,
        start,
        end,
        notes,
      }),
      credentials: "include",
    })
      .then((response) => {
        if (!response) {
          throw new Error("No response received from the server.");
        }
        if (!response.ok) {
          // if HTTP-status is 404, 500, etc
          return decodeErrorResponse(response).then((error) => {
            throw error;
          });
        }
        return response.json();
      })
      .then((data) => {
        const updatedEvent = {
          id: data.id,
          title: data.apartment,
          start: data.start,
          end: data.end,
          extendedProps: {
            apartmentId: data.apartmentId,
            notes: data.notes,
          },
        };
        let calendarApi = calendarRef.current;
        console.log("Update: " + eventId, updatedEvent);
        if (calendarApi) {
          let existingEvent = calendarApi.getApi().getEventById(eventId);
          console.log("Existing Event: " + eventId, updatedEvent);
          if (existingEvent) {
            existingEvent.remove();
            calendarApi.getApi().addEvent(updatedEvent);
          }
        }
        setShowModal(false);
        successPopup("Event updated successfully.");
      })
      .catch((error) => {
        errorPopup("An error occurred while updating the event: " + error.message);
      });
  }

  function renderEventContent(eventContent: EventContentArg) {
    return (
      <>
        <b>{eventContent.timeText}</b>
        <i>{eventContent.event.title}</i>
      </>
    );
  }

  const handleDateClick = (clickInfo: DateClickArg) => {
    setCurrentEvent(null);
    console.log(clickInfo);
    setSelectedStartDate(clickInfo.dateStr);
    let endDate = new Date(clickInfo.dateStr);
    endDate.setDate(endDate.getDate() + 1);
    let endDateStr = endDate.toISOString().split("T")[0];
    setSelectedEndDate(endDateStr);
    setShowModal(true);
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    setCurrentEvent(clickInfo.event);
    setSelectedStartDate(clickInfo.event.startStr);

    setShowModal(true);
  };

  const handleDateSelect = (selectInfo: DateSelectArg) => {
    setCurrentEvent(null);
    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // clear date selection
    setSelectedStartDate(selectInfo.startStr);
    setSelectedEndDate(selectInfo.endStr);
    setShowModal(true);
  };

  return (
    <div className="card border-0 shadow mb-4 mt-4 p-4">
      <FullCalendar
        ref={calendarRef}
        plugins={[listPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrap5Plugin]}
        headerToolbar={{
          left: "prev,next",
          center: "title",
          right: "listWeek,dayGridMonth",
        }}
        initialView="dayGridMonth"
        themeSystem={"bootstrap5"}
        buttonText={{
          prev: "Previous",
          next: "Next",
        }}
        views={{
          listWeek: {
            buttonText: "list",
          },
          dayGridMonth: {
            buttonText: "calendar",
          },
        }}
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={weekendsVisible}
        select={handleDateSelect}
        eventContent={renderEventContent} // custom render function
        eventClick={handleEventClick}
        dateClick={handleDateClick}
        eventSources={[
          function (fetchInfo, successCallback, failureCallback) {
            fetch(
              `${apiUrl}/api/admin/apartments/blocks?startDate=${fetchInfo.startStr}&endDate=${fetchInfo.endStr}`,
              fetchOptions,
            )
              .then((response) => {
                if (response) {
                  return response.json();
                }
                throw new Error("Unauthorized");
              })
              .then((events) => {
                if (!events) {
                  throw new Error("No response received from the server.");
                }
                successCallback(events);
              })
              .catch((error) => {
                failureCallback(error);
              });
          },
        ]}
      />
      <CalendarEventModal
        show={showModal}
        handleClose={handleCloseModal}
        handleDelete={handleEventDeleted}
        handleAddNew={handleNewEventAdded}
        handleUpdate={handleEventUpdated}
        dateStart={selectedStartDate}
        dateEnd={selectedEndDate}
        existingEvent={currentEvent}
        apartmentOptions={apartmentOptions.filter((option) => option.label !== "All")}
      />
    </div>
  );
};

export default BlockedCalendarPage;
