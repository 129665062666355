import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { bookingManagementApi } from "./bookingManagementApi";
import bookingPaceSlice from "./bookingPaceSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import occupancySlice from "./occupancySlice";

export const store = configureStore({
  reducer: {
    [bookingManagementApi.reducerPath]: bookingManagementApi.reducer,
    bookingPace: bookingPaceSlice,
    occupancy: occupancySlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(bookingManagementApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { useAppDispatch };
