// To parse this data:
//
//   import { Convert, AddApartmentBlockRequest, AddApartmentBlockResponse, AddApartmentGroupRequest, AddApartmentRequest, AddExpenseRequest, AddManagementFeeRatioRequest, AddPayoutRatioRequest, AddReservationRequest, Deposit, GetApartmentGroupStatsResponse, GetApartmentPayoutsResponse, GetApartmentResponse, GetApartmentStatsResponse, GetTotalStatsResponse, ModifyApartmentGroupMembersRequest, Period } from "./file";
//
//   const addApartmentBlockRequest = Convert.toAddApartmentBlockRequest(json);
//   const addApartmentBlockResponse = Convert.toAddApartmentBlockResponse(json);
//   const addApartmentGroupRequest = Convert.toAddApartmentGroupRequest(json);
//   const addApartmentRequest = Convert.toAddApartmentRequest(json);
//   const addExpenseRequest = Convert.toAddExpenseRequest(json);
//   const addManagementFeeRatioRequest = Convert.toAddManagementFeeRatioRequest(json);
//   const addPayoutRatioRequest = Convert.toAddPayoutRatioRequest(json);
//   const addReservationRequest = Convert.toAddReservationRequest(json);
//   const deposit = Convert.toDeposit(json);
//   const getApartmentGroupStatsResponse = Convert.toGetApartmentGroupStatsResponse(json);
//   const getApartmentPayoutsResponse = Convert.toGetApartmentPayoutsResponse(json);
//   const getApartmentResponse = Convert.toGetApartmentResponse(json);
//   const getApartmentStatsResponse = Convert.toGetApartmentStatsResponse(json);
//   const getTotalStatsResponse = Convert.toGetTotalStatsResponse(json);
//   const modifyApartmentGroupMembersRequest = Convert.toModifyApartmentGroupMembersRequest(json);
//   const period = Convert.toPeriod(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface YearAndPeriod {
    year: number;
    period: PeriodName;
}

export enum PeriodName {
    January = "January",
    February = "February",
    March = "March",
    April = "April",
    May = "May",
    June = "June",
    July = "July",
    August = "August",
    September = "September",
    October = "October",
    November = "November",
    December = "December",
    Q1 = "Q1",
    Q2 = "Q2",
    H1 = "H1",
    Q3 = "Q3",
    NineMonths = "NineMonths",
    Q4 = "Q4",
    H2 = "H2",
    Year = "Year",
    Month = "Month",
    NotSet = "NotSet",
    Quarter = "Quarter",
    MonthToDate = "MonthToDate",
    MonthFirstTill10nth = "Month (1 - 10)",
    Month11nthTill20nth = "Month (11 - 20)",
    Month21stTillEnd = "Month (21 - end)",
    QuarterToDate = "QuarterToDate",
    YearToDate = "YearToDate",
}


export interface Apartment {
    dateAdded: Date;
    dateRemoved?: Date;
    groupId: number;
    id: number;
    name: string;
    ownerIds: number[];
    shortName: string;
}

export interface Expense {
    amount: number;
    apartmentId: number;
    categoryId: number;
    date: Date;
    id: number;
    notes: string;
}


export interface AddApartmentBlockRequest {
    apartmentId: number;
    startDate: Date;
    endDate: Date;
}

export interface AddApartmentBlockResponse {
    apartmentBlocks: ApartmentBlockElement[];
}

export interface ApartmentBlockElement {
    apartmentId: number;
    endDate: Date;
    id?: number;
    startDate: Date;
}

export interface AddApartmentGroupRequest {
    name: string;
    description: string;
}

export interface AddApartmentRequest {
    name: string;
    shortName: string;
    dateAdded: Date;
    ownerIds: number[];
    groupId?: number;
}

export interface AddExpenseRequest {
    expenses: ExpenseElement[];
}

export interface ExpenseElement {
    amount: number;
    date: Date;
    notes: string;
    type: string;
}

export interface AddManagementFeeRatioRequest {
    apartmentId: number;
    fromDate: Date;
    ManagementFeeRatio: number;
}

export interface AddPayoutRatioRequest {
    apartmentId: number;
    fromDate: Date;
    payoutRatio: number;
}

export interface AddReservationRequest {
    reservationCode: string;
    id?: number;
    apartmentId: number;
    platform: Platform;
    bookingDate?: Date;
    startDate: Date;
    endDate: Date;
    actualDaysOfStay: number;
    guest: string;
    numberOfGuests: number;
    receivableIncome: number;
    amountPaid?: number;
    paymentDate?: Date;
    commission: number;
    cleanerId?: number;
    cleaningFee?: number;
    cleaningCost?: number;
    hostingExpense?: number;
    epsilon: boolean;
}

export enum Platform {
    Airbnb = 1,
    Booking = 2,
    Out = 3,
}

export interface Deposit {
    apartmentId: number;
    period: Period;
    year: number;
    balance: number;
}

export interface Period {
    periodName: PeriodName;
    periodType: PeriodType;
}


export enum PeriodType {
    Half = "Half",
    Month = "Month",
    NineMonths = "NineMonths",
    NotSet = "NotSet",
    Quarter = "Quarter",
    Year = "Year",
}

export interface GetApartmentGroupStatsResponse {
    averageLengthOfStay: number;
    averageLidr: number;
    cleaningFees: number;
    daysBlocked: number;
    daysBooked: number;
    grossAdr: number;
    grossIncome: number;
    groupId: number;
    netAdr: number;
    netIncome: number;
    occupancy: number;
    platformCommissions: number;
    sustainabilityFee: number;
    temporaryStayDuty: number;
    vat: number;
}

export interface GetApartmentPayoutsResponse {
    apartmentId: number;
    period: Period;
    year: number;
    payoutRatio: number;
    payoutAmount: number;
    managementFee: number;
    depositAmount: number;
}

export interface GetApartmentResponse {
    id: number;
    name: string;
    shortName: string;
    datedAdded: Date;
    ownerIds: number[];
}

export interface GetApartmentStatsResponse {
    apartmentId: number;
    averageLengthOfStay: number;
    averageLidr: number;
    cleaningFees: number;
    daysBlocked: number;
    daysBooked: number;
    grossAdr: number;
    grossIncome: number;
    netAdr: number;
    netIncome: number;
    occupancy: number;
    platformCommissions: number;
    sustainabilityFee: number;
    temporaryStayDuty: number;
    vat: number;
}

export interface GetTotalStatsResponse {
    averageLengthOfStay: number;
    averageLidr: number;
    cleaningFees: number;
    daysBlocked: number;
    daysBooked: number;
    grossAdr: number;
    grossIncome: number;
    netAdr: number;
    netIncome: number;
    occupancy: number;
    platformCommissions: number;
    sustainabilityFee: number;
    temporaryStayDuty: number;
    vat: number;
}

export interface ModifyApartmentGroupMembersRequest {
    op: Op;
    apartmentId: number;
}

export enum Op {
    Add = "add",
    Remove = "remove",
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
    public static toAddApartmentBlockRequest(json: string): AddApartmentBlockRequest {
        return cast(JSON.parse(json), r("AddApartmentBlockRequest"));
    }

    public static addApartmentBlockRequestToJson(value: AddApartmentBlockRequest): string {
        return JSON.stringify(uncast(value, r("AddApartmentBlockRequest")), null, 2);
    }

    public static toAddApartmentBlockResponse(json: string): AddApartmentBlockResponse {
        return cast(JSON.parse(json), r("AddApartmentBlockResponse"));
    }

    public static addApartmentBlockResponseToJson(value: AddApartmentBlockResponse): string {
        return JSON.stringify(uncast(value, r("AddApartmentBlockResponse")), null, 2);
    }

    public static toAddApartmentGroupRequest(json: string): AddApartmentGroupRequest {
        return cast(JSON.parse(json), r("AddApartmentGroupRequest"));
    }

    public static addApartmentGroupRequestToJson(value: AddApartmentGroupRequest): string {
        return JSON.stringify(uncast(value, r("AddApartmentGroupRequest")), null, 2);
    }

    public static toAddApartmentRequest(json: string): AddApartmentRequest {
        return cast(JSON.parse(json), r("AddApartmentRequest"));
    }

    public static addApartmentRequestToJson(value: AddApartmentRequest): string {
        return JSON.stringify(uncast(value, r("AddApartmentRequest")), null, 2);
    }

    public static toAddExpenseRequest(json: string): AddExpenseRequest {
        return cast(JSON.parse(json), r("AddExpenseRequest"));
    }

    public static addExpenseRequestToJson(value: AddExpenseRequest): string {
        return JSON.stringify(uncast(value, r("AddExpenseRequest")), null, 2);
    }

    public static toAddManagementFeeRatioRequest(json: string): AddManagementFeeRatioRequest {
        return cast(JSON.parse(json), r("AddManagementFeeRatioRequest"));
    }

    public static addManagementFeeRatioRequestToJson(value: AddManagementFeeRatioRequest): string {
        return JSON.stringify(uncast(value, r("AddManagementFeeRatioRequest")), null, 2);
    }

    public static toAddPayoutRatioRequest(json: string): AddPayoutRatioRequest {
        return cast(JSON.parse(json), r("AddPayoutRatioRequest"));
    }

    public static addPayoutRatioRequestToJson(value: AddPayoutRatioRequest): string {
        return JSON.stringify(uncast(value, r("AddPayoutRatioRequest")), null, 2);
    }

    public static toAddReservationRequest(json: string): AddReservationRequest {
        return cast(JSON.parse(json), r("AddReservationRequest"));
    }

    public static addReservationRequestToJson(value: AddReservationRequest): string {
        return JSON.stringify(uncast(value, r("AddReservationRequest")), null, 2);
    }

    public static toDeposit(json: string): Deposit {
        return cast(JSON.parse(json), r("Deposit"));
    }

    public static depositToJson(value: Deposit): string {
        return JSON.stringify(uncast(value, r("Deposit")), null, 2);
    }

    public static toGetApartmentGroupStatsResponse(json: string): GetApartmentGroupStatsResponse {
        return cast(JSON.parse(json), r("GetApartmentGroupStatsResponse"));
    }

    public static getApartmentGroupStatsResponseToJson(value: GetApartmentGroupStatsResponse): string {
        return JSON.stringify(uncast(value, r("GetApartmentGroupStatsResponse")), null, 2);
    }

    public static toGetApartmentPayoutsResponse(json: string): GetApartmentPayoutsResponse {
        return cast(JSON.parse(json), r("GetApartmentPayoutsResponse"));
    }

    public static getApartmentPayoutsResponseToJson(value: GetApartmentPayoutsResponse): string {
        return JSON.stringify(uncast(value, r("GetApartmentPayoutsResponse")), null, 2);
    }

    public static toGetApartmentResponse(json: string): GetApartmentResponse {
        return cast(JSON.parse(json), r("GetApartmentResponse"));
    }

    public static getApartmentResponseToJson(value: GetApartmentResponse): string {
        return JSON.stringify(uncast(value, r("GetApartmentResponse")), null, 2);
    }

    public static toGetApartmentStatsResponse(json: string): GetApartmentStatsResponse {
        return cast(JSON.parse(json), r("GetApartmentStatsResponse"));
    }

    public static getApartmentStatsResponseToJson(value: GetApartmentStatsResponse): string {
        return JSON.stringify(uncast(value, r("GetApartmentStatsResponse")), null, 2);
    }

    public static toGetTotalStatsResponse(json: string): GetTotalStatsResponse {
        return cast(JSON.parse(json), r("GetTotalStatsResponse"));
    }

    public static getTotalStatsResponseToJson(value: GetTotalStatsResponse): string {
        return JSON.stringify(uncast(value, r("GetTotalStatsResponse")), null, 2);
    }

    public static toModifyApartmentGroupMembersRequest(json: string): ModifyApartmentGroupMembersRequest {
        return cast(JSON.parse(json), r("ModifyApartmentGroupMembersRequest"));
    }

    public static modifyApartmentGroupMembersRequestToJson(value: ModifyApartmentGroupMembersRequest): string {
        return JSON.stringify(uncast(value, r("ModifyApartmentGroupMembersRequest")), null, 2);
    }

    public static toPeriod(json: string): Period {
        return cast(JSON.parse(json), r("Period"));
    }

    public static periodToJson(value: Period): string {
        return JSON.stringify(uncast(value, r("Period")), null, 2);
    }
}

function invalidValue(typ: any, val: any, key: any, parent: any = ''): never {
    const prettyTyp = prettyTypeName(typ);
    const parentText = parent ? ` on ${parent}` : '';
    const keyText = key ? ` for key "${key}"` : '';
    throw Error(`Invalid value${keyText}${parentText}. Expected ${prettyTyp} but got ${JSON.stringify(val)}`);
}

function prettyTypeName(typ: any): string {
    if (Array.isArray(typ)) {
        if (typ.length === 2 && typ[0] === undefined) {
            return `an optional ${prettyTypeName(typ[1])}`;
        } else {
            return `one of [${typ.map(a => {
                return prettyTypeName(a);
            }).join(", ")}]`;
        }
    } else if (typeof typ === "object" && typ.literal !== undefined) {
        return typ.literal;
    } else {
        return typeof typ;
    }
}

function jsonToJSProps(typ: any): any {
    if (typ.jsonToJS === undefined) {
        const map: any = {};
        typ.props.forEach((p: any) => map[p.json] = {key: p.js, typ: p.typ});
        typ.jsonToJS = map;
    }
    return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
    if (typ.jsToJSON === undefined) {
        const map: any = {};
        typ.props.forEach((p: any) => map[p.js] = {key: p.json, typ: p.typ});
        typ.jsToJSON = map;
    }
    return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = '', parent: any = ''): any {
    function transformPrimitive(typ: string, val: any): any {
        if (typeof typ === typeof val) return val;
        return invalidValue(typ, val, key, parent);
    }

    function transformUnion(typs: any[], val: any): any {
        // val must validate against one typ in typs
        const l = typs.length;
        for (let i = 0; i < l; i++) {
            const typ = typs[i];
            try {
                return transform(val, typ, getProps);
            } catch (_) {
            }
        }
        return invalidValue(typs, val, key, parent);
    }

    function transformEnum(cases: string[], val: any): any {
        if (cases.indexOf(val) !== -1) return val;
        return invalidValue(cases.map(a => {
            return l(a);
        }), val, key, parent);
    }

    function transformArray(typ: any, val: any): any {
        // val must be an array with no invalid elements
        if (!Array.isArray(val)) return invalidValue(l("array"), val, key, parent);
        return val.map(el => transform(el, typ, getProps));
    }

    function transformDate(val: any): any {
        if (val === null) {
            return null;
        }
        const d = new Date(val);
        if (isNaN(d.valueOf())) {
            return invalidValue(l("Date"), val, key, parent);
        }
        return d;
    }

    function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
        if (val === null || typeof val !== "object" || Array.isArray(val)) {
            return invalidValue(l(ref || "object"), val, key, parent);
        }
        const result: any = {};
        Object.getOwnPropertyNames(props).forEach(key => {
            const prop = props[key];
            const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
            result[prop.key] = transform(v, prop.typ, getProps, key, ref);
        });
        Object.getOwnPropertyNames(val).forEach(key => {
            if (!Object.prototype.hasOwnProperty.call(props, key)) {
                result[key] = transform(val[key], additional, getProps, key, ref);
            }
        });
        return result;
    }

    if (typ === "any") return val;
    if (typ === null) {
        if (val === null) return val;
        return invalidValue(typ, val, key, parent);
    }
    if (typ === false) return invalidValue(typ, val, key, parent);
    let ref: any = undefined;
    while (typeof typ === "object" && typ.ref !== undefined) {
        ref = typ.ref;
        typ = typeMap[typ.ref];
    }
    if (Array.isArray(typ)) return transformEnum(typ, val);
    if (typeof typ === "object") {
        return typ.hasOwnProperty("unionMembers") ? transformUnion(typ.unionMembers, val)
            : typ.hasOwnProperty("arrayItems") ? transformArray(typ.arrayItems, val)
                : typ.hasOwnProperty("props") ? transformObject(getProps(typ), typ.additional, val)
                    : invalidValue(typ, val, key, parent);
    }
    // Numbers can be parsed by Date but shouldn't be.
    if (typ === Date && typeof val !== "number") return transformDate(val);
    return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
    return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
    return transform(val, typ, jsToJSONProps);
}

function l(typ: any) {
    return {literal: typ};
}

function a(typ: any) {
    return {arrayItems: typ};
}

function u(...typs: any[]) {
    return {unionMembers: typs};
}

function o(props: any[], additional: any) {
    return {props, additional};
}

function r(name: string) {
    return {ref: name};
}

const typeMap: any = {
    "AddApartmentBlockRequest": o([
        {json: "apartmentId", js: "apartmentId", typ: 0},
        {json: "startDate", js: "startDate", typ: Date},
        {json: "endDate", js: "endDate", typ: Date},
    ], false),
    "AddApartmentBlockResponse": o([
        {json: "apartmentBlocks", js: "apartmentBlocks", typ: a(r("ApartmentBlockElement"))},
    ], false),
    "ApartmentBlockElement": o([
        {json: "apartmentId", js: "apartmentId", typ: 3.14},
        {json: "endDate", js: "endDate", typ: Date},
        {json: "id", js: "id", typ: u(undefined, 3.14)},
        {json: "startDate", js: "startDate", typ: Date},
    ], false),
    "AddApartmentGroupRequest": o([
        {json: "name", js: "name", typ: ""},
        {json: "description", js: "description", typ: ""},
    ], false),
    "AddApartmentRequest": o([
        {json: "name", js: "name", typ: ""},
        {json: "shortName", js: "shortName", typ: ""},
        {json: "dateAdded", js: "dateAdded", typ: Date},
        {json: "ownerIds", js: "ownerIds", typ: a(0)},
        {json: "groupId", js: "groupId", typ: u(undefined, 0)},
    ], false),
    "AddExpenseRequest": o([
        {json: "expenses", js: "expenses", typ: a(r("ExpenseElement"))},
    ], false),
    "ExpenseElement": o([
        {json: "amount", js: "amount", typ: 3.14},
        {json: "date", js: "date", typ: Date},
        {json: "notes", js: "notes", typ: ""},
        {json: "type", js: "type", typ: ""},
    ], false),
    "AddManagementFeeRatioRequest": o([
        {json: "apartmentId", js: "apartmentId", typ: 0},
        {json: "fromDate", js: "fromDate", typ: Date},
        {json: "ManagementFeeRatio", js: "ManagementFeeRatio", typ: 3.14},
    ], false),
    "AddPayoutRatioRequest": o([
        {json: "apartmentId", js: "apartmentId", typ: 0},
        {json: "fromDate", js: "fromDate", typ: Date},
        {json: "payoutRatio", js: "payoutRatio", typ: 3.14},
    ], false),
    "AddReservationRequest": o([
        {json: "reservationCode", js: "reservationCode", typ: ""},
        {json: "id", js: "id", typ: u(undefined, 0)},
        {json: "apartmentId", js: "apartmentId", typ: 0},
        {json: "platform", js: "platform", typ: r("Platform")},
        {json: "bookingDate", js: "bookingDate", typ: u(undefined, Date)},
        {json: "startDate", js: "startDate", typ: Date},
        {json: "endDate", js: "endDate", typ: Date},
        {json: "actualDaysOfStay", js: "actualDaysOfStay", typ: 0},
        {json: "guest", js: "guest", typ: ""},
        {json: "numberOfGuests", js: "numberOfGuests", typ: 0},
        {json: "receivableIncome", js: "receivableIncome", typ: 3.14},
        {json: "amountPaid", js: "amountPaid", typ: u(undefined, 3.14)},
        {json: "paymentDate", js: "paymentDate", typ: u(undefined, Date)},
        {json: "commission", js: "commission", typ: 3.14},
        {json: "cleanerId", js: "cleanerId", typ: u(undefined, 0)},
        {json: "cleaningFee", js: "cleaningFee", typ: u(undefined, 3.14)},
        {json: "cleaningCost", js: "cleaningCost", typ: u(undefined, 3.14)},
        {json: "hostingExpense", js: "hostingExpense", typ: u(undefined, 3.14)},
        {json: "epsilon", js: "epsilon", typ: true},
    ], false),
    "Deposit": o([
        {json: "apartmentId", js: "apartmentId", typ: 0},
        {json: "period", js: "period", typ: r("Period")},
        {json: "year", js: "year", typ: 0},
        {json: "balance", js: "balance", typ: 3.14},
    ], false),
    "Period": o([
        {json: "periodName", js: "periodName", typ: r("PeriodName")},
        {json: "periodType", js: "periodType", typ: r("PeriodType")},
    ], false),
    "GetApartmentGroupStatsResponse": o([
        {json: "averageLengthOfStay", js: "averageLengthOfStay", typ: 3.14},
        {json: "averageLidr", js: "averageLidr", typ: 3.14},
        {json: "cleaningFees", js: "cleaningFees", typ: 3.14},
        {json: "daysBlocked", js: "daysBlocked", typ: 0},
        {json: "daysBooked", js: "daysBooked", typ: 0},
        {json: "grossAdr", js: "grossAdr", typ: 3.14},
        {json: "grossIncome", js: "grossIncome", typ: 3.14},
        {json: "groupId", js: "groupId", typ: 0},
        {json: "netAdr", js: "netAdr", typ: 3.14},
        {json: "netIncome", js: "netIncome", typ: 3.14},
        {json: "occupancy", js: "occupancy", typ: 3.14},
        {json: "platformCommissions", js: "platformCommissions", typ: 3.14},
        {json: "sustainabilityFee", js: "sustainabilityFee", typ: 3.14},
        {json: "temporaryStayDuty", js: "temporaryStayDuty", typ: 3.14},
        {json: "vat", js: "vat", typ: 3.14},
    ], false),
    "GetApartmentPayoutsResponse": o([
        {json: "apartmentId", js: "apartmentId", typ: 0},
        {json: "period", js: "period", typ: r("Period")},
        {json: "year", js: "year", typ: 0},
        {json: "payoutRatio", js: "payoutRatio", typ: 3.14},
        {json: "payoutAmount", js: "payoutAmount", typ: 3.14},
        {json: "managementFee", js: "managementFee", typ: 3.14},
        {json: "depositAmount", js: "depositAmount", typ: 3.14},
    ], false),
    "GetApartmentResponse": o([
        {json: "id", js: "id", typ: 0},
        {json: "name", js: "name", typ: ""},
        {json: "shortName", js: "shortName", typ: ""},
        {json: "datedAdded", js: "datedAdded", typ: Date},
        {json: "ownerIds", js: "ownerIds", typ: a(0)},
    ], false),
    "GetApartmentStatsResponse": o([
        {json: "apartmentId", js: "apartmentId", typ: 0},
        {json: "averageLengthOfStay", js: "averageLengthOfStay", typ: 3.14},
        {json: "averageLidr", js: "averageLidr", typ: 3.14},
        {json: "cleaningFees", js: "cleaningFees", typ: 3.14},
        {json: "daysBlocked", js: "daysBlocked", typ: 0},
        {json: "daysBooked", js: "daysBooked", typ: 0},
        {json: "grossAdr", js: "grossAdr", typ: 3.14},
        {json: "grossIncome", js: "grossIncome", typ: 3.14},
        {json: "netAdr", js: "netAdr", typ: 3.14},
        {json: "netIncome", js: "netIncome", typ: 3.14},
        {json: "occupancy", js: "occupancy", typ: 3.14},
        {json: "platformCommissions", js: "platformCommissions", typ: 3.14},
        {json: "sustainabilityFee", js: "sustainabilityFee", typ: 3.14},
        {json: "temporaryStayDuty", js: "temporaryStayDuty", typ: 3.14},
        {json: "vat", js: "vat", typ: 3.14},
    ], false),
    "GetTotalStatsResponse": o([
        {json: "averageLengthOfStay", js: "averageLengthOfStay", typ: 3.14},
        {json: "averageLidr", js: "averageLidr", typ: 3.14},
        {json: "cleaningFees", js: "cleaningFees", typ: 3.14},
        {json: "daysBlocked", js: "daysBlocked", typ: 0},
        {json: "daysBooked", js: "daysBooked", typ: 0},
        {json: "grossAdr", js: "grossAdr", typ: 3.14},
        {json: "grossIncome", js: "grossIncome", typ: 3.14},
        {json: "netAdr", js: "netAdr", typ: 3.14},
        {json: "netIncome", js: "netIncome", typ: 3.14},
        {json: "occupancy", js: "occupancy", typ: 3.14},
        {json: "platformCommissions", js: "platformCommissions", typ: 3.14},
        {json: "sustainabilityFee", js: "sustainabilityFee", typ: 3.14},
        {json: "temporaryStayDuty", js: "temporaryStayDuty", typ: 3.14},
        {json: "vat", js: "vat", typ: 3.14},
    ], false),
    "ModifyApartmentGroupMembersRequest": o([
        {json: "op", js: "op", typ: r("Op")},
        {json: "apartmentId", js: "apartmentId", typ: 0},
    ], false),
    "Platform": [
        "airbnb",
        "booking",
        "out",
    ],
    "PeriodName": [
        "April",
        "August",
        "December",
        "February",
        "H1",
        "H2",
        "January",
        "July",
        "June",
        "March",
        "May",
        "Month",
        "NineMonths",
        "NotSet",
        "November",
        "October",
        "Q1",
        "Q2",
        "Q3",
        "Q4",
        "Quarter",
        "September",
        "Year",
    ],
    "PeriodType": [
        "Half",
        "Month",
        "NineMonths",
        "NotSet",
        "Quarter",
        "Year",
    ],
    "Op": [
        "add",
        "remove",
    ],
};
