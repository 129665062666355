import Select from "../lib/molecules/select";
import React, { useEffect } from "react";
import { apiUrl } from "../layouts/adminLayout";
import BarChartStatsCard from "../lib/molecules/barChartStatsCard";
import OccupancyChartStatsCard from "../lib/molecules/occupancyChartStatsCard";
import { PeriodName } from "../models";
import { Convert, OverviewStats } from "../models/responses";
import {
  overViewAdrFormatter,
  overViewBarChartAdrFormatter,
  overViewBarChartIncomeFormatter,
  overViewIncomeFormatter,
} from "../utils/formatters";
import { isPreviousPeriodSameAsPreviousYear, periodNameToOverviewCardString } from "../utils/periods";
import { AdminContextType, useUserContext } from "../hooks/userUserContext";
import { fetchOptions } from "../hooks/useAuth";

const OverviewPage = () => {
  const {
    periodOptions = [],
    yearOptions = [],
    selectedPeriod,
    setSelectedPeriod,
    selectedYear,
    setSelectedYear,
    groupIdMap,
  } = useUserContext().context as AdminContextType;

  const [overviewStats, setOverviewStats] = React.useState<OverviewStats | undefined>(undefined);

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(Number(event.target.value));
  };

  const handlePeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPeriod(event.target.value as PeriodName);
  };

  const chartOptionsFor = (attribute: string) => {
    return overviewStats === undefined
      ? []
      : overviewStats.groupStats
          .map((groupStat) => {
            return {
              name: groupIdMap.get(groupStat.groupId)?.name || "",
              value: groupStat[attribute] || 0,
            };
          })
          .sort((a, b) => a.name.localeCompare(b.name));
  };

  useEffect(() => {
    let url = `${apiUrl}/api/admin/overview?year=` + selectedYear + "&period=" + selectedPeriod;
    fetch(url, fetchOptions)
      .then((response) => {
        if (response) {
          return response.text();
        }
        throw new Error("Unauthorized");
      })
      .then((response) => {
        console.log("Response. selectedPeriod: " + selectedPeriod);
        console.log("Response. selectedYear: " + selectedYear);
        let overviewStats = Convert.toGetOverviewStatsResponse(response).overviewStats;
        setOverviewStats(overviewStats);
      })
      .catch((error) => console.log(error));
  }, [selectedPeriod, selectedYear]);

  return (
    <>
      <div className="row d-flex justify-content-end align-items-end">
        <div className="col-md-4 d-flex justify-content-end">
          <div className="d-flex flex-column me-2">
            <Select
              color={"secondary"}
              onChange={handlePeriodChange}
              name={"Period"}
              value={selectedPeriod}
              options={periodOptions || []}
            />
          </div>
          <div className="d-flex flex-column me-2">
            <Select
              color={"secondary"}
              onChange={handleYearChange}
              name={"Year"}
              value={selectedYear.toString()}
              options={yearOptions || []}
            />
          </div>
        </div>
      </div>
      <div className="row mt-4 d-flex justify-content-center">
        <div className="col-12 col-sm-12 col-xl-3 col-md-12 col-lg-6">
          <OccupancyChartStatsCard
            metric={"Occupancy"}
            totalOccupancy={overviewStats?.totalStats.occupancy || 0}
            groupOccupancies={chartOptionsFor("occupancy")}
            groupOccupanciesPreviousPeriod={chartOptionsFor("occupancyPrevious")}
            groupOccupanciesPreviousYear={chartOptionsFor("occupancyPreviousYear")}
            period={periodNameToOverviewCardString(selectedYear, PeriodName[selectedPeriod as keyof typeof PeriodName])}
            showPreviousYear={!isPreviousPeriodSameAsPreviousYear(selectedPeriod)}
            totalPreviousPeriod={overviewStats?.totalStats.occupancyPrevious || 0}
            totalPreviousYear={overviewStats?.totalStats.occupancyPreviousYear || 0}
          />
        </div>
        <div className="col-12 col-sm-12 col-xl-3 col-md-12 col-lg-6">
          <BarChartStatsCard
            metric={"Gross Income"}
            total={overviewStats?.totalStats.grossIncome || 0}
            totalValueFormatter={overViewIncomeFormatter}
            barValueFormatter={overViewBarChartIncomeFormatter}
            data={chartOptionsFor("grossIncome")}
            dataPreviousPeriod={chartOptionsFor("grossIncomePrevious")}
            dataPreviousYear={chartOptionsFor("grossIncomePreviousYear")}
            period={periodNameToOverviewCardString(selectedYear, PeriodName[selectedPeriod as keyof typeof PeriodName])}
            showPreviousYear={!isPreviousPeriodSameAsPreviousYear(selectedPeriod)}
            totalPreviousPeriod={overviewStats?.totalStats.grossIncomePrevious || 0}
            totalPreviousYear={overviewStats?.totalStats.grossIncomePreviousYear || 0}
          />
        </div>
        <div className="col-12 col-sm-12 col-xl-3 col-md-12 col-lg-6">
          <BarChartStatsCard
            metric={"Net Income"}
            total={overviewStats?.totalStats.netIncome || 0}
            totalValueFormatter={overViewIncomeFormatter}
            data={chartOptionsFor("netIncome")}
            dataPreviousPeriod={chartOptionsFor("netIncomePrevious")}
            dataPreviousYear={chartOptionsFor("netIncomePreviousYear")}
            barValueFormatter={overViewBarChartIncomeFormatter}
            period={periodNameToOverviewCardString(selectedYear, PeriodName[selectedPeriod as keyof typeof PeriodName])}
            showPreviousYear={!isPreviousPeriodSameAsPreviousYear(selectedPeriod)}
            totalPreviousPeriod={overviewStats?.totalStats.netIncomePrevious || 0}
            totalPreviousYear={overviewStats?.totalStats.netIncomePreviousYear || 0}
          />
        </div>

        <div className="col-12 col-sm-12 col-xl-3 col-md-12 col-lg-6">
          <BarChartStatsCard
            metric={"Gross Adr"}
            total={overviewStats?.totalStats.grossAdr || 0}
            totalValueFormatter={overViewAdrFormatter}
            data={chartOptionsFor("grossAdr")}
            dataPreviousPeriod={chartOptionsFor("grossAdrPrevious")}
            dataPreviousYear={chartOptionsFor("grossAdrPreviousYear")}
            barValueFormatter={overViewBarChartAdrFormatter}
            period={periodNameToOverviewCardString(selectedYear, PeriodName[selectedPeriod as keyof typeof PeriodName])}
            showPreviousYear={!isPreviousPeriodSameAsPreviousYear(selectedPeriod)}
            totalPreviousPeriod={overviewStats?.totalStats.grossAdrPrevious || 0}
            totalPreviousYear={overviewStats?.totalStats.grossAdrPreviousYear || 0}
          />
        </div>
      </div>
      <div className="row mt-3 d-flex justify-content-center">
        <div className="col-12 col-sm-12 col-xl-3 col-md-12 col-lg-6">
          <BarChartStatsCard
            metric={"Days Booked"}
            total={overviewStats?.totalStats.daysBooked || 0}
            totalValueFormatter={(x) => x.toString()}
            data={chartOptionsFor("daysBooked")}
            dataPreviousPeriod={chartOptionsFor("daysBookedPrevious")}
            dataPreviousYear={chartOptionsFor("daysBookedPreviousYear")}
            barValueFormatter={(x) => x.toString()}
            period={periodNameToOverviewCardString(selectedYear, PeriodName[selectedPeriod as keyof typeof PeriodName])}
            showPreviousYear={!isPreviousPeriodSameAsPreviousYear(selectedPeriod)}
            totalPreviousPeriod={overviewStats?.totalStats.daysBookedPrevious || 0}
            totalPreviousYear={overviewStats?.totalStats.daysBookedPreviousYear || 0}
          />
        </div>
        <div className="col-12 col-sm-12 col-xl-3 col-md-12 col-lg-6">
          <BarChartStatsCard
            metric={"Bookings Received"}
            total={overviewStats?.totalStats.bookingsReceived || 0}
            totalValueFormatter={(x) => x.toString()}
            data={chartOptionsFor("bookingsReceived")}
            dataPreviousPeriod={chartOptionsFor("bookingsReceivedPrevious")}
            dataPreviousYear={chartOptionsFor("bookingsReceivedPreviousYear")}
            barValueFormatter={(x) => x.toString()}
            period={periodNameToOverviewCardString(selectedYear, PeriodName[selectedPeriod as keyof typeof PeriodName])}
            showPreviousYear={!isPreviousPeriodSameAsPreviousYear(selectedPeriod)}
            totalPreviousPeriod={overviewStats?.totalStats.bookingsReceivedPrevious || 0}
            totalPreviousYear={overviewStats?.totalStats.bookingsReceivedPreviousYear || 0}
          />
        </div>
        <div className="col-12 col-sm-12 col-xl-3 col-md-12 col-lg-6">
          <BarChartStatsCard
            metric={"Average Length of Stay"}
            total={overviewStats?.totalStats.averageLengthOfStay || 0}
            totalValueFormatter={(x) => x.toFixed(1)}
            data={chartOptionsFor("averageLengthOfStay")}
            dataPreviousPeriod={chartOptionsFor("averageLengthOfStayPrevious")}
            dataPreviousYear={chartOptionsFor("averageLengthOfStayPreviousYear")}
            barValueFormatter={(x) => x.toFixed(1)}
            period={periodNameToOverviewCardString(selectedYear, PeriodName[selectedPeriod as keyof typeof PeriodName])}
            showPreviousYear={!isPreviousPeriodSameAsPreviousYear(selectedPeriod)}
            totalPreviousPeriod={overviewStats?.totalStats.averageLengthOfStayPrevious || 0}
            totalPreviousYear={overviewStats?.totalStats.averageLengthOfStayPreviousYear || 0}
          />
        </div>
        <div className="col-12 col-sm-12 col-xl-3 col-md-12 col-lg-6">
          <BarChartStatsCard
            metric={"Net Adr"}
            total={overviewStats?.totalStats.netAdr || 0}
            totalValueFormatter={overViewAdrFormatter}
            data={chartOptionsFor("netAdr")}
            dataPreviousPeriod={chartOptionsFor("netAdrPrevious")}
            dataPreviousYear={chartOptionsFor("netAdrPreviousYear")}
            barValueFormatter={overViewBarChartAdrFormatter}
            period={periodNameToOverviewCardString(selectedYear, PeriodName[selectedPeriod as keyof typeof PeriodName])}
            showPreviousYear={!isPreviousPeriodSameAsPreviousYear(selectedPeriod)}
            totalPreviousPeriod={overviewStats?.totalStats.netAdrPrevious || 0}
            totalPreviousYear={overviewStats?.totalStats.netAdrPreviousYear || 0}
          />
        </div>
      </div>
      <div className="row mt-3 d-flex justify-content-center">
        <div className="col-12 col-sm-12 col-xl-3 col-md-12 col-lg-6">
          <BarChartStatsCard
            metric={"Average LIDR"}
            total={overviewStats?.totalStats.lidr || 0}
            totalValueFormatter={(x) => x.toFixed(1)}
            data={chartOptionsFor("lidr")}
            dataPreviousPeriod={chartOptionsFor("lidrPrevious")}
            dataPreviousYear={chartOptionsFor("lidrPreviousYear")}
            barValueFormatter={(x) => x.toFixed(1)}
            period={periodNameToOverviewCardString(selectedYear, PeriodName[selectedPeriod as keyof typeof PeriodName])}
            showPreviousYear={!isPreviousPeriodSameAsPreviousYear(selectedPeriod)}
            totalPreviousPeriod={overviewStats?.totalStats.lidrPrevious || 0}
            totalPreviousYear={overviewStats?.totalStats.lidrPreviousYear || 0}
          />
        </div>
      </div>
    </>
  );
};

export default OverviewPage;
