import {
  faCalendarDays,
  faChartLine,
  faDollarSign,
  faHashtag,
  faUpload,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";

export enum FaIconName {
  DollarSign = "DollarSign",
  ChartLine = "ChartLine",
  Upload = "Upload",
  Download = "Download",
  CalendarDays = "CalendarDays",
  HashTag = "HashTag",
}

export function faIcon(name: FaIconName): IconDefinition {
  switch (name) {
    case FaIconName.DollarSign:
      return faDollarSign;
    case FaIconName.ChartLine:
      return faChartLine;
    case FaIconName.Upload:
      return faUpload;
    case FaIconName.Download:
      return faDownload;
    case FaIconName.CalendarDays:
      return faCalendarDays;
    case FaIconName.HashTag:
      return faHashtag;
  }
}
