import { GridDropdownFilter } from "../../lib/organisms/Grid";
import React from "react";
import Select from "../../lib/molecules/select";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { setDate, setSelectedApartment, setSelectedGroup } from "../../store/occupancySlice";
import { AdminContextType, useUserContext } from "../../hooks/userUserContext";
import { useGetOccupanciesQuery } from "../../store/bookingManagementApi";
import { renderError } from "../../utils/errors";

export const OccupanciesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { groupOptions, apartmentOptions } = useUserContext().context as AdminContextType;

  const { date, selectedGroup, selectedApartment } = useAppSelector((state) => state.occupancy);

  const dropdownFilters: GridDropdownFilter[] = [
    {
      label: "Group",
      options: groupOptions,
      selectedOption: selectedGroup.toString(),
      onSelectionChanged: (group) => {
        dispatch(setSelectedGroup(Number(group)));
      },
    },
    {
      label: "Apartment",
      options: apartmentOptions,
      selectedOption: selectedApartment.toString(),
      onSelectionChanged: (apartment) => dispatch(setSelectedApartment(Number(apartment))),
    },
  ];

  const {
    data: occupancies,
    isLoading,
    error,
  } = useGetOccupanciesQuery({
    date: date.toISOString().split("T")[0],
    apartmentId: selectedApartment,
    groupId: selectedGroup,
  });

  const {
    data: occupanciesPrevious,
    isLoading: isLoadingPrevious,
    error: errorPrevious,
  } = useGetOccupanciesQuery({
    date: new Date(date.getFullYear() - 1, date.getMonth(), date.getDate()).toISOString().split("T")[0],
    apartmentId: selectedApartment,
    groupId: selectedGroup,
  });

  return (
    <>
      <div className="row d-flex justify-content-end">
        <span className="h3">Occupancy Explorer</span>
        <div className="col-auto d-flex">
          {dropdownFilters.map((filter) => (
            <div key={filter.label} className="d-flex flex-column me-2">
              <Select
                color={"secondary"}
                onChange={(event) => filter.onSelectionChanged(event.target.value)}
                name={filter.label}
                value={filter.selectedOption}
                options={filter.options}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="card col-12 col-xxl-4 mt-2 mb-3">
        <div className="card-body">
          <div className="row">
            <div className={"d-flex justify-content-between small mb-0"}>
              <span>{`Bookings received until:`} </span>
              <span>
                <input
                  className={"border-0"}
                  type={"date"}
                  onChange={(e) => dispatch(setDate(new Date(e.target.value)))}
                  value={date?.toISOString().split("T")[0]}
                ></input>
              </span>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <div className="text-center mt-5">Loading...</div>}
      {error && renderError(error)}
      {occupancies && (
        <div className="card col-12 col-xxl-4 mt-2">
          <div className="card-body">
            <div className="row">
              <div className={"d-flex justify-content-between small mb-0"}></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
