import { PeriodName } from "../models";

export function getPeriodMonth(number: number) {
  switch (number) {
    case 1:
      return PeriodName.January;
    case 2:
      return PeriodName.February;
    case 3:
      return PeriodName.March;
    case 4:
      return PeriodName.April;
    case 5:
      return PeriodName.May;
    case 6:
      return PeriodName.June;
    case 7:
      return PeriodName.July;
    case 8:
      return PeriodName.August;
    case 9:
      return PeriodName.September;
    case 10:
      return PeriodName.October;
    case 11:
      return PeriodName.November;
    case 12:
      return PeriodName.December;
    default:
      return PeriodName.January;
  }
}

export function getPeriodQuarter(number: number) {
  switch (number) {
    case 1:
      return PeriodName.Q1;
    case 2:
      return PeriodName.Q2;
    case 3:
      return PeriodName.Q3;
    case 4:
      return PeriodName.Q4;
    default:
      return PeriodName.Q1;
  }
}

export function isPreviousPeriodSameAsPreviousYear(period: string): boolean {
  return period === PeriodName.Year || period === PeriodName.YearToDate || period === PeriodName.NineMonths;
}

export function toPeriodName(periodName: string): PeriodName {
  if (periodName in PeriodName) {
    return PeriodName[periodName as keyof typeof PeriodName];
  }
  return PeriodName.Quarter;
}

function findCurrentQuarter(period: PeriodName.Quarter) {
  const currentMonth = new Date().getMonth();
  const currentQuarter = Math.floor(currentMonth / 3);
  return getPeriodQuarter(currentQuarter);
}

function getMonthPeriodsFromQuarter(
  period: PeriodName.Q1 | PeriodName.Q2 | PeriodName.Q3 | PeriodName.Q4 | PeriodName.Quarter,
): PeriodName[] {
  switch (period) {
    case PeriodName.Q1:
      return [PeriodName.January, PeriodName.February, PeriodName.March];
    case PeriodName.Q2:
      return [PeriodName.April, PeriodName.May, PeriodName.June];
    case PeriodName.Q3:
      return [PeriodName.July, PeriodName.August, PeriodName.September];
    case PeriodName.Q4:
      return [PeriodName.October, PeriodName.November, PeriodName.December];
    case PeriodName.Quarter:
      return getMonthPeriodsFromQuarter(findCurrentQuarter(period));
  }
}

export interface DatePair {
  startDate: Date;
  endDate: Date;
}

function getWeeksInMonth(currentMonth: number, currentYear: number): DatePair[] {
  const firstDay = new Date(currentYear, currentMonth, 1);
  const lastDay = new Date(currentYear, currentMonth + 1, 0);
  return [
    { startDate: firstDay, endDate: new Date(currentYear, currentMonth, 7) },
    { startDate: new Date(currentYear, currentMonth, 8), endDate: new Date(currentYear, currentMonth, 14) },
    { startDate: new Date(currentYear, currentMonth, 15), endDate: new Date(currentYear, currentMonth, 21) },
    { startDate: new Date(currentYear, currentMonth, 22), endDate: lastDay },
  ];
}

function getWeekPeriodsFromMonth(period: PeriodName): DatePair[] {
  const currentYear = new Date().getFullYear();
  switch (period) {
    case PeriodName.January:
      return getWeeksInMonth(0, currentYear);
    case PeriodName.February:
      return getWeeksInMonth(1, currentYear);
    case PeriodName.March:
      return getWeeksInMonth(2, currentYear);
    case PeriodName.April:
      return getWeeksInMonth(3, currentYear);
    case PeriodName.May:
      return getWeeksInMonth(4, currentYear);
    case PeriodName.June:
      return getWeeksInMonth(5, currentYear);
    case PeriodName.July:
      return getWeeksInMonth(6, currentYear);
    case PeriodName.August:
      return getWeeksInMonth(7, currentYear);
    case PeriodName.September:
      return getWeeksInMonth(8, currentYear);
    case PeriodName.October:
      return getWeeksInMonth(9, currentYear);
    case PeriodName.November:
      return getWeeksInMonth(10, currentYear);
    case PeriodName.December:
      return getWeeksInMonth(11, currentYear);
    default:
      let currentMonth = new Date().getMonth();
      return getWeeksInMonth(currentMonth, currentYear);
  }
}

export function periodBreakdown(period: PeriodName): PeriodName[] | DatePair[] {
  switch (period) {
    case PeriodName.Quarter ||
      PeriodName.Q1 ||
      PeriodName.Q2 ||
      PeriodName.Q3 ||
      PeriodName.Q4 ||
      PeriodName.QuarterToDate:
      return getMonthPeriodsFromQuarter(period);
    case PeriodName.Year || PeriodName.YearToDate:
      return [PeriodName.Q1, PeriodName.Q2, PeriodName.Q3, PeriodName.Q4];
    case PeriodName.H1:
      return [PeriodName.Q1, PeriodName.Q2];
    case PeriodName.H2:
      return [PeriodName.Q3, PeriodName.Q4];
    case PeriodName.NineMonths:
      return [PeriodName.Q1, PeriodName.Q2, PeriodName.Q3];
    default:
      return getWeekPeriodsFromMonth(period);
  }
}

export function getNextMonth(date: Date): Date {
  if (date.getMonth() === 11) {
    return new Date(date.getFullYear() + 1, 0, 1);
  }
  return new Date(date.getFullYear(), date.getMonth() + 1, 1);
}

export function getPeriodStartAndEndDates(year: number, period: PeriodName): [Date, Date] {
  let start: Date;
  let end: Date;
  let timeNow = new Date();

  switch (period) {
    case PeriodName.January:
      start = new Date(year, 0, 1);
      end = new Date(year, 0, 31);
      break;
    case PeriodName.February:
      start = new Date(year, 1, 1);
      end = new Date(year, 2, 0);
      break;
    case PeriodName.March:
      start = new Date(year, 2, 1);
      end = new Date(year, 2, 31);
      break;
    case PeriodName.April:
      start = new Date(year, 3, 1);
      end = new Date(year, 3, 30);
      break;
    case PeriodName.May:
      start = new Date(year, 4, 1);
      end = new Date(year, 4, 31);
      break;
    case PeriodName.June:
      start = new Date(year, 5, 1);
      end = new Date(year, 5, 30);
      break;
    case PeriodName.July:
      start = new Date(year, 6, 1);
      end = new Date(year, 6, 31);
      break;
    case PeriodName.August:
      start = new Date(year, 7, 1);
      end = new Date(year, 7, 31);
      break;
    case PeriodName.September:
      start = new Date(year, 8, 1);
      end = new Date(year, 8, 30);
      break;
    case PeriodName.October:
      start = new Date(year, 9, 1);
      end = new Date(year, 9, 31);
      break;
    case PeriodName.November:
      start = new Date(year, 10, 1);
      end = new Date(year, 10, 30);
      break;
    case PeriodName.December:
      start = new Date(year, 11, 1);
      end = new Date(year, 11, 31);
      break;
    case PeriodName.Q1:
      start = new Date(year, 0, 1);
      end = new Date(year, 2, 31);
      break;
    case PeriodName.Q2:
      start = new Date(year, 3, 1);
      end = new Date(year, 5, 30);
      break;
    case PeriodName.Q3:
      start = new Date(year, 6, 1);
      end = new Date(year, 8, 30);
      break;
    case PeriodName.Q4:
      start = new Date(year, 9, 1);
      end = new Date(year, 11, 31);
      break;
    case PeriodName.H1:
      start = new Date(year, 0, 1);
      end = new Date(year, 5, 30);
      break;
    case PeriodName.H2:
      start = new Date(year, 6, 1);
      end = new Date(year, 11, 31);
      break;
    case PeriodName.NineMonths:
      start = new Date(year, 0, 1);
      end = new Date(year, 8, 30);
      break;
    case PeriodName.Year:
      start = new Date(year, 0, 1);
      end = new Date(year, 11, 31);
      break;
    case PeriodName.Quarter:
      start = new Date(timeNow.getFullYear(), Math.floor(timeNow.getMonth() / 3) * 3, 1);
      let nextQuarterStartDate = new Date(timeNow.getFullYear(), Math.floor(timeNow.getMonth() / 3) * 3 + 3, 1);
      nextQuarterStartDate.setDate(nextQuarterStartDate.getDate() - 1);
      end = nextQuarterStartDate;
      break;
    case PeriodName.YearToDate:
      start = new Date(year, 0, 1);
      end = timeNow;
      break;
    case PeriodName.MonthToDate:
      start = new Date(timeNow.getFullYear(), timeNow.getMonth(), 1);
      end = timeNow;
      break;
    case PeriodName.MonthFirstTill10nth:
      start = new Date(timeNow.getFullYear(), timeNow.getMonth(), 1);
      end = new Date(timeNow.getFullYear(), timeNow.getMonth(), 10);
      break;
    case PeriodName.Month11nthTill20nth:
      start = new Date(timeNow.getFullYear(), timeNow.getMonth(), 11);
      end = new Date(timeNow.getFullYear(), timeNow.getMonth(), 20);
      break;
    case PeriodName.Month21stTillEnd:
      start = new Date(timeNow.getFullYear(), timeNow.getMonth(), 21);
      end = new Date(timeNow.getFullYear(), timeNow.getMonth() + 1, 0);
      break;
    case PeriodName.QuarterToDate:
      start = new Date(timeNow.getFullYear(), Math.floor(timeNow.getMonth() / 3) * 3, 1);
      end = timeNow;
      break;
    default:
      start = new Date(year, 0, 1);
      end = new Date(year, 0, 31);
      break;
  }

  return [start, end];
}

function formatDate(date: Date): string {
  return date.toLocaleDateString("en-US", { day: "2-digit", month: "short" });
}

export function periodNameToOverviewCardString(year: number, period: PeriodName): string {
  let [start, end] = getPeriodStartAndEndDates(year, period);
  let formattedStart = formatDate(start);
  let formattedEnd = formatDate(end);
  return `${formattedStart} - ${formattedEnd}`;
}

export function getPeriodType(period: string): string {
  let periodValue: PeriodName = PeriodName.NotSet;
  console.log(period);
  if (period in PeriodName) {
    periodValue = PeriodName[period as keyof typeof PeriodName];
  }
  if (periodValue === PeriodName.NotSet) {
    return "Period";
  }
  switch (periodValue) {
    case PeriodName.January:
    case PeriodName.February:
    case PeriodName.March:
    case PeriodName.April:
    case PeriodName.May:
    case PeriodName.June:
    case PeriodName.July:
    case PeriodName.August:
    case PeriodName.September:
    case PeriodName.October:
    case PeriodName.November:
    case PeriodName.December:
    case PeriodName.Month:
    case PeriodName.MonthToDate:
      return "Month";
    case PeriodName.MonthFirstTill10nth:
      return "Month: 1st - 10th";
    case PeriodName.Month11nthTill20nth:
      return "Month: 11th - 20th";
    case PeriodName.Month21stTillEnd:
      return "Month: 21st - end";
    case PeriodName.Q1:
    case PeriodName.Q2:
    case PeriodName.Q3:
    case PeriodName.Q4:
    case PeriodName.Quarter:
    case PeriodName.QuarterToDate:
      return "Quarter";
    case PeriodName.H1:
    case PeriodName.H2:
      return "Half";
    case PeriodName.NineMonths:
      return "Nine months";
    case PeriodName.Year:
    case PeriodName.YearToDate:
      return "Year";
    default:
      return "Month";
  }
}

export function getPeriodByValue(value: any) {
  return Object.keys(PeriodName).find((key) => PeriodName[key as keyof typeof PeriodName] === value);
}

export function getQuarterStartDate(date: Date): Date {
  return new Date(date.getFullYear(), Math.floor(date.getMonth() / 3) * 3, 1);
}

export function getPreviousQuarterStartDate(date: Date): Date {
  let currentQuarterStartDate = getQuarterStartDate(date);
  if (currentQuarterStartDate.getMonth() === 0) {
    return new Date(currentQuarterStartDate.getFullYear() - 1, 9, 1);
  }
  return new Date(currentQuarterStartDate.getFullYear(), currentQuarterStartDate.getMonth() - 3, 1);
}

export function getNextQuarterStartDate(date: Date): Date {
  let currentQuarterStartDate = getQuarterStartDate(date);
  if (currentQuarterStartDate.getMonth() === 9) {
    return new Date(currentQuarterStartDate.getFullYear() + 1, 0, 1);
  }
  return new Date(currentQuarterStartDate.getFullYear(), currentQuarterStartDate.getMonth() + 3, 1);
}

export function dateToString(date: Date | undefined) {
  if (!date) {
    return "";
  }
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}

export function getPreviousPeriodDates(startDate: Date, endDate: Date): [Date, Date] {
  let diff = endDate.getTime() - startDate.getTime();
  let newEndDate = new Date(startDate.getTime());
  newEndDate.setDate(newEndDate.getDate() - 1);
  let newStartDate = new Date(newEndDate.getTime() - diff);
  return [newStartDate, newEndDate];
}

export function getPreviousYearDates(startDate: Date, endDate: Date): [Date, Date] {
  let newEndDate = new Date(endDate.getTime());
  let newStartDate = new Date(startDate.getTime());
  newStartDate.setFullYear(newStartDate.getFullYear() - 1);
  newEndDate.setFullYear(newEndDate.getFullYear() - 1);
  return [newStartDate, newEndDate];
}
