import React, { useEffect, useState } from "react";
import { Apartment, YearAndPeriod } from "../../models/";
import { ApartmentStats, PayoutStat } from "../../models/responses";
import { apiUrl } from "../../layouts/adminLayout";
import { fetchOptions } from "../../hooks/useAuth";
import { useUserContext } from "../../hooks/userUserContext";

interface ReportDistributionStatsProps {
  apartments: Apartment[];
  apartmentStats: ApartmentStats[];
  apartmentIdMap: Map<number, Apartment>;
  yearAndPeriod: YearAndPeriod;
}

const computeTax = (income: number): number => {
  let taxableIncome = 0.95 * income;
  const taxBrackets: [number, number][] = [
    [35001, 0.4],
    [12001, 0.35],
    [0, 0.15],
  ];
  let tax = 0;
  for (const [bracket, rate] of taxBrackets) {
    if (taxableIncome > bracket) {
      tax += (taxableIncome - bracket) * rate;
      taxableIncome = bracket;
    }
  }
  return Math.round(tax);
};

const ReportDistributionStats: React.FC<ReportDistributionStatsProps> = ({
  apartments,
  apartmentIdMap,
  yearAndPeriod,
  apartmentStats,
}) => {
  const { selectedOwner } = useUserContext().context;
  const [reportApartmentStats, setReportApartmentStats] = useState<ApartmentStats[]>([]);
  const [payoutStats, setPayoutStats] = useState<PayoutStat[]>([]);
  const [payoutStatsLastYear, setPayoutStatsLastYear] = useState<PayoutStat[]>([]);
  const [currentTotalPayoutStats, setCurrentTotalPayoutStats] = useState<PayoutStat>({
    apartmentId: 0,
    cleaningFees: 0,
    commissions: 0,
    cumulativeDeposit: 0,
    daysAvailable: 0,
    daysBooked: 0,
    expenses: 0,
    grossIncome: 0,
    managementFee: 0,
    netAdr: 0,
    netIncome: 0,
    payout: 0,
    payoutRatio: 0,
    periodDeposit: 0,
    taxes: 0,
  });

  useEffect(() => {
    setReportApartmentStats(apartmentStats);
  }, [apartmentStats]);

  useEffect(() => {
    if (apartments.length === 0) {
      return;
    }
    let url = `${apiUrl}/api/owners/${selectedOwner}/payouts?year=${yearAndPeriod.year}&period=${yearAndPeriod.period}`;
    fetch(url, fetchOptions)
      .then((response) => {
        if (response) {
          return response.text();
        }
        throw new Error("Unauthorized");
      })
      .then((data) => {
        const payoutStats = JSON.parse(data).payoutStats;
        setPayoutStats(payoutStats);
        setCurrentTotalPayoutStats(computeTotalPayoutStats(payoutStats));
      });
    let urlLastYear = `${apiUrl}/api/owners/${selectedOwner}/payouts?year=${yearAndPeriod.year - 1}&period=${yearAndPeriod.period}`;
    fetch(urlLastYear, fetchOptions)
      .then((response) => {
        if (response) {
          return response.text();
        }
        throw new Error("Unauthorized");
      })
      .then((data) => {
        const payoutStats = JSON.parse(data).payoutStats;
        setPayoutStatsLastYear(payoutStats);
      });
  }, [yearAndPeriod, apartments, apartmentIdMap]);

  function getApartmentStats(apartmentId: number, isFiftyPc: boolean) {
    if (!reportApartmentStats) {
      return null;
    }
    const stats = reportApartmentStats.find((s) => s.apartmentId === apartmentId);
    const mutliplier = isFiftyPc ? 0.5 : 1;
    if (!stats) {
      return null;
    }
    return {
      grossIncome: Math.floor(stats.grossIncome * mutliplier),
      vat: Math.floor(stats.vat * mutliplier),
      sustainabilityFee: Math.floor(stats.sustainabilityFee * mutliplier),
      temporaryStayDuty: Math.floor(stats.temporaryStayDuty * mutliplier),
      platformCommissions: Math.floor(stats.platformCommissions * mutliplier),
      cleaningFees: Math.floor(stats.cleaningFees * mutliplier),
      netIncome: Math.floor(stats.netIncome * mutliplier),
    };
  }

  function getPayoutStats(apartmentId: number, isFiftyPc: boolean) {
    if (!payoutStats) {
      return null;
    }
    const stats = payoutStats.find((s) => s.apartmentId === apartmentId);
    const mutliplier = isFiftyPc ? 0.5 : 1;
    if (!stats) {
      return null;
    }
    return {
      payout: Math.floor(stats.payout * mutliplier),
      grossIncome: Math.floor(stats.grossIncome * mutliplier),
      periodDeposit: Math.floor(stats.periodDeposit * mutliplier),
      cumulativeDeposit: Math.floor(stats.cumulativeDeposit * mutliplier),
      netIncome: Math.floor(stats.netIncome * mutliplier),
    };
  }

  function getLastYeaerPayoutStats(apartmentId: number, isFiftyPc: boolean) {
    if (!payoutStatsLastYear) {
      return null;
    }
    const stats = payoutStatsLastYear.find((s) => s.apartmentId === apartmentId);
    const mutliplier = isFiftyPc ? 0.5 : 1;
    if (!stats) {
      return null;
    }
    return {
      payout: Math.floor(stats.payout * mutliplier),
      periodDeposit: Math.floor(stats.periodDeposit * mutliplier),
      cumulativeDeposit: Math.floor(stats.cumulativeDeposit * mutliplier),
      netIncome: Math.floor(stats.netIncome * mutliplier),
    };
  }

  function computeStat(apartmentId: number, stat: number, isFiftyPc: boolean) {
    return Math.floor(isFiftyPc ? stat / 2 : stat);
  }

  function computeTotalStats() {
    let totalStats = {
      grossIncome: 0,
      vat: 0,
      sustainabilityFee: 0,
      temporaryStayDuty: 0,
      platformCommissions: 0,
      cleaningFees: 0,
      netIncome: 0,
    };
    apartmentStats.forEach((stat) => {
      let isFiftyPc = apartmentIdMap.get(stat.apartmentId)?.ownerIds.length === 2;
      totalStats.grossIncome += computeStat(stat.apartmentId, stat.grossIncome, isFiftyPc);
      totalStats.vat += computeStat(stat.apartmentId, stat.vat, isFiftyPc);
      totalStats.sustainabilityFee += computeStat(stat.apartmentId, stat.sustainabilityFee, isFiftyPc);
      totalStats.temporaryStayDuty += computeStat(stat.apartmentId, stat.temporaryStayDuty, isFiftyPc);
      totalStats.platformCommissions += computeStat(stat.apartmentId, stat.platformCommissions, isFiftyPc);
      totalStats.cleaningFees += computeStat(stat.apartmentId, stat.cleaningFees, isFiftyPc);
      totalStats.netIncome += computeStat(stat.apartmentId, stat.netIncome, isFiftyPc);
    });
    return totalStats;
  }

  function computeTotalPayoutStats(stats: PayoutStat[]): PayoutStat {
    let totalStats: PayoutStat = {
      apartmentId: 0,
      cleaningFees: 0,
      commissions: 0,
      daysAvailable: 0,
      daysBooked: 0,
      expenses: 0,
      managementFee: 0,
      netAdr: 0,
      taxes: 0,
      payout: 0,
      payoutRatio: 0,
      grossIncome: 0,
      periodDeposit: 0,
      cumulativeDeposit: 0,
      netIncome: 0,
    };
    stats.forEach((stat) => {
      let isFiftyPc = apartmentIdMap.get(stat.apartmentId)?.ownerIds.length === 2;
      totalStats.payout += computeStat(stat.apartmentId, stat.payout, isFiftyPc);
      totalStats.grossIncome += computeStat(stat.apartmentId, stat.grossIncome, isFiftyPc);
      totalStats.periodDeposit += computeStat(stat.apartmentId, stat.periodDeposit, isFiftyPc);
      totalStats.cumulativeDeposit += computeStat(stat.apartmentId, stat.cumulativeDeposit, isFiftyPc);
      totalStats.netIncome += computeStat(stat.apartmentId, stat.netIncome, isFiftyPc);
    });
    return totalStats;
  }

  return (
    <>
      <div className="col-md-12 font-extra-small">
        <table className="mb-0 table-sm border" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th className="text-left">
                <strong>Apartment</strong>
              </th>
              <th style={{ textAlign: "right" }}>
                <strong>
                  Gross
                  <br /> Income
                </strong>
              </th>
              <th style={{ textAlign: "right" }}>
                <strong>VAT 13%</strong>
              </th>
              <th style={{ textAlign: "right" }}>
                <strong>
                  Sustainability
                  <br /> Fee
                </strong>
              </th>
              <th style={{ textAlign: "right" }}>
                <strong>
                  Temporary
                  <br />
                  Stay Duty
                </strong>
              </th>
              <th style={{ textAlign: "right" }}>
                <strong>
                  Platform
                  <br />
                  Commissions
                </strong>
              </th>
              <th style={{ textAlign: "right" }}>
                <strong>
                  Cleaning
                  <br />
                  Fees
                </strong>
              </th>
              <th style={{ textAlign: "right" }}>
                <strong>Net Income</strong>
              </th>
              <th className={"border-1 bg-gray-300"} style={{ textAlign: "right" }}>
                <strong>
                  Distribution Ratio
                  <br /> {yearAndPeriod.period} {yearAndPeriod.year}
                </strong>
              </th>
              <th style={{ textAlign: "right" }}>
                <strong>
                  Distribution Ratio
                  <br /> {yearAndPeriod.period} {yearAndPeriod.year - 1}
                </strong>
              </th>
              <th className={"border-1 bg-gray-300"} style={{ textAlign: "right" }}>
                <strong>
                  Distribution
                  <br /> Amount {yearAndPeriod.year}
                </strong>
              </th>
              <th style={{ textAlign: "right" }}>
                <strong>
                  Distribution
                  <br /> Amount {yearAndPeriod.year - 1}
                </strong>
              </th>
              <th style={{ textAlign: "right" }}>
                <strong>
                  {yearAndPeriod.period} Cash
                  <br />
                  Deposit
                </strong>
              </th>
              <th style={{ textAlign: "right" }}>
                <strong>
                  Cumulative Cash <br />
                  Deposit
                </strong>
              </th>
            </tr>
          </thead>
          <tbody>
            {apartments.map((apartment) => {
              console.log("Foo" + apartmentIdMap.get(apartment.id)?.shortName);
              const isFiftyPc = (apartmentIdMap.get(apartment.id)?.ownerIds || []).length === 2;
              const stats = getApartmentStats(apartment.id, isFiftyPc);
              const currentPayoutStats = getPayoutStats(apartment.id, isFiftyPc);
              const lastYearPayoutStats = getLastYeaerPayoutStats(apartment.id, isFiftyPc);
              const apartmentName = isFiftyPc
                ? (apartmentIdMap.get(apartment.id)?.shortName || "") + " (50%)"
                : apartmentIdMap.get(apartment.id)?.shortName || "";

              return (
                <tr key={apartment.id}>
                  <td className="text-left fw-bolder">{apartmentName}</td>
                  <td className={"fw-bolder text-nowrap"} style={{ textAlign: "right" }}>
                    {currentPayoutStats?.grossIncome} €
                  </td>
                  <td style={{ textAlign: "right" }}>{stats?.vat} €</td>
                  <td style={{ textAlign: "right" }}>{stats?.sustainabilityFee} €</td>
                  <td style={{ textAlign: "right" }}>{stats?.temporaryStayDuty} €</td>
                  <td style={{ textAlign: "right" }}>{stats?.platformCommissions} €</td>
                  <td style={{ textAlign: "right" }}>{stats?.cleaningFees} €</td>
                  <td style={{ textAlign: "right" }}>{stats?.netIncome} €</td>
                  <td className={"border-1 bg-gray-300"} style={{ textAlign: "right" }}>
                    {(((currentPayoutStats?.payout || 0) / (currentPayoutStats?.netIncome || 1)) * 100).toFixed(0)} %
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {(((lastYearPayoutStats?.payout || 0) / (lastYearPayoutStats?.netIncome || 1)) * 100).toFixed(0)} %
                  </td>
                  <td className={"border-1 bg-gray-300"} style={{ textAlign: "right" }}>
                    {currentPayoutStats?.payout} €
                  </td>
                  <td style={{ textAlign: "right" }}>{lastYearPayoutStats?.payout} €</td>
                  <td style={{ textAlign: "right" }}>{currentPayoutStats?.periodDeposit} €</td>
                  <td style={{ textAlign: "right" }}>{currentPayoutStats?.cumulativeDeposit} €</td>
                </tr>
              );
            })}
            {(() => {
              let totalStats = computeTotalStats();
              let lastYearPayoutStats = computeTotalPayoutStats(payoutStatsLastYear);
              return (
                <tr>
                  <td className="text-left fw-bolder">{"Total"}</td>
                  <td className={"fw-bolder text-nowrap"} style={{ textAlign: "right" }}>
                    {totalStats.grossIncome} €
                  </td>
                  <td style={{ textAlign: "right" }}>{totalStats.vat} €</td>
                  <td style={{ textAlign: "right" }}>{totalStats.sustainabilityFee} €</td>
                  <td style={{ textAlign: "right" }}>{totalStats.temporaryStayDuty} €</td>
                  <td style={{ textAlign: "right" }}>{totalStats.platformCommissions} €</td>
                  <td style={{ textAlign: "right" }}>{totalStats.cleaningFees} €</td>
                  <td style={{ textAlign: "right" }}>{totalStats.netIncome} €</td>
                  <td className={"border-1 bg-gray-300"} style={{ textAlign: "right" }}>
                    {(
                      ((currentTotalPayoutStats?.payout || 0) / (currentTotalPayoutStats?.netIncome || 1)) *
                      100
                    ).toFixed(0)}{" "}
                    %
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {(((lastYearPayoutStats?.payout || 0) / (lastYearPayoutStats?.netIncome || 1)) * 100).toFixed(0)} %
                  </td>
                  <td className={"border-1 bg-gray-300"} style={{ textAlign: "right" }}>
                    {currentTotalPayoutStats?.payout} €
                  </td>
                  <td style={{ textAlign: "right" }}>{lastYearPayoutStats?.payout} €</td>
                  <td style={{ textAlign: "right" }}>{currentTotalPayoutStats?.periodDeposit} €</td>
                  <td
                    className={currentTotalPayoutStats?.cumulativeDeposit > 0 ? "bg-light-blue" : "bg-light-danger"}
                    style={{ textAlign: "right" }}
                  >
                    {currentTotalPayoutStats?.cumulativeDeposit} €
                  </td>
                </tr>
              );
            })()}
          </tbody>
        </table>
      </div>
      {(() => {
        return (
          <div className="row mt-4">
            <h5>Tax Benefit</h5>
            <div className="col-md-7 font-extra-small">
              <table className="mb-0 table-sm border" style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td className="text-left">
                      <strong>Taxable Income for {yearAndPeriod.year}</strong>
                    </td>
                    <td style={{ textAlign: "right" }}>{currentTotalPayoutStats.payout} €</td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <strong>Projected Tax</strong>
                    </td>
                    <td style={{ textAlign: "right" }}>{computeTax(currentTotalPayoutStats.payout)} €</td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <strong>Tax on Gross Income of {currentTotalPayoutStats.grossIncome} €</strong>
                    </td>
                    <td style={{ textAlign: "right" }}>{computeTax(currentTotalPayoutStats.grossIncome)} €</td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <strong>Tax Benefit compared to Non-Declaring Expenses</strong>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {computeTax(currentTotalPayoutStats.grossIncome) - computeTax(currentTotalPayoutStats.payout)} €
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        );
      })()}
    </>
  );
};

export default ReportDistributionStats;
