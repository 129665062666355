import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getPreviousPeriodDates, getPreviousYearDates } from "../utils/periods";
import { BookingPaceItem } from "../models/responses";
import { getPlatformName } from "../utils/random";

export type BookingPaceStats = {
  grossIncome: number;
  netIncome: number;
  grossAdr: number;
  netAdr: number;
  daysBooked: number;
  futureDaysBooked: number;
  bookingsReceived: number;
};

type BookingPaceState = {
  bookingPaceStats: Map<string, BookingPaceStats>;
  bookingPaceStatsPrevious: Map<string, BookingPaceStats>;
  bookingPaceStatsPreviousYear: Map<string, BookingPaceStats>;
  startDate: Date;
  endDate: Date;
  previousStartDate: Date;
  previousEndDate: Date;
  previousYearStartDate: Date;
  previousYearEndDate: Date;
};

const INITIAL_DATE_RANGE_DAYS = 10;

const initializeDates = () => {
  const endDate = new Date();
  const startDate = new Date(endDate);
  startDate.setDate(startDate.getDate() - INITIAL_DATE_RANGE_DAYS);

  const [previousStartDate, previousEndDate] = getPreviousPeriodDates(startDate, endDate);
  const [previousYearStartDate, previousYearEndDate] = getPreviousYearDates(startDate, endDate);

  return {
    startDate,
    endDate,
    previousStartDate,
    previousEndDate,
    previousYearStartDate,
    previousYearEndDate,
  };
};

const initialDates = initializeDates();

const initialState: BookingPaceState = {
  bookingPaceStats: new Map(),
  bookingPaceStatsPrevious: new Map(),
  bookingPaceStatsPreviousYear: new Map(),
  ...initialDates,
};

const emptyStats = (): BookingPaceStats => ({
  grossAdr: 0,
  netAdr: 0,
  grossIncome: 0,
  netIncome: 0,
  daysBooked: 0,
  futureDaysBooked: 0,
  bookingsReceived: 0,
});

const bookingPaceSlice = createSlice({
  name: "bookingPace",
  initialState: initialState,
  reducers: {
    setStartDate: (state, action: PayloadAction<Date>) => {
      state.startDate = action.payload;
      [state.previousStartDate, state.previousEndDate] = getPreviousPeriodDates(state.startDate, state.endDate);
      [state.previousYearStartDate, state.previousYearEndDate] = getPreviousYearDates(state.startDate, state.endDate);
    },
    setEndDate: (state, action: PayloadAction<Date>) => {
      state.endDate = action.payload;
      [state.previousStartDate, state.previousEndDate] = getPreviousPeriodDates(state.startDate, state.endDate);
      [state.previousYearStartDate, state.previousYearEndDate] = getPreviousYearDates(state.startDate, state.endDate);
    },
    setPreviousStartDate: (state, action: PayloadAction<Date>) => {
      state.previousStartDate = action.payload;
    },
    setPreviousEndDate: (state, action: PayloadAction<Date>) => {
      state.previousEndDate = action.payload;
    },
    setPreviousYearStartDate: (state, action: PayloadAction<Date>) => {
      state.previousYearStartDate = action.payload;
    },
    setPreviousYearEndDate: (state, action: PayloadAction<Date>) => {
      state.previousYearEndDate = action.payload;
    },
    updateStats: (
      state,
      action: PayloadAction<{
        data: BookingPaceItem[];
        type: "current" | "previous" | "previousYear";
        apartmentId: number;
        groupId: number;
      }>,
    ) => {
      const { data, type, apartmentId, groupId } = action.payload;
      let records = data || [];
      if (apartmentId !== 0) {
        records = records.filter((a) => a.apartmentId === apartmentId);
      } else if (groupId !== 0) {
        records = records.filter((a) => a.groupId === groupId);
      }

      const stats = new Map<string, BookingPaceStats>();
      stats.set("All Sources", emptyStats());

      records.forEach((record) => {
        const key = getPlatformName(record.platformId);
        let currentStats = stats.get(key) || emptyStats();

        currentStats.grossIncome += record.grossIncome;
        currentStats.netIncome += record.netIncome;
        currentStats.daysBooked += record.daysBooked;
        currentStats.futureDaysBooked += record.futureDaysBooked;
        currentStats.bookingsReceived += record.bookingsReceived;

        stats.set(key, currentStats);

        let allSourcesStats = stats.get("All Sources")!;
        allSourcesStats.grossIncome += record.grossIncome;
        allSourcesStats.netIncome += record.netIncome;
        allSourcesStats.daysBooked += record.daysBooked;
        allSourcesStats.futureDaysBooked += record.futureDaysBooked;
        allSourcesStats.bookingsReceived += record.bookingsReceived;
      });

      stats.forEach((value) => {
        value.grossAdr = value.grossIncome / value.daysBooked;
        value.netAdr = value.netIncome / value.daysBooked;
      });

      switch (type) {
        case "current":
          state.bookingPaceStats = stats;
          break;
        case "previous":
          state.bookingPaceStatsPrevious = stats;
          break;
        case "previousYear":
          state.bookingPaceStatsPreviousYear = stats;
          break;
      }
    },
  },
});

export const {
  setStartDate,
  setEndDate,
  setPreviousStartDate,
  setPreviousYearStartDate,
  setPreviousYearEndDate,
  setPreviousEndDate,
  updateStats,
} = bookingPaceSlice.actions;

export default bookingPaceSlice.reducer;
