import React, { useCallback, useEffect, useRef, useState } from "react";
import { apiUrl } from "../layouts/adminLayout";
import { Convert, Reservation } from "../models/responses";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { GridDropdownFilter } from "../lib/organisms/Grid";
import { useUserContext } from "../hooks/userUserContext";
import ReservationsPage, { ReservationRow } from "./reservations";
import { PeriodName, Platform } from "../models";
import { fetchOptions } from "../hooks/useAuth";
import { AgGridReact } from "ag-grid-react";

export const UserReservationsPage: React.FC = () => {
  const gridRef = useRef<AgGridReact | any>();

  const [reservations, setReservations] = useState<ReservationRow[]>([]);

  const {
    selectedPeriod,
    selectedYear,
    apartmentIdMap,
    ownerOptions,
    setSelectedOwner,
    periodOptions,
    setSelectedPeriod,
    yearOptions,
    setSelectedYear,
    selectedOwner,
  } = useUserContext().context;

  const getAndSetReservations = useCallback(
    (year: string, period: string): void => {
      let url;
      url = `${apiUrl}/api/owners/${selectedOwner}/reservations?year=${year}&period=${period}`;
      fetch(url, fetchOptions)
        .then((response) => {
          if (response) {
            return response.text();
          }
          throw new Error("Unauthorized");
        })
        .then((response) => {
          let responseJson = Convert.toGetReservationsResponse(response);
          let res = responseJson.reservations.map((reservation: Reservation) => {
            return {
              id: reservation.id,
              apartment: apartmentIdMap.get(reservation.apartmentId)?.name || "N/A",
              platform: Platform[reservation.platform],
              reservationCode: reservation.reservationCode,
              bookingDate: reservation.bookingDate,
              startDate: reservation.startDate,
              endDate: reservation.endDate,
              actualDaysOfStay: reservation.actualDaysOfStay,
              guestName: reservation.guestName,
              numberOfGuests: reservation.numberOfGuests,
              receivableIncome: reservation.receivableIncome,
              amountPaid: reservation.amountPaid,
              paymentDate: reservation.paymentDate,
              commission: reservation.commission,
              cleaningFee: reservation.cleaningFee,
              cleaningCost: reservation.cleaningCost,
              hostingExpense: reservation.hostingExpense,
              epsilon: reservation.epsilon,
              netIncome: reservation.netIncome,
              grossAdr: reservation.grossAdr,
              netAdr: reservation.netAdr,
              vat: reservation.vat,
              sustainabilityFee: reservation.sustainabilityFee,
              temporaryStayDuty: reservation.temporaryStayDuty,
            };
          });
          setReservations(res);
        })
        .catch((error) => console.log(error));
    },
    [apartmentIdMap, selectedOwner],
  );

  useEffect(() => {
    getAndSetReservations(selectedYear.toString(), selectedPeriod);
  }, [selectedPeriod, selectedYear, apartmentIdMap, getAndSetReservations]);

  const gridDropdownFilters: GridDropdownFilter[] = [
    {
      label: "Period",
      options: periodOptions,
      selectedOption: selectedPeriod,
      onSelectionChanged: (period) => setSelectedPeriod(period as PeriodName),
    },
    {
      label: "Year",
      options: yearOptions,
      selectedOption: selectedYear.toString(),
      onSelectionChanged: (year) => setSelectedYear(Number(year)),
    },
    {
      label: "Owner",
      options: ownerOptions,
      selectedOption: selectedOwner.toString(),
      onSelectionChanged: (owner) => setSelectedOwner(Number(owner)),
    },
  ];

  return <ReservationsPage reservations={reservations} gridRef={gridRef} gridDropdownFilters={gridDropdownFilters} />;
};
