import { Cell, Label, Pie, PieChart, ResponsiveContainer } from "recharts";
import TextWithSvg from "./textWithSvg";
import PercentageChangeText from "./percentageChangeText";
import React, { FC } from "react";
import { getPeriodType } from "../../utils/periods";

interface OccupancyChartStatsCardProps {
  metric: string;
  totalOccupancy: number;
  groupOccupancies: { name: string; value: number }[];
  groupOccupanciesPreviousPeriod: { name: string; value: number }[];
  groupOccupanciesPreviousYear: { name: string; value: number }[];
  period: string;
  showPreviousYear: boolean;
  totalPreviousPeriod: number;
  totalPreviousYear: number;
}

const OccupancyChartStatsCard: FC<OccupancyChartStatsCardProps> = ({
  metric,
  totalOccupancy,
  groupOccupancies,
  groupOccupanciesPreviousPeriod,
  groupOccupanciesPreviousYear,
  period,
  totalPreviousPeriod,
  totalPreviousYear,
  showPreviousYear,
}) => {
  const calendarIcon =
    "M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z";

  const percentageChangePrevious =
    totalPreviousPeriod === 0 ? 0 : ((totalOccupancy - totalPreviousPeriod) / totalPreviousPeriod) * 100;
  const percentageChangePreviousYear =
    totalPreviousYear === 0 ? 0 : ((totalOccupancy - totalPreviousYear) / totalPreviousYear) * 100;

  return (
    <div className="card border-0 shadow">
      <h2 className="fs-6 fw-normal mb-1 text-gray-500 mt-4 ms-4">Total {metric}</h2>
      <div className="card-body d-flex justify-content-center">
        <ResponsiveContainer width="100%" height={200} style={{ marginTop: "-120px" }}>
          <PieChart>
            <Pie
              cy={183}
              data={[{ value: Math.floor(totalOccupancy) }, { value: 100 - Math.floor(totalOccupancy) }]}
              dataKey="value"
              startAngle={180}
              endAngle={0}
              innerRadius="77%"
              outerRadius="100%"
              fill="#8884d8"
            >
              <Cell key={`cell-1`} fill={"#f0bc74"} />
              <Cell key={`cell-2`} fill={"#333"} />
              <Label value={`${Math.floor(totalOccupancy)}%`} position="center" fontSize={30} />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="small d-flex flex-column ms-4 me-4 mt-2 mb-3">
        <GroupMetrics metrics={groupOccupancies} />
      </div>
      <div className="small d-flex flex-column ms-4 mb-3" style={{ flexGrow: 1 }}>
        <TextWithSvg
          svgClassName={"icon-xxs text-gray-400"}
          className={"mb-2"}
          text={period}
          svgPathData={calendarIcon}
        />
        <div className="table-responsive">
          <table
            className="table table-centered table-nowrap mb-0 rounded"
            style={{ paddingLeft: 0, paddingRight: 0, tableLayout: "auto" }}
          >
            <thead className="thead-light">
              <tr>
                <th
                  className={"border-0 rounded-start text-start "}
                  style={{
                    paddingLeft: "0.75rem",
                    paddingRight: 0,
                    fontWeight: "normal",
                    fontSize: "0.65rem",
                  }}
                >
                  Vs Previous
                </th>
                {groupOccupanciesPreviousPeriod.map((item, index) => (
                  <th
                    key={index}
                    className={"border-0 text-end"}
                    style={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      fontWeight: "normal",
                      fontSize: "0.65rem",
                    }}
                  >
                    {item.name}
                  </th>
                ))}
                <th
                  className={"border-0 rounded-end text-end"}
                  style={{
                    paddingLeft: 0,
                    paddingRight: "0.75rem",
                    fontWeight: "bolder",
                    fontSize: "0.65rem",
                  }}
                >
                  Overall
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{}}>
                <td
                  style={{
                    textAlign: "left",
                    paddingLeft: "0.75rem",
                    paddingRight: 0,
                  }}
                >
                  {getPeriodType(period)}
                </td>
                {groupOccupanciesPreviousPeriod.map((item, index) => {
                  const percentageChange =
                    item.value === 0 ? 0 : ((groupOccupancies[index].value - item.value) / item.value) * 100;
                  return (
                    <td style={{ paddingLeft: 0, paddingRight: 0 }} key={index}>
                      <div className={"d-flex small justify-content-end"}>
                        <span>{item.value.toFixed(1) + "%"}</span>
                      </div>
                      <PercentageChangeText
                        value={percentageChange}
                        description={""}
                        valueFormatter={(value) => value.toFixed(0)}
                      />
                    </td>
                  );
                })}
                <td style={{ paddingLeft: 0, paddingRight: "0.75rem" }}>
                  <div className={"d-flex small justify-content-end"}>
                    <span>{totalPreviousPeriod.toFixed(1) + "%"}</span>
                  </div>
                  <PercentageChangeText
                    value={percentageChangePrevious}
                    description={""}
                    valueFormatter={(value) => value.toFixed(0)}
                    extraClasses={"fw-bolder"}
                  />
                </td>
              </tr>
              {showPreviousYear && (
                <tr>
                  <td style={{ textAlign: "left", paddingLeft: "0.75rem", paddingRight: 0 }}>Year</td>
                  {groupOccupanciesPreviousYear.map((item, index) => {
                    const percentageChange =
                      item.value === 0 ? 0 : ((groupOccupancies[index].value - item.value) / item.value) * 100;
                    return (
                      <td key={index} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <div className={"d-flex small justify-content-end"}>
                          <span>{item.value.toFixed(1) + "%"}</span>
                        </div>
                        <PercentageChangeText
                          value={percentageChange}
                          description={""}
                          valueFormatter={(value) => value.toFixed(0)}
                        />
                      </td>
                    );
                  })}
                  <td style={{ paddingLeft: 0, paddingRight: "0.75rem" }}>
                    <div className={"d-flex small justify-content-end"}>
                      <span>{totalPreviousYear.toFixed(1) + "%"}</span>
                    </div>
                    <PercentageChangeText
                      value={percentageChangePreviousYear}
                      description={""}
                      valueFormatter={(value) => value.toFixed(0)}
                      extraClasses={"fw-bolder"}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

interface GroupMetricsProps {
  metrics: { name: string; value: number }[];
}

const GroupMetrics: FC<GroupMetricsProps> = ({ metrics }) => {
  return (
    <>
      <h2 className="fs-6 fw-normal text-gray-500">Per group</h2>
      {metrics.map((metric, index) => (
        <div className="progress-wrapper mt-2" key={index}>
          <div className="progress-info">
            <div className="h6 mb-0">{metric.name}</div>
            <div className="small fw-bold">
              <span>{metric.value.toFixed(1)} %</span>
            </div>
          </div>
          <div className="progress mb-0">
            <div
              className="progress-bar bg-dark"
              role="progressbar"
              aria-valuenow={metric.value}
              aria-valuemin={0}
              aria-valuemax={100}
              style={{ width: `${metric.value.toFixed(0)}%` }}
            ></div>
          </div>
        </div>
      ))}
    </>
  );
};

export default OccupancyChartStatsCard;
