import React, { useEffect, useState } from "react";
import { Apartment, YearAndPeriod } from "../../models/";
import PercentageChangeText from "../molecules/percentageChangeText";
import { Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { ApartmentStats, Convert } from "../../models/responses";
import {
  reportBarChartNumberFormatter,
  reportGraphsAdrFormatter,
  reportGraphsIncomeFormatter,
  reportGraphsOccupancyFormatter,
} from "../../utils/formatters";
import CustomLabel from "../molecules/barChartCustomLabel";
import { apiUrl } from "../../layouts/adminLayout";
import { getPeriodByValue } from "../../utils/periods";
import { useUserContext } from "../../hooks/userUserContext";
import { fetchOptions } from "../../hooks/useAuth";

interface ReportGraphsProps {
  yearAndPeriod: YearAndPeriod;
  apartments: Apartment[];
  apartmentStats: ApartmentStats[];
}

interface ApartmentBarChartStats {
  name: string;
  value: number;
}

function barChart(data: ApartmentBarChartStats[]) {
  return (
    <ResponsiveContainer width="80%" height={110}>
      <BarChart data={data}>
        <XAxis dataKey="name" />
        <Tooltip />
        <Bar dataKey="value" fill="#1F2937">
          <LabelList content={<CustomLabel barValueFormatter={reportBarChartNumberFormatter} fontSize={9} />} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

function getPeriodBreakdownStats(
  periodStats: ApartmentStats[],
  periodNames: string[],
  attribute: keyof ApartmentStats,
) {
  if (!periodStats || !periodNames) {
    return [];
  }
  let periodBreakdownStats: ApartmentBarChartStats[] = [];
  for (let i = 0; i < periodNames.length; i++) {
    periodBreakdownStats.push({
      name: periodNames[i],
      value: periodStats.length > i ? periodStats[i][attribute] : 0,
    });
  }
  return periodBreakdownStats;
}

const ReportGraphs: React.FC<ReportGraphsProps> = ({ yearAndPeriod, apartments, apartmentStats }) => {
  const [periodBreakdownStats, setPeriodBreakdownStats] = useState<ApartmentStats[][]>([]);
  const [periodNames, setPeriodNames] = useState<string[]>([]);
  const [previousStats, setPreviousStats] = useState<ApartmentStats[]>([]);
  const [reportApartments, setReportApartments] = useState<Apartment[]>(apartments);
  const { selectedOwner } = useUserContext().context;

  useEffect(() => {
    setReportApartments(apartments);
    let currentPeriodUrl = `${apiUrl}/api/owners/${selectedOwner}/stats?year=${yearAndPeriod.year}&period=${getPeriodByValue(yearAndPeriod.period)}`;
    let previousPeriodUrl = `${apiUrl}/api/owners/${selectedOwner}/stats?year=${yearAndPeriod.year - 1}&period=${getPeriodByValue(yearAndPeriod.period)}`;
    fetch(previousPeriodUrl, fetchOptions)
      .then((response) => {
        if (response) {
          return response.text();
        }
        throw new Error("Unauthorized");
      })
      .then((response) => {
        setPreviousStats(Convert.toGetStatsForApartmentsResponse(response).apartmentStats);
      })
      .catch((error) => console.log(error));
    let periodBreakdownStatsUrl = currentPeriodUrl + "&periodBreakdown=true&foo=bar";
    fetch(periodBreakdownStatsUrl, fetchOptions)
      .then((response) => {
        if (response) {
          return response.text();
        }
        throw new Error("Unauthorized");
      })
      .then((response) => {
        let periodBreakdownStats = Convert.toGetPeriodBreakdownStatsResponse(response).periodBreakdownStats;
        setPeriodBreakdownStats(periodBreakdownStats.apartmentStats);
        setPeriodNames(periodBreakdownStats.periodNames);
      })
      .catch((error) => console.log(error));
  }, [yearAndPeriod, apartments]);

  return (
    <div className="mb-2">
      {reportApartments.map((apartment: Apartment, index: number) => {
        let currentYearStats = apartmentStats.find((stat) => stat.apartmentId === apartment.id);
        let previousYearStats = previousStats.find((stat) => stat.apartmentId === apartment.id);
        let daysBookedChange = 0,
          occupancyChange = 0,
          netIncomeChange = 0,
          netAdrChange = 0,
          grossAdrChange = 0;
        let periodStats = periodBreakdownStats[index];

        if (currentYearStats && previousYearStats) {
          daysBookedChange = Math.round(
            ((currentYearStats.daysBooked - previousYearStats.daysBooked) / previousYearStats.daysBooked) * 100,
          );
          occupancyChange = Math.round(
            ((currentYearStats.occupancy - previousYearStats.occupancy) / previousYearStats.occupancy) * 100,
          );
          netIncomeChange = Math.round(
            ((currentYearStats.netIncome - previousYearStats.netIncome) / previousYearStats.netIncome) * 100,
          );
          netAdrChange = Math.round(
            ((currentYearStats.netAdr - previousYearStats.netAdr) / previousYearStats.netAdr) * 100,
          );
          grossAdrChange = Math.round(
            ((currentYearStats.grossAdr - previousYearStats.grossAdr) / previousYearStats.grossAdr) * 100,
          );
        }
        return (
          <div className={"row mb-3 font-extra-small d-flex justify-content-between flex-row"} key={apartment.id}>
            <div className={"d-flex flex-nowrap"} style={{ display: "inline-flex" }}></div>
            <div className="justify-content-between d-flex">
              <div style={{ display: "inline-block" }}>
                <div className={"d-flex justify-content-between"} style={{ width: "170px" }}>
                  <i className="small">{apartment.shortName}</i>
                  {currentYearStats && (
                    <span className="h6 font-extra-small">
                      Days Available:
                      <strong className="font-extra-small text-success fw-bold">
                        {currentYearStats.availableBookingDays - currentYearStats.daysBlocked}
                      </strong>
                    </span>
                  )}
                </div>
                <img
                  className="report-img"
                  width={"170px"}
                  src={`${apiUrl}/apartments/${encodeURIComponent(apartment.name)}.webp`}
                  alt="Apartment"
                />
              </div>
              <div className="font-extra-small">
                <span className="h6 font-extra-small">Days Booked: </span>
                <strong className="font-extra-small fw-bold text-info"> {currentYearStats?.daysBooked}</strong>
                <div style={{ whiteSpace: "nowrap", display: "flex" }}>
                  <span className="font-extra-small">YoY Change:</span>
                  <PercentageChangeText value={daysBookedChange} description={""} />
                </div>
                <div className="d-flex justify-content-start" style={{ marginLeft: "-10px", marginTop: "25px" }}>
                  {barChart(getPeriodBreakdownStats(periodStats, periodNames, "daysBooked"))}
                </div>
              </div>

              <div className="font-extra-small">
                <span className="h6 font-extra-small">Occupancy: </span>
                <strong className="font-extra-small fw-bold">
                  {" "}
                  {reportGraphsOccupancyFormatter(currentYearStats?.occupancy)}
                </strong>
                <div style={{ whiteSpace: "nowrap", display: "flex" }}>
                  <span className="font-extra-small">YoY Change:</span>
                  <PercentageChangeText value={occupancyChange} description={""} />
                </div>
                <div className="d-flex justify-content-start" style={{ marginLeft: "-10px", marginTop: "25px" }}>
                  {barChart(getPeriodBreakdownStats(periodStats, periodNames, "occupancy"))}
                </div>
              </div>

              <div className="font-extra-small">
                <span className="h6 font-extra-small">Net Income: </span>
                <strong className="font-extra-small fw-bold">
                  {" "}
                  {reportGraphsIncomeFormatter(currentYearStats?.netIncome)}
                </strong>
                <div style={{ whiteSpace: "nowrap", display: "flex" }}>
                  <span className="font-extra-small">YoY Change:</span>
                  <PercentageChangeText value={netIncomeChange} description={""} />
                </div>
                <div className="d-flex justify-content-start" style={{ marginLeft: "-10px", marginTop: "25px" }}>
                  {barChart(getPeriodBreakdownStats(periodStats, periodNames, "netIncome"))}
                </div>
              </div>

              <div className="font-extra-small">
                <span className="h6 font-extra-small">Gross ADR: </span>
                <strong className="font-extra-small fw-bold">
                  {" "}
                  {reportGraphsAdrFormatter(currentYearStats?.grossAdr)}
                </strong>
                <div style={{ whiteSpace: "nowrap", display: "flex" }}>
                  <span className="font-extra-small">YoY Change:</span>
                  <PercentageChangeText value={grossAdrChange} description={""} />
                </div>
                <div className="d-flex justify-content-start" style={{ marginLeft: "-10px", marginTop: "25px" }}>
                  {barChart(getPeriodBreakdownStats(periodStats, periodNames, "grossAdr"))}
                </div>
              </div>

              <div className="font-extra-small">
                <span className="h6 font-extra-small">Net ADR: </span>
                <strong className="font-extra-small fw-bold">
                  {" "}
                  {reportGraphsAdrFormatter(currentYearStats?.netAdr)}
                </strong>
                <div style={{ whiteSpace: "nowrap", display: "flex" }}>
                  <span className="font-extra-small">YoY Change:</span>
                  <PercentageChangeText value={netAdrChange} description={""} />
                </div>
                <div className="d-flex justify-content-start" style={{ marginLeft: "-10px", marginTop: "25px" }}>
                  {barChart(getPeriodBreakdownStats(periodStats, periodNames, "netAdr"))}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ReportGraphs;
