import { Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import TextWithSvg from "./textWithSvg";
import React, { FC } from "react";
import CustomLabel from "./barChartCustomLabel";
import PercentageChangeText from "./percentageChangeText";
import { getPeriodType } from "../../utils/periods";

interface BarChartStatsCardProps {
  metric: string;
  total: number;
  data: { name: string; value: number }[];
  dataPreviousPeriod: { name: string; value: number }[];
  dataPreviousYear: { name: string; value: number }[];
  period: string;
  showPreviousYear: boolean;
  totalPreviousPeriod: number;
  totalPreviousYear: number;
  totalValueFormatter: (value: number) => string;
  barValueFormatter: (value: number) => string;
}

const BarChartStatsCard: FC<BarChartStatsCardProps> = ({
  metric,
  total,
  data,
  dataPreviousPeriod,
  dataPreviousYear,
  period,
  totalPreviousPeriod,
  totalPreviousYear,
  totalValueFormatter,
  barValueFormatter,
  showPreviousYear,
}) => {
  const calendarIcon =
    "M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z";

  const percentageChangePrevious =
    totalPreviousPeriod === 0 ? 0 : ((total - totalPreviousPeriod) / totalPreviousPeriod) * 100;
  const percentageChangePreviousYear =
    totalPreviousYear === 0 ? 0 : ((total - totalPreviousYear) / totalPreviousYear) * 100;

  return (
    <div className="card border-0 shadow">
      <h2 className="fs-6 fw-normal mb-1 text-gray-500 mt-4 ms-4">Total {metric}</h2>
      <h3 className="fw-extrabold mb-1 mt-1 ms-4">{totalValueFormatter(total)}</h3>
      <div className="card-body d-flex justify-content-start" style={{ marginLeft: "10px" }}>
        <ResponsiveContainer width="100%" height={205}>
          <BarChart
            data={data.map((item) => ({ ...item, value: item.value }))}
            margin={{ top: 0, right: 10, left: 0, bottom: 0 }}
          >
            <XAxis
              dataKey="name"
              tick={(props) => (
                <text {...props} dy={15} fontSize={12}>
                  {props.payload.value}
                </text>
              )}
            />
            <Tooltip />
            <Bar dataKey="value" fill="#1F2937">
              <LabelList content={<CustomLabel barValueFormatter={barValueFormatter} fontSize={11} />} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="small d-flex flex-column ms-2 me-2 mt-2 mb-3">
        <TextWithSvg
          svgClassName={"icon-xxs text-gray-400"}
          className={"mb-2"}
          text={period}
          svgPathData={calendarIcon}
        />
        <div className="table-responsive">
          <table
            className="table table-centered table-nowrap mb-0 rounded "
            style={{ paddingLeft: 0, paddingRight: 0, tableLayout: "auto" }}
          >
            <thead className="thead-light">
              <tr>
                <th
                  className={"border-0 rounded-start text-start"}
                  style={{
                    paddingLeft: "0.75rem",
                    paddingRight: 0,
                    fontWeight: "normal",
                    fontSize: "0.65rem",
                  }}
                >
                  Vs Previous
                </th>
                {data.map((item, index) => (
                  <th
                    key={index}
                    className={"border-0 text-end"}
                    style={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      fontWeight: "normal",
                      fontSize: "0.65rem",
                    }}
                  >
                    {item.name}
                  </th>
                ))}
                <th
                  className={"rounded-end text-end"}
                  style={{
                    paddingLeft: 0,
                    paddingBottom: "0.7rem",
                    paddingRight: "0.75rem",
                    fontWeight: "bolder",
                    fontSize: "0.65rem",
                  }}
                >
                  Overall
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{}}>
                <td
                  style={{
                    textAlign: "left",
                    paddingLeft: "0.75rem",
                    paddingRight: 0,
                    fontSize: "0.8rem",
                  }}
                >
                  {getPeriodType(period)}
                </td>
                {dataPreviousPeriod.map((item, index) => {
                  const percentageChange = item.value === 0 ? 0 : ((data[index].value - item.value) / item.value) * 100;
                  return (
                    <td style={{ paddingLeft: 0, paddingRight: 0 }} key={index}>
                      <div className={"d-flex small justify-content-end"}>
                        <span className={"text-"}>{barValueFormatter(item.value)}</span>
                      </div>
                      <PercentageChangeText
                        value={percentageChange}
                        description={""}
                        valueFormatter={(value) => value.toFixed(0)}
                      />
                    </td>
                  );
                })}
                <td style={{ paddingLeft: 0, paddingRight: "0.75rem" }}>
                  <div className={"d-flex small justify-content-end"}>
                    <span>{barValueFormatter(totalPreviousPeriod)}</span>
                  </div>
                  <PercentageChangeText
                    value={percentageChangePrevious}
                    description={""}
                    valueFormatter={(value) => value.toFixed(0)}
                    extraClasses={"fw-bolder"}
                  />
                </td>
              </tr>
              {showPreviousYear && (
                <tr>
                  <td
                    style={{
                      textAlign: "left",
                      paddingLeft: "0.75rem",
                      paddingRight: 0,
                      fontSize: "0.8rem",
                    }}
                  >
                    Year
                  </td>
                  {dataPreviousYear.map((item, index) => {
                    const percentageChange =
                      item.value === 0 ? 0 : ((data[index].value - item.value) / item.value) * 100;
                    return (
                      <td key={index} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <div className={"d-flex small justify-content-end"}>
                          <span className={"text-"}>{barValueFormatter(item.value)}</span>
                        </div>
                        <PercentageChangeText
                          value={percentageChange}
                          description={""}
                          valueFormatter={(value) => value.toFixed(0)}
                        />
                      </td>
                    );
                  })}
                  <td style={{ paddingLeft: 0, paddingRight: "0.75rem" }}>
                    <div className={"d-flex small justify-content-end"}>
                      <span className={"text-"}>{barValueFormatter(totalPreviousYear)}</span>
                    </div>
                    <PercentageChangeText
                      value={percentageChangePreviousYear}
                      description={""}
                      valueFormatter={(value) => value.toFixed(0)}
                      extraClasses={"fw-bolder"}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BarChartStatsCard;
