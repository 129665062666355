import { useCallback, useEffect, useRef, useState } from "react";
import { apiUrl } from "../../layouts/adminLayout";
import { Convert } from "../../models/responses";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { euroFormatterTwoDecimals, paramsEuroFormatter } from "../../utils/formatters";
import Grid, { AggregateFunction, GridDropdownFilter } from "../../lib/organisms/Grid";

import { AgGridReact } from "ag-grid-react";
import { fetchOptions } from "../../hooks/useAuth";
import { AdminContextType, useUserContext } from "../../hooks/userUserContext";
import { PeriodName } from "../../models";

const AdminGroupedExpensesPage: React.FC = () => {
  const [expenses, setExpenses] = useState<Map<number, Map<number, number>>>(new Map());
  const [gridRows] = useState<{ [key: string]: number | string }[]>([]);
  const [colDefs, setColDefs] = useState<ColDef[]>([]);
  const gridRef = useRef<AgGridReact | any>();
  const gridName = "expenses";

  const {
    yearOptions,
    periodOptions,
    setSelectedPeriod,
    selectedPeriod,
    selectedYear,
    setSelectedGroup,
    groupOptions,
    setAggregateMethod,
    setSelectedYear,
    aggregateMethodOptions,
    apartmentIdMap,
    selectedGroup,
    expenseCategoriesMap,
    aggregateMethod,
  } = useUserContext().context as AdminContextType;

  const setColumnDefinitions = useCallback(
    (categories: Set<number>) => {
      const columns: ColDef[] = [
        {
          field: "apartment",
          headerName: "Apartment",
          pinned: "left",
          cellDataType: "text",
        },
      ];
      categories.forEach((categoryId) => {
        columns.push({
          field: categoryId.toString(),
          headerName: expenseCategoriesMap.get(categoryId)?.category,
          cellDataType: "number",
          valueFormatter: paramsEuroFormatter,
          type: ["rightAligned", "euroAmount"],
        });
      });
      columns.push({
        field: "total",
        headerName: "Total",
        valueFormatter: paramsEuroFormatter,
        pinned: "right",
        cellDataType: "number",
        type: ["rightAligned", "euroAmount"],
      });
      setColDefs(columns);
    },
    [expenseCategoriesMap],
  );

  const setRows = useCallback(
    (expensesByApartment: Map<number, Map<number, number>>) => {
      let rows: { [key: string]: number | string }[] = [];

      expensesByApartment.forEach((expenseMap, apartmentId) => {
        let row: { [key: string]: number | string } = { total: 0, total_average: 0 };
        row["id"] = apartmentId;
        row["apartment"] = apartmentIdMap.get(apartmentId)?.name || "Unknown";

        expenseMap.forEach((amount, categoryId) => {
          let categoryName = categoryId.toString();
          row[categoryName] = amount;
          row["total"] = (row["total"] as number) + amount;
        });
        rows.push(row);
      });
      if (gridRef.current && gridRef.current.api) {
        gridRef.current.api.setGridOption(
          "rowData",
          rows.sort((a, b) => (a.apartment as string).localeCompare(b.apartment as string)),
        );
      }
    },
    [apartmentIdMap],
  );

  const getAndSetExpenses = useCallback(
    (year: string, period: string, agg: string): void => {
      let url = `${apiUrl}/api/admin/apartments/expenses?aggregate=${agg}&year=${year}&period=${period}`;
      fetch(url, fetchOptions)
        .then((response) => {
          if (response) {
            return response.text();
          }
          throw new Error("Unauthorized");
        })
        .then((response) => {
          let expenses = Convert.toGetGroupedByExpensesResponse(response).expenses;
          const expensesByApartment = new Map<number, Map<number, number>>();
          const categories = new Set<number>();
          expenses.forEach((expense) => {
            if (!expensesByApartment.has(expense.apartmentId)) {
              expensesByApartment.set(expense.apartmentId, new Map());
            }
            categories.add(expense.categoryId);
            expensesByApartment.get(expense.apartmentId)?.set(expense.categoryId, expense.amount);
          });
          setColumnDefinitions(categories);
          setExpenses(expensesByApartment);
        })
        .catch((error) => console.log(error));
    },
    [expenseCategoriesMap],
  );

  useEffect(() => {
    if (selectedGroup) {
      const filteredExpenses = new Map<number, Map<number, number>>();
      new Map<number, Map<number, number>>();
      expenses.forEach((expenseMap, apartmentId) => {
        if (apartmentIdMap.get(apartmentId)?.groupId === selectedGroup) {
          filteredExpenses.set(apartmentId, expenseMap);
        }
      });
      setRows(filteredExpenses);
    } else {
      setRows(expenses);
    }
  }, [selectedGroup, expenses, selectedYear, expenseCategoriesMap, setRows, apartmentIdMap]);

  useEffect(() => {
    console.log(selectedYear, selectedPeriod, aggregateMethod);
    getAndSetExpenses(selectedYear.toString(), selectedPeriod, aggregateMethod);
  }, [selectedPeriod, selectedYear, apartmentIdMap, yearOptions, getAndSetExpenses, aggregateMethod]);

  const gridDropdownFilters: GridDropdownFilter[] = [
    {
      label: "Period",
      options: periodOptions,
      selectedOption: selectedPeriod,
      onSelectionChanged: (period) => setSelectedPeriod(period as PeriodName),
    },
    {
      label: "Year",
      options: yearOptions,
      selectedOption: selectedYear.toString(),
      onSelectionChanged: (year) => setSelectedYear(Number(year)),
    },
    {
      label: "Group",
      options: groupOptions,
      selectedOption: selectedGroup.toString(),
      onSelectionChanged: (apartment) => setSelectedGroup(Number(apartment)),
    },
    {
      label: "AggregateMethod",
      options: aggregateMethodOptions,
      selectedOption: aggregateMethod,
      onSelectionChanged: (aggregateMethod) => setAggregateMethod(aggregateMethod as "sum" | "avg"),
    },
  ];

  return (
    <Grid<{ [key: string]: number | string }>
      columnDefs={colDefs}
      gridName={gridName + " " + aggregateMethod}
      initialRowData={gridRows}
      gridRef={gridRef}
      dropdownFilters={gridDropdownFilters}
      showAggregateFilter={true}
      getRowId={(params) => params.data.apartment as string}
      columnsToAggregate={[
        ...colDefs
          .filter((col) => col.headerName !== "Apartment")
          .map((col) => {
            return {
              colDef: col,
              aggregateFunction: "sum" as AggregateFunction,
              formatter: euroFormatterTwoDecimals,
            };
          }),
        ...colDefs
          .filter((col) => col.headerName !== "Apartment")
          .map((col) => {
            return {
              colDef: col,
              aggregateFunction: "avg" as AggregateFunction,
              formatter: euroFormatterTwoDecimals,
            };
          }),
      ]}
    />
  );
};

export default AdminGroupedExpensesPage;
