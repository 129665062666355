import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { multiSelectDropdownStyle, singleSelectDropdownStyle } from "../../utils/graphs";
import Select, { ActionMeta, MultiValue, SingleValue } from "react-select";
import { SelectOption } from "../molecules/select";
import { useUserContext } from "../../hooks/userUserContext";
import { errorPopup } from "../../utils/alerts";
import { User } from "../../models/responses";

interface AddUserModalProps {
  show: boolean;
  handleClose: () => void;
  handleSubmitted: (
    firstName: string,
    lastName: string,
    email: string,
    isAdmin: boolean,
    visibleOwners: number[],
    existingUser?: User,
  ) => void;
  existingUser?: User;
}

const AddUserModal: React.FC<AddUserModalProps> = (props) => {
  const { ownerOptions } = useUserContext().context;
  const [firstName, setFirstName] = useState<string>(props.existingUser?.givenName || "");
  const [lastName, setLastName] = useState<string>(props.existingUser?.lastName || "");
  const [email, setEmail] = useState<string>(props.existingUser?.email || "");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isAdmin, setIsAdmin] = useState<boolean>(props.existingUser?.isAdmin || false);
  const [visibleOwners, setVisibleOwners] = useState<number[]>(props.existingUser?.visibleOwners || []);

  useEffect(() => {
    console.log(props.existingUser);
    setFirstName(props.existingUser?.givenName || "");
    setLastName(props.existingUser?.lastName || "");
    setEmail(props.existingUser?.email || "");
    setIsAdmin(props.existingUser?.isAdmin || false);
    setVisibleOwners(props.existingUser?.visibleOwners || []);
  }, [props.existingUser]);

  const validateAndSetEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);
    setIsEmailValid(isValid);
    setEmail(email);
  };

  const onSubmit = (event: React.FormEvent) => {
    console.log(event);
    event.preventDefault();
    try {
      props.handleSubmitted(firstName, lastName, email, isAdmin, visibleOwners, props.existingUser);
      handleCloseModal();
    } catch (error) {
      errorPopup(`Failed to add user: ${error}`);
      return;
    }
  };

  const handleCloseModal = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setIsAdmin(false);
    setVisibleOwners([]);
    props.handleClose();
  };

  const onVisibleOwnersChanged = (newValue: MultiValue<SelectOption>, actionMeta: ActionMeta<SelectOption>) => {
    const newOwnerIDs = newValue.map((owner) => Number(owner.value));
    setVisibleOwners(newOwnerIDs);
  };

  const onIsAdminChanged = (newValue: SingleValue<SelectOption>, actionMeta: ActionMeta<SelectOption>) => {
    setIsAdmin(newValue?.value === "true");
  };

  return (
    <Modal show={props.show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{`${props.existingUser ? "Edit" : "Add"} User`}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className={"mt-2"}>
            <Col>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  isInvalid={email !== "" && !isEmailValid}
                  value={email}
                  onChange={(event) => validateAndSetEmail(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col className={"col-md-3"}>
              <Form.Label>Admin</Form.Label>
              <Form.Group>
                <Select
                  options={[
                    { label: "Yes", value: "true" },
                    { label: "No", value: "false" },
                  ]}
                  blurInputOnSelect={false}
                  onChange={onIsAdminChanged}
                  styles={singleSelectDropdownStyle}
                  value={[{ label: isAdmin ? "Yes" : "No", value: isAdmin ? "true" : "false" }]}
                  className={"mb-2"}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className={"mt-2"}>
            <Form.Group>
              <Form.Label>Visible Owners</Form.Label>
              <Select
                options={ownerOptions.sort((a, b) => a.label.localeCompare(b.label))}
                isMulti={true}
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                onChange={onVisibleOwnersChanged}
                value={ownerOptions.filter((owner) => visibleOwners.includes(Number(owner.value)))}
                styles={multiSelectDropdownStyle}
                className={"mb-2"}
              />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="secondary" type="submit">
            {props.existingUser ? "Update" : "Add"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddUserModal;
