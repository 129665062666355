import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OccupancyItem } from "../models/responses";

type OccupancySliceState = {
  date: Date;
  selectedApartment: number;
  selectedGroup: number;
  occupancies: OccupancyItem[];
};

const getPreviousYearsDate = (date: Date) => new Date(date.getFullYear() - 1, date.getMonth(), date.getDate());

const initialState: OccupancySliceState = {
  date: new Date(),
  occupancies: [],
  selectedApartment: 0,
  selectedGroup: 0,
};

const occupancySlice = createSlice({
  name: "occupancy",
  initialState: initialState,
  reducers: {
    setDate: (state, action: PayloadAction<Date>) => {
      state.date = action.payload;
    },
    setSelectedApartment: (state, action: PayloadAction<number>) => {
      state.selectedApartment = action.payload;
    },
    setSelectedGroup: (state, action: PayloadAction<number>) => {
      state.selectedGroup = action.payload;
    },
  },
});

export const { setDate, setSelectedApartment, setSelectedGroup } = occupancySlice.actions;

export default occupancySlice.reducer;
