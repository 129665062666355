import { StylesConfig } from "react-select";
import { SelectOption } from "../lib/molecules/select";

export const singleSelectDropdownStyle: StylesConfig<SelectOption, false> = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "secondary",
    borderColor: "#4f5253",
    borderRadius: "0.75rem",
    minHeight: "38px",
    height: "auto",
    boxShadow: "none",
  }),
  menu: (provided) => ({
    ...provided,
    borderColor: "#ced4da",
    marginTop: ".5rem",
    borderRadius: "0.25rem",
    boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
    zIndex: 2,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#f0bc74" : undefined,
    color: state.isDisabled ? undefined : state.isSelected ? "black" : "black",
    ":active": {
      backgroundColor: state.isDisabled ? undefined : state.isSelected ? "#343a40" : "#f8f9fa",
    },
    borderRadius: "0.5rem", // Add this line to apply rounded corners
  }),
};

export const multiSelectDropdownStyle: StylesConfig<SelectOption, true> = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "secondary",
    borderColor: "#ced4da",
    borderRadius: "0.75rem",
    minHeight: "38px",
    height: "auto",
    width: 400,
    boxShadow: "none",
  }),
  menu: (provided) => ({
    ...provided,
    borderColor: "#ced4da",
    marginTop: ".5rem",
    borderRadius: "0.25rem",
    boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
    zIndex: 2,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled
      ? undefined
      : state.isSelected
        ? "#343a40"
        : state.isFocused
          ? "#f8f9fa"
          : undefined,
    ":active": {
      backgroundColor: state.isDisabled ? undefined : state.isSelected ? "#343a40" : "#f8f9fa",
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#1F2937",
    borderRadius: "0.75rem",
    maxWidth: "48%", // Set maximum width to 50%
    minWidth: "20%", // Set minimum width to 10%
    overflow: "hidden", // Hide text that exceeds the maximum width
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "white",
    maxWidth: "99%", // Set maximum width to 90% to leave space for the remove icon
    overflow: "hidden", // Hide text that exceeds the maximum width
    textOverflow: "ellipsis", // Add ellipsis (...) for text that is too long
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "white",
    ":hover": {
      backgroundColor: "#343a40",
      color: "white",
    },
  }),
};
