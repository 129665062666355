import {ChangeEvent, FC, useEffect, useState} from "react";
import {Dropdown} from "react-bootstrap";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface MultiSelectDropdownItemProps {
    label: string;
    input: string;
    isChecked: boolean;
}

export interface MultiSelectDropdownProps {
    color: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'danger' | 'gray-700' | 'gray-100';
    text: string;
    dropDownItems: MultiSelectDropdownItemProps[];
    onChanged: (items: MultiSelectDropdownItemProps[]) => void;
    extraClasses?: string;
}

const MultiSelectDropdown: FC<MultiSelectDropdownProps> = ({color, text, dropDownItems, onChanged, extraClasses}) => {


    const [items, setItems] = useState<MultiSelectDropdownItemProps[]>(dropDownItems);

    function handleInputChanged(event: ChangeEvent<HTMLInputElement>) {
        const {checked, id} = event.target;
        const newItems = items.map(item => {
            if (item.input === id) {
                return {...item, isChecked: checked}
            }
            return item;
        })
        setItems(newItems);
        onChanged(newItems)
    }

    useEffect(() => {
        setItems(dropDownItems);
    }, [dropDownItems]);


    return <div style={{overflow: "visible"}}
                className={`btn-group col-auto ${extraClasses ? extraClasses : ''}`}>
        <Dropdown style={{overflow: "visible"}} onSelect={(eventKey, event) => event?.stopPropagation()}>
            <Dropdown.Toggle variant={color} id="multiSelectDropdown">
                {text} <FontAwesomeIcon className="ms-2" icon={faCaretDown}/></Dropdown.Toggle>

            <Dropdown.Menu>
                {items.map((item, index) => {
                    return <Dropdown.Item as="li" key={index} onClick={(event) => event.stopPropagation()}>
                        <div className="form-check">
                            <label className="form-check-label" htmlFor={item.input}
                                   onClick={(event) => event.stopPropagation()}>
                                {item.label}
                            </label>
                            <input className="form-check-input" type="checkbox" checked={item.isChecked} id={item.input}
                                   onChange={handleInputChanged} onClick={(event) => event.stopPropagation()}
                            />
                        </div>
                    </Dropdown.Item>
                })}
            </Dropdown.Menu>
        </Dropdown>
    </div>
}

export default MultiSelectDropdown;
export type {MultiSelectDropdownItemProps};