import React, { useCallback, useEffect, useRef, useState } from "react";
import { apiUrl } from "../../layouts/adminLayout";
import { Convert, Expense } from "../../models/responses";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { dateFormatter, euroFormatterTwoDecimals, paramsEuroFormatter } from "../../utils/formatters";
import Grid, { GridDropdownFilter } from "../../lib/organisms/Grid";
import { AgGridReact } from "ag-grid-react";
import { errorPopup, successPopup } from "../../utils/alerts";
import Swal from "sweetalert2";
import { fetchOptions, useAuth } from "../../hooks/useAuth";
import { useUserContext } from "../../hooks/userUserContext";
import { PeriodName } from "../../models";
import FileUploadModal from "../../lib/organisms/newFileUploadModal";
import { decodeErrorResponse } from "../../utils/errors";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

interface ExpenseRow {
  id: number;
  apartment: string;
  expenseCategory: string;
  date: Date;
  amount: number;
  notes: string;
}

const ExpensesPage: React.FC = () => {
  const { user } = useAuth();
  const [expenses, setExpenses] = useState<ExpenseRow[]>([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const gridRef = useRef<AgGridReact | any>();
  const gridName = "Expenses";

  const {
    selectedPeriod,
    setSelectedPeriod,
    periodOptions,
    selectedYear,
    setSelectedApartment,
    ownerOptions,
    setSelectedOwner,
    apartmentOptions,
    setSelectedYear,
    yearOptions,
    selectedApartment,
    apartmentIdMap,
    expenseCategoriesMap,
    selectedOwner,
  } = useUserContext().context;

  const handleFileUpload = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    Swal.fire({
      title: "Uploading file...",
      allowOutsideClick: false,
    });
    Swal.showLoading();
    fetch(`${apiUrl}/api/admin/expenses/upload`, {
      method: "POST",
      body: formData,
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          return decodeErrorResponse(response);
        }
        return response.text();
      })
      .then((response) => {
        const importResult = Convert.toImportExpensesResponse(response).importExpensesResult;
        successPopup(
          `Successfully processed expenses file\n New items: ${importResult.newExpenseItems} expenses, Deleted items: ${importResult.DeletedExpenseItems}`,
        );
      })
      .catch((error) => {
        Swal.close();
        errorPopup(error.message);
      });
  };

  const handleDeleteSelectedRows = (selectedRows: ExpenseRow[]) => {
    let idsToDelete = selectedRows.map((row) => row.id);
    fetch(`${apiUrl}/api/admin/expenses/delete`, {
      method: "POST",
      body: JSON.stringify({ ids: idsToDelete }),
      credentials: "include",
    })
      .then((response) => {
        if (!response) {
          throw new Error("Unauthorized");
        }
        if (response.ok) {
          setExpenses(expenses.filter((row) => !idsToDelete.includes(row.id)));
          console.log("Deleted successfully");
        } else {
          console.log("Failed to delete");
        }
      })
      .catch((error) => console.log(error));
  };

  const getAndSetExpenses = useCallback(
    (year: string, period: string, apartmentId: string, ownerId: string): void => {
      let url;
      console.log(apartmentId);
      if (apartmentId === "0") {
        url = `${apiUrl}/api${user?.isAdmin ? "/admin" : "/owners/" + ownerId}/expenses?year=${year}&period=${period}`;
      } else {
        url = `${apiUrl}/api${user?.isAdmin ? "/admin" : "/owners/" + ownerId}/apartments/${apartmentId}/expenses?year=${year}&period=${period}`;
      }
      fetch(url, fetchOptions)
        .then((response) => {
          if (response) {
            return response.text();
          }
          throw new Error("Unauthorized");
        })
        .then((response) => {
          let responseJson;
          try {
            responseJson = Convert.toGetExpensesResponse(response);
          } catch (e) {
            console.error("Invalid JSON string:", response);
            throw e;
          }
          setExpenses(
            responseJson.expenses.map((expense: Expense) => {
              return {
                id: expense.id,
                apartment: apartmentIdMap.get(expense.apartmentId)?.name || "N/A",
                expenseCategory: expenseCategoriesMap.get(expense.categoryId)?.category || "N/A",
                date: new Date(expense.date),
                amount: expense.amount,
                notes: expense.notes,
              };
            }),
          );
        })
        .catch((error) => console.log(error));
    },
    [apartmentIdMap, expenseCategoriesMap],
  );

  const amountRow: ColDef<ExpenseRow, any> = {
    field: "amount",
    headerName: "Amount",
    valueFormatter: paramsEuroFormatter,
    cellDataType: "number",
    type: ["rightAligned", "euroAmount"],
  };
  const columnDefinitions: ColDef[] = [
    { field: "apartment", headerName: "Apartment" },
    { field: "expenseCategory", headerName: "Category" },
    { field: "date", headerName: "Date", valueFormatter: dateFormatter },
    amountRow,

    { field: "notes", headerName: "Notes", flex: 1 },
  ];

  useEffect(() => {
    console.log(expenseCategoriesMap);
    getAndSetExpenses(selectedYear.toString(), selectedPeriod, selectedApartment.toString(), selectedOwner.toString());
  }, [selectedPeriod, selectedYear, apartmentIdMap, selectedApartment, expenseCategoriesMap, selectedOwner]);

  const gridDropdownFilters: GridDropdownFilter[] = [
    {
      label: "Period",
      options: periodOptions,
      selectedOption: selectedPeriod,
      onSelectionChanged: (period) => setSelectedPeriod(period as PeriodName),
    },
    {
      label: "Year",
      options: yearOptions,
      selectedOption: selectedYear.toString(),
      onSelectionChanged: (year) => setSelectedYear(Number(year)),
    },
  ];

  if (user?.isAdmin) {
    gridDropdownFilters.push({
      label: "Apartment",
      options: apartmentOptions,
      selectedOption: selectedApartment.toString(),
      onSelectionChanged: (apartment) => setSelectedApartment(Number(apartment)),
    });
  } else {
    gridDropdownFilters.push({
      label: "Owner",
      options: ownerOptions,
      selectedOption: selectedOwner.toString(),
      onSelectionChanged: (owner) => setSelectedOwner(Number(owner)),
    });
  }

  const importExpensesButton = { label: "Import", onClick: () => setShowUploadModal(true), faIcon: faAdd };
  return (
    <>
      <FileUploadModal
        show={showUploadModal}
        handleClose={() => setShowUploadModal(false)}
        handleFileUpload={handleFileUpload}
      />
      <Grid<ExpenseRow>
        columnDefs={columnDefinitions}
        gridName={gridName}
        initialRowData={expenses}
        gridRef={gridRef}
        buttons={user?.isAdmin ? [importExpensesButton] : []}
        dropdownFilters={gridDropdownFilters}
        columnsToAggregate={[
          { colDef: amountRow, formatter: euroFormatterTwoDecimals },
          { colDef: amountRow, formatter: euroFormatterTwoDecimals, aggregateFunction: "avg" },
        ]}
      />
    </>
  );
};

export default ExpensesPage;
