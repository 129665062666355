export const PrivacyPolicy = () => {
  return (
    <div className={"container"}>
      <h1>Privacy Policy</h1>
      <p>
        <i>Last updated: 13/07/2024</i>
      </p>
      <p>
        Our application respects your privacy and is committed to protecting it through our compliance with this policy.
        This policy describes the types of information we may collect or that you may provide when you use our
        application, and our practices for collecting, using, maintaining, protecting, and disclosing that information.
      </p>
      <p>
        Specifically, this policy covers how we handle Google user data, in compliance with the Google API Service: User
        Data Policy. Our use of Google user data is limited to the practices described in this privacy policy.
      </p>

      <h2>Information We Collect and How We Use It</h2>
      <p>
        We collect information directly from you when you provide it to us and automatically when you use the
        application. This includes information you provide when you sign up for our service, and information collected
        through your interaction with our application.
      </p>

      <h3>Information You Provide to Us</h3>
      <p>When you use our application, we may ask you to provide your:</p>
      <ul>
        <li>Email Address: Used for authenticating the user and sending application notifications.</li>
        <li>Name: Displayed in the top right corner of our application for a personalized user experience.</li>
        <li>
          Google Profile Image: Displayed in the top right corner of our application for a personalized user experience.
        </li>
      </ul>
      <p>
        This information is stored in a secure Postgres database, with encryption at rest to ensure the safety and
        privacy of your data.
      </p>

      <h2>How We Use Your Information</h2>
      <p>
        We use information that we collect about you or that you provide to us, including any personal information, to:
      </p>
      <ul>
        <li>Present our application and its contents to you.</li>
        <li>
          Provide you with information, products, or services that you request from us, such as new reservations for
          your apartments.
        </li>
        <li>Fulfill any other purpose for which you provide it.</li>
        <li>
          Carry out our obligations and enforce our rights arising from any contracts entered into between you and us.
        </li>
        <li>Notify you about changes to our application or any products or services we offer or provide through it.</li>
      </ul>
      <p>
        We do not share your Google user data with any third parties. Your information is used solely for the purposes
        described in this privacy policy.
      </p>

      <h2>Data Security</h2>
      <p>
        We have implemented measures designed to secure your personal information from accidental loss and from
        unauthorized access, use, alteration, and disclosure. The safety and security of your information also depend on
        you. Where we have given you (or where you have chosen) a password for access to certain parts of our
        application, you are responsible for keeping this password confidential. We ask you not to share your password
        with anyone.
      </p>

      <h2>Changes to Our Privacy Policy</h2>
      <p>
        We may update our privacy policy from time to time. If we make material changes to how we treat our users'
        personal information, we will notify you through a notice on the application home page.
      </p>

      <h2>Contact Information</h2>
      <p>
        To ask questions or comment about this privacy policy and our privacy practices, contact us at:{" "}
        <a href={"mailto:contact@mikemylonakis.com"}>contact@mikemylonakis.com</a>.
      </p>
    </div>
  );
};
