import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BookingPaceItem, Convert, OccupancyItem } from "../models/responses";
import { apiUrl } from "../layouts/adminLayout";

export const bookingManagementApi = createApi({
  reducerPath: "bookingManagementApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${apiUrl}/api/admin/`, credentials: "include" }),
  endpoints: (builder) => ({
    getBookingPace: builder.query<BookingPaceItem[], { startDate: string; endDate: string }>({
      query: ({ startDate, endDate }) => `pace?startDate=${startDate}&endDate=${endDate}`,
      transformResponse: (response: string) => {
        const data = Convert.toGetBookingPaceResponse(JSON.stringify(response));
        return data?.bookingPace || [];
      },
    }),
    getOccupancies: builder.query<OccupancyItem[], { date: string; apartmentId: number; groupId: number }>({
      query: ({ date, apartmentId, groupId }) => {
        const params = new URLSearchParams({
          date: date.toString(),
        });

        if (apartmentId && apartmentId !== 0) {
          params.append("apartmentId", apartmentId.toString());
        }

        if (groupId && groupId !== 0) {
          params.append("groupId", groupId.toString());
        }
        return {
          url: `occupancies?${params.toString()}`,
        };
      },
      transformResponse: (response: string) => {
        const data = Convert.toGetOccupanciesResponse(JSON.stringify(response));
        return data?.occupancies || [];
      },
    }),
  }),
});

export const { useGetBookingPaceQuery, useGetOccupanciesQuery } = bookingManagementApi;
