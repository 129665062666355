import React, { useEffect, useState } from "react";
import { Apartment, YearAndPeriod } from "../../models/";
import { Convert, ExpenseCategory } from "../../models/responses";
import { apiUrl } from "../../layouts/adminLayout";
import { useUserContext } from "../../hooks/userUserContext";
import { fetchOptions } from "../../hooks/useAuth";

interface ExpenseTableProps {
  apartments: Apartment[];
  apartmentIdMap: Map<number, Apartment>;
  expenseCategories: Map<number, ExpenseCategory>;
  yearAndPeriod: YearAndPeriod;
}

const ExpenseTable: React.FC<ExpenseTableProps> = ({
  apartments,
  apartmentIdMap,
  expenseCategories,
  yearAndPeriod,
}) => {
  const [totalExpensesPerApartment, setTotalExpensesPerApartment] = useState<Map<number, number>>(new Map());
  const [expensesByCategoryByApartment, setExpensesByCategoryByApartment] = useState<Map<number, Map<number, number>>>(
    new Map<number, Map<number, number>>(),
  );
  const [totalExpensesPerCategory, setTotalExpensesPerCategory] = useState<Map<number, number>>(new Map());
  const { selectedOwner } = useUserContext().context;

  useEffect(() => {
    if (apartments.length === 0) {
      return;
    }
    let url = `${apiUrl}/api/owners/${selectedOwner}/expenses?aggregate=sum&year=${yearAndPeriod.year}&period=${yearAndPeriod.period}`;
    fetch(url, fetchOptions)
      .then((response) => {
        if (response) {
          return response.text();
        }
        throw new Error("Unauthorized");
      })
      .then((data) => {
        console.log(data);
        const groupedExpenses = Convert.toGetGroupedByExpensesResponse(data).expenses;
        const totalExpensesPerApartment = new Map<number, number>();
        const totalExpensesPerCategory = new Map<number, number>();
        const expensesByCategoryByApartment = new Map<number, Map<number, number>>();
        groupedExpenses.forEach((expense) => {
          totalExpensesPerCategory.set(
            expense.categoryId,
            (totalExpensesPerCategory.get(expense.categoryId) || 0) + expense.amount,
          );
          totalExpensesPerApartment.set(
            expense.apartmentId,
            (totalExpensesPerApartment.get(expense.apartmentId) || 0) + expense.amount,
          );
          if (!expensesByCategoryByApartment.has(expense.categoryId)) {
            expensesByCategoryByApartment.set(expense.categoryId, new Map());
          }
          let categoryMap = expensesByCategoryByApartment.get(expense.categoryId);
          if (categoryMap) {
            categoryMap.set(expense.apartmentId, expense.amount);
          }
        });
        setTotalExpensesPerApartment(totalExpensesPerApartment);
        setExpensesByCategoryByApartment(expensesByCategoryByApartment);
        setTotalExpensesPerCategory(totalExpensesPerCategory);
      });
  }, [apartments, yearAndPeriod.period, yearAndPeriod.year]);

  return (
    <div className="table-responsive table-sm font-extra-small">
      <table className="mb-0 table-sm" style={{ width: "100%" }}>
        <thead className="bg-light border-top">
          <tr>
            <th scope="row" className="border-0 text-left">
              Expense Type
            </th>
            {apartments.map((apartment) => (
              <th key={apartment.id} scope="row" className="border-0" style={{ textAlign: "right" }}>
                {apartment.shortName || apartment.name}
              </th>
            ))}
            <th scope="row" className="border-0 bg-gray-300" style={{ textAlign: "right" }}>
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.from(expensesByCategoryByApartment).map(([categoryId, apartmentExpensesForCategory]) => (
            <tr key={categoryId}>
              <td className="text-left">{expenseCategories.get(categoryId)?.category}</td>
              {apartments.map((apartment) => (
                <td key={apartment.id} style={{ textAlign: "right" }}>
                  {apartmentExpensesForCategory.get(apartment.id)
                    ? apartmentExpensesForCategory.get(apartment.id) + " €"
                    : 0}
                </td>
              ))}
              <td
                className={"bg-gray-300"}
                style={{
                  textAlign: "right",
                  fontWeight: "bold",
                }}
              >
                {totalExpensesPerCategory.get(categoryId)
                  ? totalExpensesPerCategory.get(categoryId)?.toFixed(2) + " €"
                  : 0}
              </td>
            </tr>
          ))}
          <tr>
            <td className="text-left">Total</td>
            {apartments.map((apartment) => (
              <td key={apartment.id} style={{ textAlign: "right" }}>
                {totalExpensesPerApartment.get(apartment.id)?.toFixed(2) + " €" || 0}
              </td>
            ))}
            <td className={"bg-gray-300"} style={{ textAlign: "right", fontWeight: "bold" }}>
              {Array.from(totalExpensesPerApartment.values())
                .reduce((total, amount) => total + amount, 0)
                .toFixed(2) + " €"}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ExpenseTable;
