import React, { useEffect, useRef, useState } from "react";
import { apiUrl } from "../layouts/adminLayout";
import { ApartmentStatTrend, Convert } from "../models/responses";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import {
  euroFormatterTwoDecimals,
  occupancyBarRenderer,
  paramsEuroFormatter,
  percentageFormatterMultipliedByHundred,
} from "../utils/formatters";
import Grid, { GridDropdownFilter } from "../lib/organisms/Grid";
import { AgGridReact } from "ag-grid-react";
import { fetchOptions } from "../hooks/useAuth";
import { useUserContext } from "../hooks/userUserContext";
import { PeriodName } from "../models";

interface TrendsRow {
  apartment: string;
  numberOfVisits: number;
  grossIncome: number;
  grossIncomePreviousPeriod: number;
  grossIncomePreviousYear: number;
  netIncome: number;
  netIncomePreviousPeriod: number;
  netIncomePreviousYear: number;
  netAdr: number;
  netAdrPreviousPeriod: number;
  netAdrPreviousYear: number;
  grossAdr: number;
  grossAdrPreviousPeriod: number;
  grossAdrPreviousYear: number;
  occupancy: number;
  occupancyPreviousPeriod: number;
  occupancyPreviousYear: number;
  daysBooked: number;
  daysBookedPreviousPeriod: number;
  daysBookedPreviousYear: number;
  daysAvailable: number;
  daysAvailablePreviousPeriod: number;
  daysAvailablePreviousYear: number;
  payout: number;
  payoutPreviousYear: number;
  payoutRatio: number;
  payoutRatioPreviousYear: number;
}

const UserTrends: React.FC = () => {
  const [apartmentTrends] = useState<TrendsRow[]>([]);
  const gridRef = useRef<AgGridReact | any>();

  const {
    selectedPeriod,
    selectedYear,
    apartmentIdMap,
    periodOptions,
    setSelectedPeriod,
    yearOptions,
    setSelectedYear,
    selectedOwner,
    setSelectedOwner,
    ownerOptions,
  } = useUserContext().context;

  const getAndSetTrends = (year: string, period: string, ownerId: string): void => {
    const url = `${apiUrl}/api/owners/${ownerId}/trends?year=${year}&period=${period}`;
    fetch(url, fetchOptions)
      .then((response) => {
        if (response) {
          return response.text();
        }
        throw new Error("Unauthorized");
      })
      .then((response) => {
        let trends = Convert.toGetApartmentStatTrends(response)
          .apartmentStatTrends.map((trend) => mapTrendToRow(trend))
          .sort((a, b) => a.apartment.localeCompare(b.apartment));
        gridRef.current!.api.setGridOption("rowData", trends);
      })
      .catch((error) => console.log(error));
  };

  const daysBookedColumn: ColDef<TrendsRow, any> = { field: "daysBooked", headerName: "Days Booked", width: 100 };
  const grossIncomeColumn: ColDef<TrendsRow, any> = {
    field: "grossIncome",
    headerName: "Gross Income",
    valueFormatter: paramsEuroFormatter,
    type: ["rightAligned", "euroAmount"],
    cellDataType: "number",
  };
  const netIncomeColumn: ColDef<TrendsRow, any> = {
    field: "netIncome",
    headerName: "Net Income",
    valueFormatter: paramsEuroFormatter,
    type: ["rightAligned", "euroAmount"],
    cellDataType: "number",
  };
  const numberOfVisitsColumn: ColDef<TrendsRow, any> = {
    field: "numberOfVisits",
    headerName: "Number of Visits",
    width: 130,
  };
  const netAdrColumn: ColDef<TrendsRow, any> = {
    field: "netAdr",
    headerName: "Net Adr",
    valueFormatter: paramsEuroFormatter,
    type: ["rightAligned", "euroAmount"],
    cellDataType: "number",
  };
  const grossAdrColumn: ColDef<TrendsRow, any> = {
    field: "grossAdr",
    headerName: "Gross Adr",
    valueFormatter: paramsEuroFormatter,
    type: ["rightAligned", "euroAmount"],
    cellDataType: "number",
  };
  const columnDefinitions: ColDef[] = [
    { field: "apartment", headerName: "Apartment", width: 400 },
    numberOfVisitsColumn,
    { field: "occupancy", headerName: "Occupancy", cellRenderer: occupancyBarRenderer, type: ["percentage"] },
    {
      field: "occupancyPreviousPeriod",
      headerName: "Occupancy Previous Period",
      cellRenderer: occupancyBarRenderer,
      type: ["percentage"],
    },
    {
      field: "occupancyPreviousYear",
      headerName: "Occupancy Previous Year",
      cellRenderer: occupancyBarRenderer,
      type: ["percentage"],
    },
    daysBookedColumn,
    { field: "daysBookedPreviousPeriod", headerName: "Days Booked Previous Period", width: 200 },
    { field: "daysBookedPreviousYear", headerName: "Days Booked Previous Year", width: 200 },
    { field: "daysAvailable", headerName: "Days Available", width: 200 },
    { field: "daysAvailablePreviousPeriod", headerName: "Days Available Previous Period", width: 200 },
    { field: "daysAvailablePreviousYear", headerName: "Days Available Previous Year", width: 200 },
    grossIncomeColumn,
    {
      field: "grossIncomePreviousPeriod",
      headerName: "Gross Income Previous Period",
      valueFormatter: paramsEuroFormatter,
      type: ["rightAligned", "euroAmount"],
      cellDataType: "number",
    },
    {
      field: "grossIncomePreviousYear",
      headerName: "Gross Income Previous Year",
      valueFormatter: paramsEuroFormatter,
      type: ["rightAligned", "euroAmount"],
      cellDataType: "number",
    },
    netIncomeColumn,
    {
      field: "netIncomePreviousPeriod",
      headerName: "Net Income Previous Period",
      valueFormatter: paramsEuroFormatter,
      type: ["rightAligned", "euroAmount"],
      cellDataType: "number",
    },
    {
      field: "netIncomePreviousYear",
      headerName: "Net Income Previous Year",
      valueFormatter: paramsEuroFormatter,
      type: ["rightAligned", "euroAmount"],
      cellDataType: "number",
    },
    netAdrColumn,
    {
      field: "netAdrPreviousPeriod",
      headerName: "Net Adr Previous Period",
      valueFormatter: paramsEuroFormatter,
      type: ["rightAligned", "euroAmount"],
      cellDataType: "number",
    },
    {
      field: "netAdrPreviousYear",
      headerName: "Net Adr Previous Year",
      valueFormatter: paramsEuroFormatter,
      type: ["rightAligned", "euroAmount"],
      cellDataType: "number",
    },
    grossAdrColumn,
    {
      field: "grossAdrPreviousPeriod",
      headerName: "Gross Adr Previous Period",
      valueFormatter: paramsEuroFormatter,
      type: ["rightAligned", "euroAmount"],
      cellDataType: "number",
    },
    {
      field: "grossAdrPreviousYear",
      headerName: "Gross Adr Previous Year",
      valueFormatter: paramsEuroFormatter,
      type: ["rightAligned", "euroAmount"],
      cellDataType: "number",
    },
    {
      field: "payout",
      headerName: "Payout",
      valueFormatter: paramsEuroFormatter,
      type: ["rightAligned", "euroAmount"],
      cellDataType: "number",
    },
    {
      field: "payoutPreviousYear",
      headerName: "Payout Previous Year",
      valueFormatter: paramsEuroFormatter,
      type: ["rightAligned", "euroAmount"],
      cellDataType: "number",
    },
    {
      field: "payoutRatio",
      headerName: "Payout Ratio",
      valueFormatter: percentageFormatterMultipliedByHundred,
      cellDataType: "number",
      type: ["percentage"],
    },
    {
      field: "payoutRatioPreviousYear",
      headerName: "Payout Ratio Previous Year",
      valueFormatter: percentageFormatterMultipliedByHundred,
      cellDataType: "number",
      type: ["percentage"],
    },
  ];

  function mapTrendToRow(trend: ApartmentStatTrend): TrendsRow {
    return {
      apartment: apartmentIdMap.get(trend.apartmentId)?.name || "Unknown",
      numberOfVisits: trend.numberOfVisits,
      grossIncome: trend.grossIncome,
      grossIncomePreviousPeriod: trend.grossIncomePreviousPeriod,
      grossIncomePreviousYear: trend.grossIncomePreviousYear,
      netIncome: trend.netIncome,
      netIncomePreviousPeriod: trend.netIncomePreviousPeriod,
      netIncomePreviousYear: trend.netIncomePreviousYear,
      netAdr: trend.netAdr,
      netAdrPreviousPeriod: trend.netAdrPreviousPeriod,
      netAdrPreviousYear: trend.netAdrPreviousYear,
      grossAdr: trend.grossAdr,
      grossAdrPreviousPeriod: trend.grossAdrPreviousPeriod,
      grossAdrPreviousYear: trend.grossAdrPreviousYear,
      occupancy: trend.occupancy,
      occupancyPreviousPeriod: trend.occupancyPreviousPeriod,
      occupancyPreviousYear: trend.occupancyPreviousYear,
      daysBooked: trend.daysBooked,
      daysBookedPreviousPeriod: trend.daysBookedPreviousPeriod,
      daysBookedPreviousYear: trend.daysBookedPreviousYear,
      daysAvailable: trend.daysAvailable,
      daysAvailablePreviousPeriod: trend.daysAvailablePreviousPeriod,
      daysAvailablePreviousYear: trend.daysAvailablePreviousYear,
      payout: trend.payout,
      payoutPreviousYear: trend.payoutPreviousYear,
      payoutRatio: trend.payoutRatio,
      payoutRatioPreviousYear: trend.payoutRatioPreviousYear,
    };
  }

  useEffect(() => {
    getAndSetTrends(selectedYear.toString(), selectedPeriod, selectedOwner.toString());
  }, [selectedPeriod, selectedYear, apartmentIdMap, yearOptions, selectedOwner]);

  const gridDropdownFilters: GridDropdownFilter[] = [
    {
      label: "Period",
      options: periodOptions,
      selectedOption: selectedPeriod,
      onSelectionChanged: (period) => setSelectedPeriod(period as PeriodName),
    },
    {
      label: "Year",
      options: yearOptions,
      selectedOption: selectedYear.toString(),
      onSelectionChanged: (year) => setSelectedYear(Number(year)),
    },
    {
      label: "Owner",
      options: ownerOptions,
      selectedOption: selectedOwner.toString(),
      onSelectionChanged: (apartment) => setSelectedOwner(Number(apartment)),
    },
  ];

  return (
    <Grid<TrendsRow>
      columnDefs={columnDefinitions}
      dropdownFilters={gridDropdownFilters}
      gridName={"Trends"}
      initialRowData={apartmentTrends}
      gridRef={gridRef}
      columnsToAggregate={[
        { colDef: daysBookedColumn, formatter: (x) => x },
        { colDef: numberOfVisitsColumn, formatter: (x) => x },
        { colDef: grossIncomeColumn, formatter: euroFormatterTwoDecimals },
        { colDef: netIncomeColumn, formatter: euroFormatterTwoDecimals },
        { colDef: netAdrColumn, formatter: euroFormatterTwoDecimals, aggregateFunction: "avg" },
        {
          colDef: grossAdrColumn,
          formatter: euroFormatterTwoDecimals,
          aggregateFunction: "avg",
        },
      ]}
    />
  );
};

export default UserTrends;
