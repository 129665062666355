import React, { useCallback, useEffect, useRef } from "react";
import { apiUrl } from "../../layouts/adminLayout";
import { Select as SelectM } from "../../lib/molecules/select";
import {
  ApartmentStatsWithDate,
  Convert,
  GroupStatsWithDate,
  PayoutStatsWithDate,
  TotalDepositsWithDate,
} from "../../models/responses";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Payload } from "recharts/types/component/DefaultLegendContent";
import { overViewIncomeFormatter } from "../../utils/formatters";
import Select, { ActionMeta, MultiValue } from "react-select";
import { multiSelectDropdownStyle } from "../../utils/graphs";
import { fetchOptions } from "../../hooks/useAuth";
import { AdminContextType, useUserContext } from "../../hooks/userUserContext";

interface SelectOption {
  label: string;
  value: string;
}

type Metric =
  | "grossIncome"
  | "netIncome"
  | "occupancy"
  | "payout"
  | "cumulativeDeposit"
  | "deposit"
  | "numberOfVisits"
  | "grossAdr"
  | "netAdr"
  | "averageLidr"
  | "averageLengthOfStay";
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const GraphsPage: React.FC = () => {
  const now = new Date();
  const lastMonthOfYear = new Date(now.getFullYear(), 12, 1);
  const { apartmentOptions, groupIdMap, apartmentIdMap } = useUserContext().context as AdminContextType;
  const metricOptions: { label: string; value: Metric; formatter: (value: number) => string }[] = [
    { label: "Gross Income", value: "grossIncome", formatter: overViewIncomeFormatter },
    { label: "Net Income", value: "netIncome", formatter: overViewIncomeFormatter },
    { label: "Gross ADR", value: "grossAdr", formatter: overViewIncomeFormatter },
    { label: "Net ADR", value: "netAdr", formatter: overViewIncomeFormatter },
    { label: "Average LIDR", value: "averageLidr", formatter: (value: number) => value.toFixed(1) },
    { label: "Average Length of Stay", value: "averageLengthOfStay", formatter: (value: number) => value.toFixed(1) },
    { label: "Occupancy", value: "occupancy", formatter: (value: number) => value.toFixed(0) + "%" },
    // {label: "Payouts", value: "payout"},
    // {label: "Deposit", value: "deposit"},
    { label: "Number of Visits", value: "numberOfVisits", formatter: (value: number) => value.toFixed(0) },
  ];
  const payoutMetric: { label: string; value: Metric; formatter: (value: number) => string } = {
    label: "Payouts",
    value: "payout",
    formatter: overViewIncomeFormatter,
  };
  const depositMetric: { label: string; value: Metric; formatter: (value: number) => string } = {
    label: "Deposit",
    value: "cumulativeDeposit",
    formatter: overViewIncomeFormatter,
  };

  const [selectedMetric, setSelectedMetric] = React.useState<{
    label: string;
    value: Metric;
    formatter: (value: number) => string;
  }>(metricOptions[0]);

  const [startDate, setStartDate] = React.useState<Date>(new Date("2022-01-01"));
  const [endDate, setEndDate] = React.useState<Date>(lastMonthOfYear);
  const selectedApartmentsRef = useRef<SelectOption[]>([]);
  const groupVisibilityRef = useRef<Map<string, boolean>>(new Map());
  const yearOverYearGroupVisibilityRef = useRef<Map<string, boolean>>(new Map());

  function onMetricChanged(event: React.ChangeEvent<HTMLSelectElement>) {
    metricOptions.forEach((option) => {
      if (option.value === event.target.value) {
        setSelectedMetric(option);
      }
    });
  }

  const ChartTitle = ({ title }: { title: string }) => {
    return <h5 style={{ textAlign: "center", marginBottom: "1em" }}>{title}</h5>;
  };

  interface MetricLineChartProps {
    data: any[];
    type:
      | "Group"
      | "Total"
      | "Group - Year Over Year"
      | "Apartment"
      | "Apartment Year Over Year"
      | "Apartment Payouts"
      | "Apartment Cumulative"
      | "Apartment Payouts Year Over Year";
    selectedMetric: {
      label: string;
      value: Metric;
      formatter: (value: number) => string;
    };
    lineNames: Map<string, boolean>;
    handleLegendClick: (data: Payload, index: number) => void;
  }

  const MetricLineChart = React.memo(
    ({ type, data, selectedMetric, lineNames, handleLegendClick }: MetricLineChartProps) => {
      return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <ChartTitle title={`${type} ${selectedMetric.label} trend`} />
          <div style={{ width: "100%" }}>
            <ResponsiveContainer width="100%" height={500}>
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" angle={-20} textAnchor="end" height={50} />
                <YAxis tickFormatter={selectedMetric.formatter} width={80} tickCount={10} />
                <Tooltip
                  formatter={(value: any, name: any, props: any) => {
                    return [selectedMetric.formatter(value), name];
                  }}
                />
                <Legend verticalAlign="top" onClick={(data, index) => handleLegendClick(data, index)} />
                {Array.from(lineNames).map((groupName: [string, boolean], index: number) => {
                  const colors = [
                    "#000000",
                    "#FF0000",
                    "#00FF00",
                    "#0000FF",
                    "#FF00FF",
                    "#808080",
                    "#800000",
                    "#808000",
                    "#008000",
                    "#800080",
                    "#008080",
                    "#000080",
                    "#808040",
                    "#400080",
                    "#804000",
                    "#008040",
                    "#8000FF",
                    "#408080",
                    "#804080",
                    "#80FF80",
                    "#408040",
                    "#FF8080",
                    "#404080",
                    "#80FF00",
                    "#FF8000",
                    "#FF80FF",
                    "#00FF80",
                    "#00FF40",
                  ];
                  return (
                    <Line
                      key={index}
                      type="linear"
                      dataKey={groupName[0]}
                      stroke={colors[index % colors.length]}
                      strokeWidth={2}
                      activeDot={{ r: 8 }}
                      hide={groupName[1]}
                    />
                  );
                })}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    },
  );

  const arePropsEqual = (prevProps: GroupMetricLineChartProps, nextProps: GroupMetricLineChartProps) => {
    // Perform a deep equality check on the startDate and endDate props
    const areDatesEqual =
      prevProps.start === nextProps.start && prevProps.end === nextProps.end && prevProps.metric === nextProps.metric;
    // Only re-render if the dates have changed
    return areDatesEqual;
  };

  interface GroupMetricLineChartProps {
    start: Date;
    end: Date;
    metric: {
      label: string;
      value: Metric;
      formatter: (value: number) => string;
    };
    groupVisibility: Map<string, boolean>;
    yearOverYearGroupVisibility: Map<string, boolean>;
  }

  const GroupsMetricLineChart: React.FC<GroupMetricLineChartProps> = React.memo(
    ({ start, end, metric, groupVisibility, yearOverYearGroupVisibility }) => {
      const [groupstats, setGroupstats] = React.useState<GroupStatsWithDate[]>([]);
      const [totalDeposits, setTotalTotalDeposits] = React.useState<any[]>([]);
      const [graphData, setGraphData] = React.useState<any[]>([]);
      const [lineNames, setLineNames] = React.useState<Map<string, boolean>>(groupVisibility);
      const [yearOverYearGraphData, setYearOverYearGraphData] = React.useState<any[]>([]);
      const [yearOverYearLineNames, setYearOverYearLineNames] =
        React.useState<Map<string, boolean>>(yearOverYearGroupVisibility);

      useEffect(() => {
        const groupStatsUrl = `${apiUrl}/api/admin/groups/stats?startDate=${start.toISOString().slice(0, 10)}&endDate=${end.toISOString().slice(0, 10)}`;
        fetch(groupStatsUrl, fetchOptions)
          .then((response) => {
            if (response) {
              return response.text();
            }
            throw new Error("No response");
          })
          .then((data) => {
            const groupStats: GroupStatsWithDate[] = Convert.toGetStatsForGroupResponse(data).groupStats;
            setGroupstats(groupStats);
          });
        fetch(`${apiUrl}/api/admin/total-deposits-with-date`, fetchOptions)
          .then((response) => {
            if (response) {
              return response.text();
            }
            throw new Error("No response");
          })
          .then((data) => {
            const depositStats: TotalDepositsWithDate[] = Convert.toGetTotalDepositsWithDates(data).totalDeposits;
            var d = depositStats.map((deposit) => {
              var date = new Date(deposit.date.getFullYear(), deposit.date.getMonth(), 1);
              return {
                date: `${monthNames[date.getMonth()]} '${date.getFullYear().toString().substr(-2)}`,
                "Total Deposit": deposit.deposit,
              };
            });
            setTotalTotalDeposits(d);
          });
      }, [start, end]);

      useEffect(() => {
        let data: any[] = [];
        let yearOverYearData: any[] = [];
        let lineNamesMap = new Map<string, boolean>();
        let yearOverYearLineNamesMap = new Map<string, boolean>();
        let statsByDate: Map<string, any> = new Map();
        let yearOverYearStatsByDate: Map<string, any> = new Map();
        groupstats.forEach((groupStat) => {
          const date = new Date(groupStat.date.getFullYear(), groupStat.date.getMonth(), 1);
          const dateStr = `${monthNames[date.getMonth()]} '${date.getFullYear().toString().substr(-2)}`;
          const yearOverYearDateStr = `${monthNames[date.getMonth()]}`;
          const lineName = groupIdMap.get(groupStat.groupId)?.name || "Unknown";
          const yearOverYearLineName = `${groupIdMap.get(groupStat.groupId)?.name || "Unknown"} - ${date.getFullYear()}`;
          lineNamesMap.set(lineName, lineNamesMap.get(lineName) || groupVisibility.get(lineName) || false);
          yearOverYearLineNamesMap.set(
            yearOverYearLineName,
            yearOverYearGroupVisibility.get(yearOverYearLineName) || false,
          );
          if (!statsByDate.has(dateStr)) {
            statsByDate.set(dateStr, {});
          }
          if (!yearOverYearStatsByDate.has(yearOverYearDateStr)) {
            yearOverYearStatsByDate.set(yearOverYearDateStr, {});
          }
          const stats = statsByDate.get(dateStr);
          stats[lineName] = groupStat[metric.value];
          const yearOverYearStats = yearOverYearStatsByDate.get(yearOverYearDateStr);
          yearOverYearStats[yearOverYearLineName] = groupStat[metric.value];
        });
        statsByDate.forEach((value, key) => {
          data.push({ date: key, ...value });
        });
        yearOverYearStatsByDate.forEach((value, key) => {
          yearOverYearData.push({ date: key, ...value });
        });
        setGraphData(data);
        setYearOverYearGraphData(yearOverYearData);
        setLineNames(lineNamesMap);
        setYearOverYearLineNames(yearOverYearLineNamesMap);
      }, [groupstats, metric]);

      const handleGroupLegendClick = React.useCallback(
        (data: Payload, index: number) => {
          const groupName = data.dataKey;
          if (typeof groupName === "string") {
            const newGroupNames = new Map(lineNames);
            newGroupNames.set(groupName, !lineNames.get(groupName));
            setLineNames(newGroupNames);
            groupVisibilityRef.current = newGroupNames;
          }
        },
        [lineNames],
      );

      const handleYearOverYearGroupLegendClick = React.useCallback(
        (data: Payload, index: number) => {
          const groupName = data.dataKey;
          if (typeof groupName === "string") {
            const newGroupNames = new Map(yearOverYearLineNames);
            newGroupNames.set(groupName, !yearOverYearLineNames.get(groupName));
            setYearOverYearLineNames(newGroupNames);
            yearOverYearGroupVisibilityRef.current = newGroupNames;
          }
        },
        [yearOverYearLineNames],
      );

      return (
        <>
          <MetricLineChart
            type={"Group"}
            data={graphData}
            selectedMetric={metric}
            lineNames={lineNames}
            handleLegendClick={handleGroupLegendClick}
          />
          <MetricLineChart
            type={"Total"}
            data={totalDeposits}
            selectedMetric={{ label: "Deposits", value: "deposit", formatter: overViewIncomeFormatter }}
            lineNames={new Map([["Total Deposit", false]])}
            handleLegendClick={() => {}}
          />
          <div className={"mt-5"}>
            <MetricLineChart
              type={"Group - Year Over Year"}
              data={yearOverYearGraphData}
              selectedMetric={metric}
              lineNames={yearOverYearLineNames}
              handleLegendClick={handleYearOverYearGroupLegendClick}
            />
          </div>
        </>
      );
    },
    arePropsEqual,
  );

  const compareMonthIndex = useCallback((a: string, b: string) => {
    const monthA = monthNames.indexOf(a.split(" ")[0]);
    const monthB = monthNames.indexOf(b.split(" ")[0]);
    return monthA - monthB;
  }, []);

  const ApartmentTrendGraph: React.FC = () => {
    const [data, setData] = React.useState<any[]>([]);
    const [dataYearOverYear, setDataYearOverYear] = React.useState<any[]>([]);
    const [payoutData, setPayoutData] = React.useState<any[]>([]);
    const [depositData, setDepositData] = React.useState<any[]>([]);
    const [payoutDataYearOverYear, setPayoutDataYearOverYear] = React.useState<any[]>([]);
    const [names, setNames] = React.useState<Map<string, boolean>>(new Map());
    const [namesYearOverYear, setNamesYearOverYear] = React.useState<Map<string, boolean>>(new Map());
    const [apartments, setApartments] = React.useState<SelectOption[]>(selectedApartmentsRef.current);
    const [apartmentStats, setApartmentStats] = React.useState<ApartmentStatsWithDate[]>([]);
    const [payoutStats, setPayoutStats] = React.useState<PayoutStatsWithDate[]>([]);

    useEffect(() => {
      let apartmentIds = apartments?.map((option) => option.value).join(",");
      if (!apartmentIds) {
        return;
      }
      const statsFetchUrl = `${apiUrl}/api/admin/apartments/stats-with-date?apartments=${apartmentIds}&startDate=${startDate.toISOString().slice(0, 10)}&endDate=${endDate.toISOString().slice(0, 10)}`;
      fetch(statsFetchUrl, fetchOptions)
        .then((response) => {
          if (response) {
            return response.text();
          }
          throw new Error("No response");
        })
        .then((data) => {
          const apartmentStats: ApartmentStatsWithDate[] =
            Convert.toGetStatsForApartmentsWithDates(data).apartmentStats;
          setApartmentStats(apartmentStats);
        });
      const payoutsUrl = `${apiUrl}/api/admin/apartments/payouts-with-date?apartments=${apartmentIds}&startDate=${startDate.toISOString().slice(0, 10)}&endDate=${endDate.toISOString().slice(0, 10)}`;
      fetch(payoutsUrl, fetchOptions)
        .then((response) => {
          if (response) {
            return response.text();
          }
          throw new Error("No response");
        })
        .then((data) => {
          const payoutStats: PayoutStatsWithDate[] = Convert.toGetPayoutStatsWithDates(data).payoutStats;
          setPayoutStats(payoutStats);
        });
    }, [apartments]);

    useEffect(() => {
      let dataStats: any[] = [];
      let dataStatsYearOverYear: any[] = [];
      let payouts: any[] = [];
      let deposits: any[] = [];
      let payoutsYearOverYear: any[] = [];
      let lineNames = new Map<string, boolean>();
      let yearOverYearLineNamesMap = new Map<string, boolean>();
      let statsByDate: Map<string, any> = new Map();
      let yearOverYearStatsByDate: Map<string, any> = new Map();
      let depositStatsByDate: Map<string, any> = new Map();
      let payoutStatsByDate: Map<string, any> = new Map();
      let yearOverYearPayoutStatsByDate: Map<string, any> = new Map();
      apartmentStats.forEach((apartmentStat) => {
        const date = new Date(apartmentStat.date.getFullYear(), apartmentStat.date.getMonth(), 1);
        const dateStr = `${monthNames[date.getMonth()]} '${date.getFullYear().toString().substr(-2)}`;
        const yearOverYearDateStr = `${monthNames[date.getMonth()]}`;
        const apartmentName = apartmentIdMap.get(apartmentStat.apartmentId)?.name || "Unknown";
        const yearOverYearLineName = `${apartmentIdMap.get(apartmentStat.apartmentId)?.name || "Unknown"} - ${date.getFullYear()}`;
        lineNames.set(apartmentName, names.get(apartmentName) || false);
        yearOverYearLineNamesMap.set(yearOverYearLineName, namesYearOverYear.get(yearOverYearLineName) || false);
        if (!statsByDate.has(dateStr)) {
          statsByDate.set(dateStr, {});
        }
        if (!yearOverYearStatsByDate.has(yearOverYearDateStr)) {
          yearOverYearStatsByDate.set(yearOverYearDateStr, {});
        }
        const stats = statsByDate.get(dateStr);
        const yearOverYearStats = yearOverYearStatsByDate.get(yearOverYearDateStr);
        stats[apartmentName] = apartmentStat[selectedMetric.value];
        yearOverYearStats[yearOverYearLineName] = apartmentStat[selectedMetric.value];
      });

      payoutStats.forEach((payoutStat) => {
        const date = new Date(payoutStat.date.getFullYear(), payoutStat.date.getMonth(), 1);
        const dateStr = `${monthNames[date.getMonth()]} '${date.getFullYear().toString().substr(-2)}`;
        const yearOverYearDateStr = `${monthNames[date.getMonth()]}`;
        const apartmentName = apartmentIdMap.get(payoutStat.apartmentId)?.name || "Unknown";
        const yearOverYearLineName = `${apartmentIdMap.get(payoutStat.apartmentId)?.name || "Unknown"} - ${date.getFullYear()}`;
        lineNames.set(apartmentName, false);
        if (!payoutStatsByDate.has(dateStr)) {
          payoutStatsByDate.set(dateStr, {});
        }
        if (!yearOverYearPayoutStatsByDate.has(yearOverYearDateStr)) {
          yearOverYearPayoutStatsByDate.set(yearOverYearDateStr, {});
        }
        if (!depositStatsByDate.has(dateStr)) {
          depositStatsByDate.set(dateStr, {});
        }
        const payoutStats = payoutStatsByDate.get(dateStr);
        const depositStats = depositStatsByDate.get(dateStr);
        const yearOverYearPayOutStats = yearOverYearPayoutStatsByDate.get(yearOverYearDateStr);
        payoutStats[apartmentName] = payoutStat[payoutMetric.value];
        depositStats[apartmentName] = payoutStat[depositMetric.value];
        yearOverYearPayOutStats[yearOverYearLineName] = payoutStat[payoutMetric.value];
      });
      statsByDate.forEach((value, key) => {
        dataStats.push({ date: key, ...value });
      });
      yearOverYearStatsByDate.forEach((value, key) => {
        dataStatsYearOverYear.push({ date: key, ...value });
      });
      payoutStatsByDate.forEach((value, key) => {
        payouts.push({ date: key, ...value });
      });
      yearOverYearPayoutStatsByDate.forEach((value, key) => {
        payoutsYearOverYear.push({ date: key, ...value });
      });
      depositStatsByDate.forEach((value, key) => {
        deposits.push({ date: key, ...value });
      });
      setData(dataStats);
      setDataYearOverYear(dataStatsYearOverYear.sort((a, b) => compareMonthIndex(a.date, b.date)));
      setPayoutData(payouts);
      setDepositData(deposits);
      setPayoutDataYearOverYear(payoutsYearOverYear.sort((a, b) => compareMonthIndex(a.date, b.date)));
      setNames(lineNames);
      setNamesYearOverYear(yearOverYearLineNamesMap);
    }, [apartmentStats, payoutStats]);

    const handleApartmentLegendClick = React.useCallback(
      (data: Payload, index: number) => {
        const apartmentName = data.dataKey;
        if (typeof apartmentName === "string") {
          const newApartmentNames = new Map(names);
          newApartmentNames.set(apartmentName, !names.get(apartmentName));
          setNames(newApartmentNames);
        }
      },
      [names],
    );

    const onApartmentSelectionChanged = (newValue: MultiValue<SelectOption>, actionMeta: ActionMeta<SelectOption>) => {
      setApartments(newValue as SelectOption[]);
      selectedApartmentsRef.current = newValue as SelectOption[];
    };

    return (
      <>
        <div
          className="d-flex flex-column me-2 align-items-end "
          style={{ overflow: "visible", height: "auto", width: "50%", marginLeft: "auto" }}
        >
          <label className="my-1 mr-2" htmlFor="states">
            Apartments
          </label>
          <Select
            options={apartmentOptions.sort((a, b) => a.label.localeCompare(b.label))}
            isMulti={true}
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            onChange={onApartmentSelectionChanged}
            value={apartments}
            styles={multiSelectDropdownStyle}
            className={"mb-4"}
          />
        </div>
        <div>
          <MetricLineChart
            type={"Apartment"}
            data={data}
            selectedMetric={selectedMetric}
            lineNames={names}
            handleLegendClick={handleApartmentLegendClick}
          />
        </div>
        <div className={"mt-3"}>
          <MetricLineChart
            type={"Apartment"}
            data={payoutData}
            selectedMetric={payoutMetric!}
            lineNames={names}
            handleLegendClick={handleApartmentLegendClick}
          />
        </div>
        <div className={"mt-3"}>
          <MetricLineChart
            type={"Apartment Cumulative"}
            data={depositData}
            selectedMetric={depositMetric!}
            lineNames={names}
            handleLegendClick={handleApartmentLegendClick}
          />
        </div>
        <div className={"mt-3"}>
          <MetricLineChart
            type={"Apartment Year Over Year"}
            data={dataYearOverYear}
            selectedMetric={selectedMetric}
            lineNames={namesYearOverYear}
            handleLegendClick={handleApartmentLegendClick}
          />
        </div>
        <div className={"mt-3"}>
          <MetricLineChart
            type={"Apartment Payouts Year Over Year"}
            data={payoutDataYearOverYear}
            selectedMetric={payoutMetric!}
            lineNames={namesYearOverYear}
            handleLegendClick={handleApartmentLegendClick}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div className="card border-0 shadow mb-4" style={{ overflow: "visible" }}>
        <div className="card-header" style={{ overflow: "visible", height: "auto" }}>
          <div
            className="row d-flex justify-content-end align-items-end"
            style={{ overflow: "visible", height: "auto" }}
          >
            <div className="col-md-6 d-flex justify-content-end" style={{ overflow: "visible", height: "auto" }}>
              <div className="d-flex flex-column me-2" style={{ overflow: "visible", height: "auto" }}>
                <SelectM
                  color={"secondary"}
                  onChange={onMetricChanged}
                  name={"Metric"}
                  value={selectedMetric.value}
                  options={metricOptions}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card-body d-flex flex-column" style={{ marginLeft: "10px" }}>
          <div className="d-flex flex-column" style={{ width: "100%" }}>
            <GroupsMetricLineChart
              start={startDate}
              end={endDate}
              metric={selectedMetric}
              groupVisibility={groupVisibilityRef.current}
              yearOverYearGroupVisibility={yearOverYearGroupVisibilityRef.current}
            />
          </div>
          <hr />

          <div className="d-flex flex-column" style={{ width: "100%" }}>
            <ApartmentTrendGraph />
          </div>
        </div>
      </div>
    </>
  );
};
