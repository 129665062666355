import React, {FC} from 'react';

export interface SelectOption {
    value: string;
    label: string;
}

/**
 * Interface for the props of the Select component.
 */
export interface SelectProps {
    /**
     * The color of the select element.
     */
    color: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'danger' | 'gray-700' | 'gray-100';

    /**
     * An array of options for the select element. Each option is an object with a `value` and a `text` property.
     */
    options: SelectOption[];

    /**
     * The name of the select element. This is used as the label for the select element and as the name attribute of the select element.
     */
    name: string;

    /**
     * The current selected value of the select element.
     */
    value: string

    /**
     * Whether the select element is disabled. This prop is optional.
     */
    disabled?: boolean;

    /**
     * The function that is called when the select element is changed
     */
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const Select: FC<SelectProps> = ({
                                     color,
                                     options,
                                     onChange,
                                     name,
                                     disabled,
                                     value
                                 }) => {
    return (
        <>
            <label htmlFor={name}>{name}</label>
            <select className={`btn btn-${color} form-select text-start mb-0 pe-5`} name={name}
                    disabled={disabled === true}
                    onChange={onChange} value={value}>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                ))}
            </select>
        </>
    );
};

export default Select;