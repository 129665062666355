import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Convert, User } from "../models/responses";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Grid from "../lib/organisms/Grid";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { CustomCellEditorProps } from "@ag-grid-community/react";
import { fetchOptions } from "../hooks/useAuth";
import { apiUrl } from "../layouts/userLayout";
import { useUserContext } from "../hooks/userUserContext";
import Chip from "../lib/atoms/chip/Chip";
import { multiSelectDropdownStyle } from "../utils/graphs";
import Select, { ActionMeta, MultiValue } from "react-select";
import { SelectOption } from "../lib/molecules/select";
import AddOrEditUserModal from "../lib/organisms/AddUserModal";
import { decodeErrorResponse } from "../utils/errors";
import { errorPopup, successPopup } from "../utils/alerts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faEdit, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { dateFormatter } from "../utils/formatters";

export const VisibleOwnerEditor = memo((props: CustomCellEditorProps) => {
  const { ownerOptions } = useUserContext().context;
  const [selectedOwners, setSelectedOwners] = useState(
    ownerOptions.filter((owner) => props.value.visibleOwners.includes(Number(owner.value))),
  );

  const onVisibleOwnersChanged = (newValue: MultiValue<SelectOption>, actionMeta: ActionMeta<SelectOption>) => {
    const newOwnerIDs = newValue.map((owner) => Number(owner.value));
    const updatedValue = {
      userId: props.value.userId,
      visibleOwners: newOwnerIDs,
    };
    props.onValueChange(updatedValue);
  };

  return (
    <div>
      <Select
        options={ownerOptions.sort((a, b) => a.label.localeCompare(b.label))}
        isMulti={true}
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
        onChange={(newValue: MultiValue<SelectOption>, actionMeta: ActionMeta<SelectOption>) => {
          onVisibleOwnersChanged(newValue, actionMeta);
          setSelectedOwners([...newValue]);
        }}
        value={selectedOwners}
        styles={multiSelectDropdownStyle}
        className={"mb-2"}
        // onMenuClose={() => props.api.stopEditing()}
      />
    </div>
  );
});

export const VisibleOwnersRenderer: React.FC<CustomCellRendererProps> = (params: CustomCellRendererProps) => {
  const { ownerOptions } = useUserContext().context;
  const [selectedOwners, setSelectedOwners] = useState<string[]>(
    ownerOptions
      .filter((owner) => params.value.visibleOwners.includes(Number(owner.value)))
      .map((owner) => owner.label),
  );

  useEffect(() => {
    setSelectedOwners(
      ownerOptions
        .filter((owner) => params.value.visibleOwners.includes(Number(owner.value)))
        .map((owner) => owner.label),
    );
  }, [ownerOptions, params.value.visibleOwners]);

  const handleDelete = (ownerLabel: string) => {
    if (params.setValue) {
      const removedOwnerId = ownerOptions.find((owner) => owner.label === ownerLabel)?.value;
      params.setValue({
        userId: params.value.userId,
        visibleOwners: params.value.visibleOwners.filter((ownerId: number) => ownerId !== Number(removedOwnerId)),
        isAdmin: params.value.isAdmin,
      });
    }
  };
  return (
    <div>
      {params.value.isAdmin === false ? (
        selectedOwners.map((ownerLabel, index) => (
          <Chip
            key={index}
            color={"primary"}
            text={ownerLabel}
            onDelete={() => handleDelete(ownerLabel)}
            extraClasses={"mb-2 btn-sm me-2 mt-2"}
          />
        ))
      ) : (
        <Chip color={"gray-500"} text={"All"} extraClasses={"mb-2 btn-sm me-2 mt-2"} />
      )}
    </div>
  );
};

interface ExtendedActionsRendererProps extends CustomCellRendererProps {
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
}

export const ActionsRenderer: React.FC<ExtendedActionsRendererProps> = (params: ExtendedActionsRendererProps) => {
  return (
    <div>
      <FontAwesomeIcon
        style={{ cursor: "pointer" }}
        className={"text-info-emphasis"}
        icon={faEdit}
        onClick={function () {
          console.log(params);
          return params.onEdit(params.data.id);
        }}
      />
      <FontAwesomeIcon
        style={{ cursor: "pointer" }}
        className={"text-info-emphasis ms-2"}
        icon={faTrashCan}
        onClick={function () {
          return params.onDelete(params.data.id);
        }}
      />
    </div>
  );
};

const UsersPage: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [userToEdit, setUserToEdit] = useState<User | undefined>(undefined);
  const gridRef = useRef<AgGridReact | any>();
  const [showAddUserModal, setShowAddOrEditUserModal] = useState(false);
  const gridName = "Users";

  const updateUserVisibleOwners = (newValue: { userId: number; visibleOwners: number[] }) => {
    const updatedVisibleOwnersForUser = users.map((user) => {
      if (user.id === newValue.userId) {
        return {
          ...user,
          visibleOwners: newValue.visibleOwners,
        };
      }
      return user;
    });
    fetch(`${apiUrl}/api/admin/users/${newValue.userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        visibleOwners: newValue.visibleOwners,
      }),
    }).then((response) => {
      if (!response.ok) throw new Error("Failed to update visible owners");
      gridRef.current.api.setGridOption("rowData", updatedVisibleOwnersForUser);
    });
  };

  const deleteUser = useCallback(
    (userId: number) => {
      const user: User = users.find((user) => user.id === userId)!;
      Swal.fire({
        title: `Are you sure you want to delete user ${user.givenName} ${user.lastName}?`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0f212c",
        cancelButtonColor: "#f0bc74",
        confirmButtonText: "Yes, delete!",
      }).then((result) => {
        if (result.isConfirmed) {
          fetch(`${apiUrl}/api/admin/users/${userId}`, {
            method: "DELETE",
            credentials: "include",
          })
            .then((response) => {
              if (!response.ok) return decodeErrorResponse(response);
              return response.text();
            })
            .then(() => {
              let updatedUsers = users.filter((user) => user.id !== userId);
              setUsers(updatedUsers);
              Swal.fire("Deleted!", "User has been deleted.", "success");
            })
            .catch((error) => {
              Swal.fire("Error!", `Failed to delete user: ${error.message}`, "error");
            });
        }
      });
    },
    [users],
  );
  const editUser = useCallback(
    (userId: number) => {
      const user: User = users.find((user) => user.id === userId)!;
      setUserToEdit(user);
      setShowAddOrEditUserModal(true);
    },
    [users],
  );

  const columnDefinitions: ColDef[] = [
    { field: "givenName", headerName: "First Name" },
    { field: "lastName", headerName: "Last Name" },
    { field: "email", headerName: "Email", width: 300 },
    { field: "isAdmin", headerName: "Admin" },
    { field: "lastVisited", headerName: "Last Visited", valueFormatter: dateFormatter },
    {
      field: "visibleOwners",
      headerName: "Visible Owners",
      cellDataType: "object",
      valueGetter: (params) => {
        return {
          visibleOwners: params.data.visibleOwners,
          userId: params.data.id,
          isAdmin: params.data.isAdmin,
        };
      },
      cellRenderer: VisibleOwnersRenderer,
      cellRendererParams: {
        setValue: updateUserVisibleOwners,
      },
      cellEditorSelector: (params) => {
        return {
          component: VisibleOwnerEditor,
          params: { onValueChange: updateUserVisibleOwners },
        };
      },
      cellEditorPopup: false,
      editable: (params) => !params.data.isAdmin,
      autoHeight: true,
      wrapText: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      cellRenderer: ActionsRenderer,
      cellRendererParams: {
        onEdit: (userId: number) => editUser(userId),
        onDelete: (userId: number) => deleteUser(userId),
      },
    },
  ];

  useEffect(() => {
    fetch(`${apiUrl}/api/admin/users`, fetchOptions)
      .then((response) => response.text())
      .then((data) => {
        let users = Convert.toGetAllUsersResponse(data).users;
        setUsers(users);
      });
  }, []);

  const addUserButton = { label: "Add", onClick: () => setShowAddOrEditUserModal(true), faIcon: faAdd };

  const handleModalSubmitted = useCallback(
    (
      firstname: string,
      lastname: string,
      email: string,
      isAdmin: boolean,
      visibleOwners: number[],
      existingUser: User | undefined,
    ) => {
      const url = existingUser ? `${apiUrl}/api/admin/users/${existingUser.id}` : `${apiUrl}/api/admin/users`;
      const method = existingUser ? "PUT" : "POST";
      fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          givenName: firstname,
          lastName: lastname,
          email: email,
          isAdmin: isAdmin,
          visibleOwners: visibleOwners,
        }),
      })
        .then((response) => {
          if (!response.ok) return decodeErrorResponse(response);
          return response.text();
        })
        .then((data) => {
          let updatedOrNewUser = Convert.toGetUserResponse(data).user;
          if (existingUser) {
            let updatedUsers = users.map((user) => (user.id === updatedOrNewUser.id ? updatedOrNewUser : user));
            setUsers(updatedUsers);
            successPopup("User updated successfully");
            return;
          } else {
            let updatedUsers = [...users, updatedOrNewUser];
            setUsers(updatedUsers);
            successPopup("User added successfully");
          }
          setShowAddOrEditUserModal(false);
        })
        .catch((error) => {
          errorPopup("Failed to add user: " + error.message);
        });
    },
    [users],
  );

  const handleModalClosed = useCallback(() => {
    setUserToEdit(undefined);
    setShowAddOrEditUserModal(false);
  }, []);

  return (
    <>
      <AddOrEditUserModal
        show={showAddUserModal}
        existingUser={userToEdit}
        handleClose={handleModalClosed}
        handleSubmitted={handleModalSubmitted}
      />
      <Grid<User>
        columnDefs={columnDefinitions}
        gridName={gridName}
        initialRowData={users}
        buttons={[addUserButton]}
        gridRef={gridRef}
        canExport={false}
        getRowId={(params) => params.data.id.toString()}
      />
    </>
  );
};
export default UsersPage;
