import React, { ComponentProps } from "react";
import clsx from "clsx";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type ButtonProps = ComponentProps<"button"> & {
  variant:
    | "primary"
    | "secondary"
    | "tertiary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "white"
    | "gray-900"
    | "gray-700"
    | "gray-100";
  outline?: boolean;
  faIcon?: IconDefinition;
};

const Button = ({ variant, outline = false, faIcon, children, className, ...props }: ButtonProps) => {
  const classes = clsx("btn", `btn-${outline ? "outline-" : ""}${variant}`, className);
  return (
    <button {...props} className={classes}>
      {faIcon && <FontAwesomeIcon className={"me-2"} icon={faIcon} />}
      {children}
    </button>
  );
};

export { Button };
