import Swal from "sweetalert2";

const popup = (isSuccess: boolean, message: string) => {
    // Replace each comma or colon with a line break
    const formattedMessage = message.replace(/[,\n]/g, '<br>');
    Swal.fire({
        title: `${isSuccess ? "Success" : "Error"}!`,
        html: `<span class="small-font">${formattedMessage}</span>`, // Wrap the message in a span with the 'small-font' class
        icon: isSuccess ? 'success' : 'error',
        confirmButtonText: 'OK',
        customClass: {
            confirmButton: 'btn btn-primary me-3',
            cancelButton: 'btn btn-gray',
        },
        buttonsStyling: false
    });
}
const successPopup = (message: string) => {
    popup(true, message);
}

const popupWithHtml = (isSuccess: boolean, message: string) => {
    Swal.fire({
        title: `${isSuccess ? "Success" : "Error"}!`,
        html: message,
        icon: isSuccess ? 'success' : 'error',
        confirmButtonText: 'OK',
        customClass: {
            confirmButton: 'btn btn-primary me-3',
            cancelButton: 'btn btn-gray',
        },
        buttonsStyling: false
    });
}

const successPopupWithHtml = (message: string) => {
    popupWithHtml(true, message);
}

const errorPopupWithHtml = (message: string) => {
    popupWithHtml(false, message);
}

const errorPopup = (message: string) => {
    popup(false, message);
}

export {errorPopup, successPopup, successPopupWithHtml, errorPopupWithHtml};



