import { Button, Form, Modal } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";
import { errorPopup } from "../../utils/alerts";
import Select from "../molecules/select";
import { dateToString, getQuarterStartDate } from "../../utils/periods";

enum QuarterOption {
  Current = "Current",
}

const quarterOptions = Object.keys(QuarterOption).map((key) => {
  return { label: `${key} Quarter`, value: key };
});

export interface EditApartmentRatioModalProps {
  show: boolean;
  handleClose: () => void;
  modalTitle: string;
  apartmentName: string;
  ratio: number;
  effectiveFrom: Date;
  handleSubmitted: (ratio: number, effectiveFrom: Date) => void;
}

const EditRatioModal: React.FC<EditApartmentRatioModalProps> = (props) => {
  const [ratio, setRatio] = useState<number>(props.ratio);
  const [isRatioValid, setIsRatioValid] = useState(true);
  const [lastUpdated, setLastUpdated] = useState<string>(props.effectiveFrom.toISOString().split("T")[0]);
  const [selectedQuarter, setSelectedQuarter] = useState<QuarterOption>(QuarterOption.Current);

  // const validateAndSetEmail = (email: string) => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   const isValid = emailRegex.test(email);
  //   setIsEmailValid(isValid);
  //   setEmail(email);
  // };
  useEffect(() => {
    setRatio(props.ratio);
    setLastUpdated(props.effectiveFrom.toISOString().split("T")[0]);
  }, [props.effectiveFrom, props.ratio]);

  const getDateFromQuarterSelection = useCallback((quarter: QuarterOption) => {
    let effectiveFrom: Date;
    switch (quarter) {
      // case QuarterOption.Previous:
      //   effectiveFrom = getPreviousQuarterStartDate(new Date());
      //   break;
      case QuarterOption.Current:
        effectiveFrom = getQuarterStartDate(new Date());
        break;
      // case QuarterOption.Next:
      //   effectiveFrom = getNextQuarterStartDate(new Date());
      //   break;
    }
    return effectiveFrom;
  }, []);

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    try {
      let effectiveFrom = getDateFromQuarterSelection(selectedQuarter);
      props.handleSubmitted(ratio, effectiveFrom);
      handleCloseModal();
    } catch (error) {
      errorPopup(`Failed to add user: ${error}`);
      return;
    }
  };

  const handleCloseModal = () => {
    setRatio(0);
    setLastUpdated(new Date().toISOString().split("T")[0]);
    setIsRatioValid(true);
    props.handleClose();
  };

  const validateAndSetRatio = useCallback((inputValue: string) => {
    const value = Number(inputValue) / 100;
    setRatio(value);
    console.log(value);
    setIsRatioValid(value >= 0 && value <= 1);
  }, []);

  return (
    <Modal show={props.show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{props.apartmentName}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          <h6 className={"text-center"}>{`Current ${props.modalTitle}`}</h6>
          <Form.Label>{`Value`}</Form.Label>
          <Form.Group style={{ display: "flex", alignItems: "center" }}>
            <Form.Control type="numeric" value={(props.ratio * 100).toFixed(0)} required disabled={true} />
            <span className={"input-group-text"} aria-disabled={true}>
              %
            </span>
          </Form.Group>
          <Form.Label className={"mt-4"}>{`Last Updated`}</Form.Label>
          <Form.Control type="numeric" value={dateToString(props.effectiveFrom)} required disabled={true} />

          <hr className={"mt-4 mb-4"}></hr>
          <h6 className={"text-center"}>{`New ${props.modalTitle}`}</h6>
          <Form.Label>{`Value`}</Form.Label>
          <Form.Group style={{ display: "flex", alignItems: "center" }}>
            <Form.Control
              type="number"
              value={(ratio * 100).toFixed(0)}
              isInvalid={!isRatioValid}
              onChange={(event) => validateAndSetRatio(event.target.value)}
              required
            />
            <span className={"input-group-text"}>%</span>
          </Form.Group>
          <div className={"mt-4"}>
            <Select
              color={"secondary"}
              options={quarterOptions}
              name={`Effective From`}
              value={selectedQuarter}
              onChange={(event) => setSelectedQuarter(event.target.value as QuarterOption)}
            ></Select>
          </div>
          <Form.Control
            className={"mt-4"}
            value={dateToString(getDateFromQuarterSelection(selectedQuarter))}
            required
            readOnly={true}
            disabled={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="secondary" type="submit">
            Update
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditRatioModal;
