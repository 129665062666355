import React, {useEffect, useState} from 'react';
import {Button, Form, InputGroup, Modal} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EventApi} from "@fullcalendar/core";
import Select from "../molecules/select";

interface CalendarEventModalProps {
    show: boolean;
    handleClose: () => void;
    handleDelete: () => void;
    handleUpdate: (id: string, apartmentId: number, start: string, end: string, notes: string) => void;
    handleAddNew: (apartmentId: number, start: string, end: string, notes: string) => void;
    dateStart: string;
    dateEnd: string;
    existingEvent: EventApi | null;
    apartmentOptions: { label: string, value: string }[];
}

const CalendarEventModal: React.FC<CalendarEventModalProps> = ({
                                                                   show,
                                                                   handleClose,
                                                                   handleDelete,
                                                                   handleUpdate,
                                                                   handleAddNew,
                                                                   dateStart,
                                                                   dateEnd,
                                                                   apartmentOptions,
                                                                   existingEvent
                                                               }) => {
    const [start, setStart] = useState(dateStart);
    const [end, setEnd] = useState(dateEnd);
    const [notes, setNotes] = useState("");
    const [apartmentId, setApartmentId] = useState("1");
    const [currentEvent, setCurrentEvent] = useState<EventApi | null>(existingEvent);

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (currentEvent) {
            handleUpdate(currentEvent.id, parseInt(apartmentId), start, end, notes);
            return;
        }
        handleAddNew(parseInt(apartmentId), start, end, notes);
    };

    useEffect(() => {
        setCurrentEvent(existingEvent);
    }, [existingEvent]);

    useEffect(() => {
        if (currentEvent) {
            setApartmentId(currentEvent.extendedProps.apartmentId);
            setNotes(currentEvent.extendedProps.notes);
            setStart(currentEvent.startStr);
            setEnd(currentEvent.endStr);
        } else {
            console.log(currentEvent)
            console.log(start)
            console.log(end)
            setApartmentId("0");
            setStart(start);
            setEnd(end);
            setNotes("")
        }
    }, [currentEvent]);


    useEffect(() => {
        console.log(currentEvent)
        setStart(dateStart);
        setEnd(dateEnd);
    }, [dateStart, dateEnd]);


    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Event Details</Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <Form.Group className="mb-4">
                            <Select color={"secondary"}
                                    options={apartmentOptions}
                                    name={"Apartment"}
                                    value={apartmentId}
                                    onChange={e => setApartmentId(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Label>Select start date</Form.Label>
                            <InputGroup>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={['fas', 'calendar']}/>
                                </InputGroup.Text>
                                <Form.Control type="date" required value={start}
                                              onChange={e => setStart(e.target.value)}/>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Label>Select end date</Form.Label>
                            <InputGroup>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={['fas', 'calendar']}/>
                                </InputGroup.Text>
                                <Form.Control type="date" required value={end}
                                              onChange={e => setEnd(e.target.value)}/>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control as="textarea" rows={4} value={notes}
                                          onChange={e => setNotes(e.target.value)}/> </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            currentEvent && (
                                <Button variant="danger" onClick={handleDelete}>
                                    Delete event
                                </Button>
                            )
                        }
                        <Button variant="secondary" onClick={() => {
                            setCurrentEvent(null);
                            handleClose();
                        }}
                        >
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            {existingEvent ? "Update event" : "Add event"}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};

export default CalendarEventModal;