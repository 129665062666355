import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";

const profileImageURL = "https://p7.hiclipart.com/preview/442/477/305/computer-icons-user-profile-avatar-profile.jpg";

type ProfileDropdownProps = {
    profileImageUrl?: string;
    userName: string;
}

const ProfileDropdown: React.FC<ProfileDropdownProps> = ({
                                                             profileImageUrl = profileImageURL,
                                                             userName
                                                         }) => {
    const [isOpen, setIsOpen] = useState(false);
    const node = useRef<HTMLDivElement>(null);

    const handleClickOutside = (e: MouseEvent) => {
        if (node.current?.contains(e.target as Node)) {
            // inside click
            return;
        }
        // outside click
        setIsOpen(false);
    };

    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClickOutside);
        // return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleOpen = () => setIsOpen(!isOpen);

    return (
        <div ref={node} className={`mb-4 nav-item dropdown ms-lg-3 ${isOpen ? 'show' : ''}`}>
            <a className={`nav-link dropdown-toggle pt-1 px-0 ${isOpen ? 'show' : ''}`} href="#" role="button"
               onClick={toggleOpen} aria-expanded={isOpen}>
                <div className="media d-flex align-items-center">
                    <img className="avatar rounded-circle" alt="" src={profileImageUrl}/>
                    <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                        <span className="mb-0 small fw-bold text-gray-900">{`${userName}`}</span>
                    </div>
                </div>
            </a>
            <div className={`dropdown-menu dashboard-dropdown dropdown-menu-end mt-2 py-1 ${isOpen ? 'show' : ''}`}
                 style={{right: '1px'}}>
                <Link to={"/logout"} className="dropdown-item d-flex align-items-center">
                    <svg className="dropdown-icon text-danger me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
                    Logout
                </Link>
            </div>
        </div>
    );
};

export default ProfileDropdown;