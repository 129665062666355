import React, { useEffect, useState } from "react";
import { Convert, Reservation } from "../../models/responses";
import { Apartment, Platform, YearAndPeriod } from "../../models/";
import { apiUrl } from "../../layouts/adminLayout";
import { fetchOptions } from "../../hooks/useAuth";
import { useUserContext } from "../../hooks/userUserContext";

interface ReportReservationProps {
  apartments: Apartment[];
  yearAndPeriod: YearAndPeriod;
  apartmentIdMap: Map<number, Apartment>;
}

const ReportReservations: React.FC<ReportReservationProps> = ({ apartments, yearAndPeriod, apartmentIdMap }) => {
  const { selectedOwner } = useUserContext().context;
  const [reservations, setReservations] = useState<Reservation[]>([]);

  useEffect(() => {
    fetch(
      `${apiUrl}/api/owners/${selectedOwner}/reservations?year=${yearAndPeriod.year}&period=${yearAndPeriod.period}&startingInPeriod=true`,
      fetchOptions,
    )
      .then((response) => {
        if (response) {
          return response.text();
        }
        throw new Error("Unauthorized");
      })
      .then((data) => {
        console.log(data);
        const reservations = Convert.toGetReservationsResponse(data).reservations;
        const sortedReservations = reservations.sort((a, b) => {
          if (a.apartmentId !== b.apartmentId) {
            return a.apartmentId - b.apartmentId;
          }
          return a.startDate.getTime() - b.startDate.getTime();
        });
        setReservations(sortedReservations);
      });
  }, [yearAndPeriod, selectedOwner]);

  return (
    <div className="table-sm font-extra-small report-section">
      <table className="mb-0 table-sm font-extra-small" style={{ width: "100%" }}>
        <thead className="bg-light border-top">
          <tr>
            <th scope="row" className="border-0 text-left">
              Platform
            </th>
            <th scope="row" className="border-0 text-left">
              Code
            </th>
            <th scope="row" className="border-0 text-left">
              Guest
            </th>
            <th scope="row" className="border-0 text-left">
              #Guests
            </th>
            <th scope="row" className="border-0 text-left">
              Start
            </th>
            <th scope="row" className="border-0 text-left">
              End
            </th>
            <th scope="row" className="border-0 text-left">
              #Days
            </th>
            <th scope="row" className="border-0 text-left">
              Apartment
            </th>
            <th scope="row" className="border-0 text-right" style={{ textAlign: "right" }}>
              Gross
              <br /> Income
            </th>
            <th scope="row" className="border-0 text-right" style={{ textAlign: "right" }}>
              Commission
            </th>
            <th scope="row" className="border-0 text-right" style={{ textAlign: "right" }}>
              Taxes
            </th>
            <th scope="row" className="border-0 text-right" style={{ textAlign: "right" }}>
              Cleaning
              <br /> Fee
            </th>
            <th scope="row" className="border-0 text-right bg-gray-300 fw-bolder" style={{ textAlign: "right" }}>
              Net
              <br />
              ncome
            </th>
          </tr>
        </thead>
        <tbody>
          {reservations.map((reservation) => (
            <tr key={reservation.id}>
              <td className="text-left">{Platform[reservation.platform]}</td>
              <td className="text-left">{reservation.reservationCode}</td>
              <td className="text-left te">{reservation.guestName}</td>
              <td className="text-left">{reservation.numberOfGuests}</td>
              <td className="text-left text-nowrap">{reservation.startDate.toISOString().substring(0, 10)}</td>
              <td className="text-left text-nowrap">{reservation.endDate.toISOString().substring(0, 10)}</td>
              <td className="text-left">{reservation.actualDaysOfStay}</td>
              <td className="text-left text-nowrap">
                {apartmentIdMap.get(reservation.apartmentId)?.shortName || "N/A"}
              </td>
              <td className="text-right" style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                {reservation.receivableIncome} €
              </td>
              <td className="text-right" style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                {reservation.commission.toFixed(2)} €
              </td>
              <td
                className="text-left"
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                }}
              >
                {(reservation.temporaryStayDuty + reservation.sustainabilityFee + reservation.vat).toFixed(2)} €
              </td>
              <td className="text-right" style={{ textAlign: "right" }}>
                {reservation.cleaningFee?.toFixed(2)} €
              </td>
              <td className="text-right bg-gray-300 fw-bold" style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                {(
                  reservation.receivableIncome -
                  reservation.commission -
                  (reservation.cleaningFee || 0) -
                  reservation.vat -
                  reservation.temporaryStayDuty -
                  reservation.sustainabilityFee
                ).toFixed(2)}{" "}
                €
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReportReservations;
