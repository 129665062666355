import React, { FC, useCallback, useEffect, useState } from "react";
import "./Sidebar.css";
import { Link, NavLink, useLocation } from "react-router-dom";
import kerosLogo from "../../staticfiles/KH logo-01.png";

interface SideBarItemProps {
  name: string;
  href: string;
  active: boolean;
  svgPathData: string[];
  onSideBarItemClicked: () => void;
}

const SideBarItem: FC<SideBarItemProps> = ({ name, href, active, svgPathData, onSideBarItemClicked }) => {
  const [isActive, setIsActive] = useState(active);
  let location = useLocation();

  const findActive = useCallback(() => {
    let path = location.pathname;
    if (href === "/" || href === "/admin/") {
      return path === href;
    }
    return path.includes(href);
  }, [href, location]);

  useEffect(() => {
    setIsActive(findActive());
  }, [findActive, isActive]);

  const handleMouseEnter = () => {
    console.log("handleMouseEnter");
    setIsActive(true);
  };

  const handleMouseLeave = () => {
    if (!active) {
      setIsActive(false);
    }
  };

  const handleClick = () => {
    onSideBarItemClicked();
    console.log("foo");
  };

  return (
    <li className={`nav-item ${isActive ? "active" : ""}`}>
      <NavLink
        to={href}
        className="nav-link d-flex align-items-center justify-content-between"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <span>
          <span className="sidebar-icon">
            <svg
              className="icon icon-xs me-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              {svgPathData.map((d, index) => (
                <path key={index} d={d} />
              ))}
            </svg>
          </span>
          <span className="sidebar-text">{name}</span>
        </span>
      </NavLink>
    </li>
  );
};

interface DropdownItemProps {
  name: string;
  contractedName: string;
  href: string;
}

interface SideBarDropdownItemProps {
  name: string;
  svgPathData: string[];
  isDropdownOpen: boolean;
  dropdownItems: DropdownItemProps[];
  onClicked: () => void;
}

const SideBarDropdownItem: FC<SideBarDropdownItemProps> = ({
  name,
  svgPathData,
  dropdownItems,
  onClicked,
  isDropdownOpen,
}) => {
  const [isOpen, setIsOpen] = useState(isDropdownOpen);
  const [active, setActive] = useState(-1);
  let location = useLocation();

  const findActive = () => {
    let path = location.pathname;
    let index = -1;
    dropdownItems.forEach((item, i) => {
      if (path.includes(item.href)) {
        index = i;
      }
    });
    return index;
  };

  useEffect(() => {
    setActive(findActive());
  }, [findActive, location]);

  const handleClicked = () => {
    setIsOpen(!isOpen);
    onClicked();
  };

  return (
    <li className={`nav-item`}>
      <span className="nav-link d-flex align-items-center justify-content-between text-white" onClick={handleClicked}>
        <span>
          <div className="d-flex align-items-center">
            <span className="sidebar-icon">
              <svg
                className="icon icon-xs me-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                {svgPathData.map((d, index) => (
                  <path key={index} d={d} />
                ))}
              </svg>
            </span>
            <span className="sidebar-text">{name}</span>
          </div>
        </span>
        <span className="link-arrow">
          <svg
            className={`icon icon-sm ${isOpen ? "rotate-90" : ""}`}
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </span>
      </span>
      <div className={`dropdown-menu1 ${isOpen ? "open" : ""}`}>
        <ul className="flex-column nav">
          {dropdownItems.map((item, index) => (
            <li className={`nav-item  ${active === index ? "active" : ""}`} key={index}>
              <Link className="nav-link" to={item.href}>
                <span className="sidebar-text">{item.name}</span>
                <span className="sidebar-text-contracted">{item.contractedName}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
};

interface SideBarProps {
  sidebarItems: (SideBarItemProps | SideBarDropdownItemProps)[];
  contracted: boolean;
  handleToggle?: () => void;
  onSidebarExpanded?: () => void;
  logoLinkUrl?: string;
}

const Sidebar: FC<SideBarProps> = ({ sidebarItems, contracted, onSidebarExpanded, logoLinkUrl = "/" }) => {
  let [isContracted, setIsContracted] = useState(contracted);

  useEffect(() => {
    setIsContracted(contracted);
  }, [contracted]);

  const handleDropdownClicked = (index: number) => {
    if (isContracted) {
      setIsContracted(false);
      onSidebarExpanded?.();
    }
  };

  return (
    <nav
      id="sidebarMenu"
      className={`sidebar d-lg-block bg-gray-800 text-white collapse ${isContracted ? "contracted" : ""}`}
      data-simplebar
    >
      <div className={`sidebar-inner px-4 pt-3 ${isContracted ? "contracted" : ""}`}>
        <ul className="nav flex-column pt-3 pt-md-0">
          <li className="nav-item">
            <Link to={logoLinkUrl} className="d-flex align-items-center sidebar-text">
              <img src={kerosLogo} width="100%" height="auto" alt="Keros Hospitality Logo" />
            </Link>
          </li>
          {sidebarItems.map((item, index) =>
            "dropdownItems" in item ? (
              <SideBarDropdownItem
                key={index}
                {...item}
                onClicked={() => handleDropdownClicked(index)}
                isDropdownOpen={false}
              />
            ) : (
              <SideBarItem key={index} {...item} active={false} onSideBarItemClicked={() => {}} />
            ),
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
export { SideBarDropdownItem };
export type { SideBarProps, SideBarItemProps, SideBarDropdownItemProps };
