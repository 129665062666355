import {MultiSelectDropdownItemProps} from "../lib/molecules/multiSelectDropdown";
import {ColDef} from 'ag-grid-community';


const getColumnVisibilityOptions = (colDefs: ColDef[]): MultiSelectDropdownItemProps[] => {
    console.log("Get Column Visibility Options")
    return colDefs.map(colDef => {
        if (colDef.headerName && colDef.field) {
            return {
                label: colDef.headerName,
                input: colDef.field,
                isChecked: true
            }
        } else {
            return {
                label: 'Default Label',
                input: 'defaultInput',
                isChecked: true
            }
        }
    })
}

export {getColumnVisibilityOptions}