import {Button, Form, Modal} from "react-bootstrap";
import {useEffect, useState} from "react";

interface SaveViewModalProps {
    show: boolean;
    viewName: string;
    handleClose: () => void;
    handleSaveView: (viewName: string) => void;
}

const SaveViewModal: React.FC<SaveViewModalProps> = ({show, viewName, handleClose, handleSaveView}) => {
    const [name, setName] = useState(viewName);
    const [errorMessage, setErrorMessage] = useState('');

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const disallowedValues = ["Default"];
        if (disallowedValues.includes(name)) {
            setErrorMessage("This view name is not allowed.");
            return;
        }
        handleSaveView(name);
        handleCloseModal();
    };

    useEffect(() => {
        setName(viewName);
    }, [viewName]);

    const handleCloseModal = () => {
        setErrorMessage('');
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Save View</Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit}>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>View Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            isInvalid={!!errorMessage}
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            {errorMessage}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button variant="secondary" type="submit">
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default SaveViewModal;