import {FC} from "react";
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, IconDefinition} from "@fortawesome/free-solid-svg-icons";

interface DropdownButtonProps {
    dropDownLabel: string;
    size?: 'sm' | 'lg';
    color?: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'danger' | 'gray-700' | 'gray-100';
    actions: { label: string, onClick: () => void, faIcon?: IconDefinition }[];
    extraClasses?: string;
    disabled?: boolean;
}

const DropdownButtons: FC<DropdownButtonProps> = ({
                                                      dropDownLabel,
                                                      actions,
                                                      color = "secondary",
                                                      extraClasses = "",
                                                      size = "md",
                                                      disabled = true
                                                  }) => {
    return (
        <Dropdown className={`btn-group me-2 ${extraClasses}`}>
            <Dropdown.Toggle variant={color} id="multiSelectDropdown" disabled={disabled}>
                {dropDownLabel} <FontAwesomeIcon className="ms-2" icon={faCaretDown}/>
            </Dropdown.Toggle>

            <Dropdown.Menu style={{minWidth: 110}}>
                {actions.map((action, index) => (
                    <Dropdown.Item key={index} onClick={action.onClick}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                            <div style={{width: '15px'}}>
                                {action.faIcon &&
                                    <FontAwesomeIcon className={"text-info-emphasis"}
                                                     icon={action.faIcon}/>}
                            </div>
                            <div>
                                {action.label}
                            </div>
                        </div>
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default DropdownButtons;