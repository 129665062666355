import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router-dom";
import ErrorPage from "./error-page";
import AdminLayout from "./layouts/adminLayout";
import AdminTrendsPage from "./pages/dashboards/adminTrends";
import PayoutsPage from "./pages/dashboards/payouts";
import ExpensesPage from "./pages/database/apartmentExpenses";
import BlockedCalendarPage from "./pages/database/blocked";
import OverviewPage from "./pages/overview";
import LoginPage from "./pages/login";
import GroupedExpenses from "./pages/dashboards/groupedExpenses";
import { GraphsPage } from "./pages/graphs/graphs";
import { AuthLayout } from "./pages/authLayout";
import ReportsPage from "./pages/reports";
import { useAuth } from "./hooks/useAuth";
import UserLayout from "./layouts/userLayout";
import { LoggedInUserLayout } from "./pages/loggedInUserLayout";
import { AdminReservationsPage } from "./pages/database/adminReservationsPage";
import { UserReservationsPage } from "./pages/userReservationsPage";
import AdminGroupedExpensesPage from "./pages/dashboards/adminGroupedExpenses";
import { User } from "./models/responses";
import UsersPage from "./pages/usersPage";
import UserTrends from "./pages/userTrends";
import PayoutRatiosPage from "./pages/database/payoutRatiosPage";
import ManagementFeeRatiosPage from "./pages/database/managementFeeRatiosPage";
import { PrivacyPolicy } from "./pages/privacyPolicy";
import { OauthHomePage } from "./pages/oauthHomePage";
import BookingPacePage from "./pages/dashboards/bookingPacePage";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { OccupanciesPage } from "./pages/graphs/occupanciesPage";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

/** A higher-order component with conditional routing logic */
export const withCondition: (Component: React.FC, condition: boolean, redirectTo: string) => React.FC = (
  Component: React.FC,
  condition: boolean,
  redirectTo: string,
) => {
  return function InnerComponent(props: any) {
    return condition ? <Component {...props} /> : <Navigate to={redirectTo} replace />;
  };
};

export const redirectWithCondition: (
  predicate: (user: User) => boolean,
  redirectIfTrue: string,
  redirectIfFalse: string,
) => React.FC<User> = (predicate, redirectIfTrue, redirectIfFalse) => {
  return function InnerComponent(user: User) {
    return predicate(user) ? <Navigate to={redirectIfTrue} replace /> : <Navigate to={redirectIfFalse} replace />;
  };
};

interface RouteOrRedirectProps {
  component: React.FC;
  condition: (user: User) => boolean;
  redirectTo?: string;
}

interface UserRedirectProps {
  predicate: (user: User) => boolean;
  redirectIfTrue: string;
  redirectIfFalse: string;
}

export const ConditionallyRendered: React.FC<RouteOrRedirectProps> = ({
  component,
  condition,
  redirectTo = "/login",
}) => {
  const auth = useAuth();
  return auth.user ? withCondition(component, condition(auth.user), redirectTo)({}) : <Navigate to="/login" replace />;
};

export const UserRedirect: React.FC<UserRedirectProps> = ({
  predicate,
  redirectIfTrue = "/admin",
  redirectIfFalse = "/overview",
}) => {
  const auth = useAuth();
  return auth.user ? (
    redirectWithCondition(predicate, redirectIfTrue, redirectIfFalse)(auth.user)
  ) : (
    <Navigate to="/login" replace />
  );
};

function adminRoutes() {
  return (
    <Route
      path="/admin"
      element={<ConditionallyRendered component={AdminLayout} condition={(user) => user.isAdmin} />}
      errorElement={<ErrorPage />}
    >
      <Route index element={<OverviewPage />} />
      <Route path="reservations" element={<AdminReservationsPage />} />
      <Route path="payout-ratios" element={<PayoutRatiosPage />} />
      <Route path="management-fee-ratios" element={<ManagementFeeRatiosPage />} />
      <Route path="trends" element={<AdminTrendsPage />} />
      <Route path="payouts" element={<PayoutsPage />} />
      <Route path="expenses-by-category" element={<AdminGroupedExpensesPage />} />
      <Route path="expenses" element={<ExpensesPage />} />
      <Route path="graphs" element={<GraphsPage />} />
      <Route path="occupancies" element={<OccupanciesPage />} />
      <Route path="blocked" element={<BlockedCalendarPage />} />
      <Route path="reports" element={<ReportsPage />} />
      <Route path="users" element={<UsersPage />} />
      <Route path="pace" element={<BookingPacePage />} />
    </Route>
  );
}

function redirectSlashRoute() {
  return (
    <Route
      path="/"
      element={<UserRedirect predicate={(user) => user.isAdmin} redirectIfTrue="/admin" redirectIfFalse="/reports" />}
    />
  );
}

function loggedInUserRoutes() {
  return (
    <Route element={<ConditionallyRendered component={UserLayout} condition={(uuser) => true} />}>
      <Route path="reports" element={<ReportsPage />} />
      <Route path="reservations" element={<UserReservationsPage />} />
      <Route path="expenses" element={<ExpensesPage />} />
      <Route path="trends" element={<UserTrends />} />
      <Route path="aggregate-expenses" element={<GroupedExpenses />} />
    </Route>
  );
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AuthLayout />}>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/logout" element={<LoginPage logoutAndClearSessions={true} />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/oauth-home" element={<OauthHomePage />} />
      <Route element={<ConditionallyRendered component={LoggedInUserLayout} condition={(user) => true} />}>
        {adminRoutes()}
        {loggedInUserRoutes()}
        {redirectSlashRoute()}
      </Route>
    </Route>,
  ),
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
