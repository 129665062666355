import { useCallback, useEffect, useRef, useState } from "react";
import { Convert, PayoutRatio } from "../../models/responses";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Grid from "../../lib/organisms/Grid";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { fetchOptions } from "../../hooks/useAuth";
import { apiUrl } from "../../layouts/userLayout";
import { decodeErrorResponse } from "../../utils/errors";
import { errorPopup, successPopup } from "../../utils/alerts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import EditApartmentRatioModal from "../../lib/organisms/EditApartmentRatioModal";
import { useUserContext } from "../../hooks/userUserContext";
import { dateToString } from "../../utils/periods";

interface ExtendedActionsRendererProps extends CustomCellRendererProps {
  onEdit: (id: number) => void;
}

export const ActionsRenderer: React.FC<ExtendedActionsRendererProps> = (params: ExtendedActionsRendererProps) => {
  return (
    <div>
      <FontAwesomeIcon
        style={{ cursor: "pointer" }}
        className={"text-info-emphasis"}
        icon={faEdit}
        onClick={function () {
          console.log(params);
          return params.onEdit(params.data.id);
        }}
      />
    </div>
  );
};

const PayoutRatiosPage: React.FC = () => {
  const { apartmentIdMap } = useUserContext().context;
  const [ratios, setRatios] = useState<PayoutRatio[]>([]);
  const [apartmentToEdit, setApartmentToEdit] = useState<PayoutRatio | undefined>(undefined);
  const gridRef = useRef<AgGridReact | any>();
  const [showEditRatiosModal, setShowEditRatiosModal] = useState(false);
  const gridName = "Latest Payout Ratios";

  const editPayoutRatio = useCallback(
    (id: number) => {
      const apartmentRatio: PayoutRatio = ratios.find((ratio) => ratio.id === id)!;
      setApartmentToEdit(apartmentRatio);
      setShowEditRatiosModal(true);
    },
    [ratios],
  );

  const columnDefinitions: ColDef[] = [
    {
      field: "apartmentId",
      headerName: "Apartment",
      valueGetter: (params) => apartmentIdMap.get(params.data.apartmentId)?.name,
      flex: 1,
    },
    {
      field: "payoutRatio",
      headerName: "Payout Ratio",
      valueFormatter: (params) => `${(params.value * 100).toFixed(0)}%`,
    },
    { field: "effectiveFrom", headerName: "Effective From" },
    {
      field: "actions",
      headerName: "Actions",
      cellRenderer: ActionsRenderer,
      cellRendererParams: {
        onEdit: (apartmentId: number) => editPayoutRatio(apartmentId),
      },
    },
  ];

  useEffect(() => {
    fetch(`${apiUrl}/api/admin/apartments/payout-ratios`, fetchOptions)
      .then((response) => response.text())
      .then((data) => {
        let ratios = Convert.toGetPayoutRatiosResponse(data).payoutRatios;
        setRatios(ratios);
      });
  }, []);

  const handleModalSubmitted = useCallback(
    (apartmentToEdit: PayoutRatio, payoutRatio: number, effectiveFrom: Date) => {
      const apartmentId = apartmentToEdit?.apartmentId;
      const updatesExisting = dateToString(apartmentToEdit?.effectiveFrom) === dateToString(effectiveFrom);
      console.log(apartmentToEdit);
      console.log(apartmentToEdit?.effectiveFrom);
      console.log(effectiveFrom);
      const url = updatesExisting
        ? `${apiUrl}/api/admin/apartments/${apartmentId}/payout-ratios/${apartmentToEdit?.id}`
        : `${apiUrl}/api/admin/apartments/${apartmentId}/payout-ratios`;
      const method = updatesExisting ? "PUT" : "POST";
      fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          apartmentId: apartmentId,
          payoutRatio: payoutRatio,
          fromDate: dateToString(effectiveFrom),
        }),
      })
        .then((response) => {
          if (!response.ok) return decodeErrorResponse(response);
          return response.text();
        })
        .then((data) => {
          let updatedPayoutRatio: PayoutRatio = Convert.toGetPayoutRatioResponse(data).payoutRatio;
          let updatedRatios: PayoutRatio[] = ratios.map((ratio: PayoutRatio) =>
            ratio.apartmentId === updatedPayoutRatio.apartmentId ? updatedPayoutRatio : ratio,
          );
          setRatios(updatedRatios);
          successPopup("Payout ratio updated successfully");
          setShowEditRatiosModal(false);
        })
        .catch((error) => {
          errorPopup("Failed to update ratio:" + error.message);
        });
    },
    [ratios],
  );

  const handleModalClosed = useCallback(() => {
    setApartmentToEdit(undefined);
    setShowEditRatiosModal(false);
  }, []);

  return (
    <>
      <EditApartmentRatioModal
        show={showEditRatiosModal}
        handleClose={handleModalClosed}
        handleSubmitted={(payoutRatio, effectiveFrom) =>
          handleModalSubmitted(apartmentToEdit!, payoutRatio, effectiveFrom)
        }
        modalTitle={"Payout Ratio"}
        apartmentName={apartmentIdMap.get(apartmentToEdit?.apartmentId || 0)?.name || ""}
        ratio={apartmentToEdit?.payoutRatio || 0}
        effectiveFrom={apartmentToEdit?.effectiveFrom || new Date()}
      />
      <Grid<PayoutRatio>
        columnDefs={columnDefinitions}
        gridName={gridName}
        initialRowData={ratios}
        gridRef={gridRef}
        canExport={false}
        getRowId={(params) => params.data.apartmentId.toString()}
      />
    </>
  );
};
export default PayoutRatiosPage;
