import Sidebar from "../lib/organisms/Sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../scss/volt.scss";
import { Outlet, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Apartment, ApartmentOwner, ExpenseCategory } from "../models/responses";
import { SelectOption } from "../lib/molecules/select";
import ProfileDropdown from "../lib/molecules/profileDropdown";
import { useAuth } from "../hooks/useAuth";
import { userSidebarItems } from "../pages/userSidebar";

type ContextType = {
  selectedYear: number;
  setSelectedYear: (year: number) => void;
  selectedPeriod: string;
  setSelectedPeriod: (periodName: string) => void;
  selectedOwner: number;
  setSelectedOwner: (ownerId: number) => void;
  aggregateMethod: "sum" | "avg";
  setAggregateMethod: (method: "sum" | "avg") => void;
  periodOptions: SelectOption[];
  yearOptions: SelectOption[];
  ownerOptions: SelectOption[];
  apartmentOptions: SelectOption[];
  apartmentIdMap: Map<number, Apartment>;
  ownerIdMap: Map<number, ApartmentOwner>;
  expenseCategoriesMap: Map<number, ExpenseCategory>;
};

export const apiUrl = process.env.REACT_APP_GOREPORTING_API_URL;

export default function UserLayout() {
  const { user } = useAuth();
  const [isContracted, setIsContracted] = useState(false);
  const [sidebarItems, setSidebarItems] = useState(userSidebarItems);
  const fetchOptions: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || !user.visibleOwners) {
      navigate("/login");
    }
  }, [navigate, user]);

  const handleSidebarToggle = () => {
    setIsContracted(!isContracted);
  };

  return (
    <>
      <Sidebar logoLinkUrl={"/admin"} sidebarItems={sidebarItems} contracted={isContracted} />
      <main className="content">
        <nav className="navbar navbar-top navbar-expand navbar-dashboard navbar-dark ps-0 pe-2 pb-0">
          <div className="container-fluid px-0">
            <div className="d-flex justify-content-between w-100" id="navbarSupportedContent">
              <div className="d-flex align-items-center">
                <button
                  id="sidebar-toggle"
                  className="sidebar-toggle me-3 btn btn-icon-only d-none d-lg-inline-block align-items-center justify-content-center"
                  onClick={handleSidebarToggle}
                >
                  <svg
                    className="toggle-icon"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              <ProfileDropdown profileImageUrl={user?.profileImageUri} userName={user?.givenName || ""} />
            </div>
          </div>
        </nav>
        <Outlet />
      </main>
    </>
  );
}
