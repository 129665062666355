import kerosLogo from "../../src/staticfiles/KH logo-01.png";
import React from "react";

export const OauthHomePage = () => {
  return (
    <div className="container">
      <div className={"d-flex justify-content-center"}>
        <img src={kerosLogo} width="20%" height="auto" alt="Keros Hospitality Logo" />
      </div>
      <h1>Google OAuth2 HomePage</h1>
      <hr></hr>
      <h3>App Identity</h3>
      <p>
        Keros Hospitality Analytics is a web application that provides a dashboard for managing and viewing reservations
        and financial data for apartments. Apartment managers can input reservation data and view financial data and
        apartment owners can view financial data for their apartments.
      </p>
      <h3>What will this app do with user data</h3>
      <p>This app will store the following user data:</p>
      <ul>
        <li>Email Address: Used for authenticating the user and sending application notifications.</li>
        <li>Name: Displayed in the top right corner of our application for a personalized user experience.</li>
        <li>
          Google Profile Image: Displayed in the top right corner of our application for a personalized user experience.
        </li>
      </ul>
      <h3>Privacy Policy</h3>
      <p>
        The privacy policy for this application can be found at the following link:{" "}
        <a href="/privacy-policy">privacy policy</a>.
      </p>
      <h3>Context</h3>
      <p>
        This application's intention is to provide a dashboard for managing and viewing reservations and financial data.
      </p>

      <h3>Google OAuth2 Limited Use Disclosure</h3>
      <p>
        This application will only use the email address, name, and profile image provided by Google OAuth2 for
        authentication and personalization purposes. It does not request restricted scopes or access to any other user
        data. In any case, Keros Hospitality adheres to the Google OAuth2 Limited Use policy.
      </p>
      <h3>Copyright</h3>
      <p>
        In case there is a copyright complaint, please contact me and include the relevant page from Keros Hospitality
        that you believe infringes on your copyright. I will respond to your request.
      </p>
    </div>
  );
};
