import React, { FC, ReactNode } from "react";

export interface ChipProps {
  color: "primary" | "secondary" | "info" | "success" | "warning" | "danger" | "gray-500" | "gray-700" | "gray-100";
  text: ReactNode;
  extraClasses?: string;
  onDelete?: () => void;
}

const Chip: FC<ChipProps> = ({ color, onDelete, text, extraClasses = "" }) => {
  return (
    <div className={`btn btn-${color} ${extraClasses}`} style={{ cursor: "default" }}>
      <span className={"me-2 small"}>{text}</span>
      {onDelete && (
        <span onClick={onDelete} className={"small"} style={{ cursor: "pointer" }}>
          &times;
        </span>
      )}
    </div>
  );
};

export default Chip;
