import { SideBarDropdownItemProps, SideBarItemProps } from "../lib/organisms/Sidebar";

export const adminSidebarItems: (SideBarItemProps | SideBarDropdownItemProps)[] = [
  {
    name: "Overview",
    href: "/admin/",
    active: false,
    svgPathData: [
      "M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z",
      "M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z",
    ],
    onSideBarItemClicked: () => {},
  },
  {
    name: "Graphs",
    href: "/admin/graphs",
    active: false,
    svgPathData: [
      "M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z",
    ],
    onSideBarItemClicked: () => {},
  },
  // {
  //   name: "Occupancies",
  //   href: "/admin/occupancies",
  //   active: false,
  //   svgPathData: [
  //     "M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z",
  //   ],
  //   onSideBarItemClicked: () => {},
  // },
  {
    name: "Dashboards",
    svgPathData: ["M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z", "M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"],
    isDropdownOpen: false,
    onClicked: () => {},
    dropdownItems: [
      {
        name: "Booking Pace",
        contractedName: "A",
        href: "/admin/pace",
      },
      {
        name: "Trends",
        contractedName: "T",
        href: "/admin/trends",
      },
      {
        name: "Payouts",
        contractedName: "P",
        href: "/admin/payouts",
      },
      {
        name: "Expenses",
        contractedName: "E",
        href: "/admin/expenses-by-category",
      },
    ],
  },
  {
    name: "Database",
    svgPathData: [
      "M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z",
    ],
    isDropdownOpen: false,
    onClicked: () => {},
    dropdownItems: [
      {
        name: "Reservations",
        contractedName: "R",
        href: "/admin/reservations",
      },
      {
        name: "Expenses",
        contractedName: "e",
        href: "/admin/expenses",
      },
      {
        name: "Payout Ratios",
        contractedName: "P",
        href: "/admin/payout-ratios",
      },
      {
        name: "Management Fees",
        contractedName: "M",
        href: "/admin/management-fee-ratios",
      },
      {
        name: "Blocked Dates",
        contractedName: "e",
        href: "/admin/blocked",
      },
    ],
  },
  {
    name: "Reports",
    href: "/admin/reports",
    active: false,
    svgPathData: [
      "M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zM9.75 17.25a.75.75 0 00-1.5 0V18a.75.75 0 001.5 0v-.75zm2.25-3a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0v-3a.75.75 0 01.75-.75zm3.75-1.5a.75.75 0 00-1.5 0V18a.75.75 0 001.5 0v-5.25z",
      "M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z",
    ],
    onSideBarItemClicked: () => {},
  },
  {
    name: "Users",
    href: "/admin/users",
    active: false,
    svgPathData: [
      "M7 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM14.5 9a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM1.615 16.428a1.224 1.224 0 0 1-.569-1.175 6.002 6.002 0 0 1 11.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 0 1 7 18a9.953 9.953 0 0 1-5.385-1.572ZM14.5 16h-.106c.07-.297.088-.611.048-.933a7.47 7.47 0 0 0-1.588-3.755 4.502 4.502 0 0 1 5.874 2.636.818.818 0 0 1-.36.98A7.465 7.465 0 0 1 14.5 16Z",
    ],
    onSideBarItemClicked: () => {},
  },
];
