import React, { ComponentProps } from "react";
import PercentageChangeText from "../molecules/percentageChangeText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIcon, FaIconName } from "../../utils/faIcons";

export type StatsCardProps = ComponentProps<"div"> & {
  title: string;
  value: number;
  lasPeriodValue: number;
  lastYearValue: number;
  periodDescription: string;
  valueComment?: string;
  valueFormatter?: (value: number) => string;
  faIconName?: FaIconName;
  faIconVariant?: "primary" | "secondary" | "info" | "success" | "warning" | "danger";
};

const StatsCard: React.FC<StatsCardProps> = ({
  title,
  value,
  lasPeriodValue,
  lastYearValue,
  periodDescription,
  valueFormatter = (value) => value.toFixed(0),
  faIconName = FaIconName.ChartLine,
  faIconVariant = "primary",
  valueComment,
  className,
}) => {
  const changeVsLastPeriod = ((value - lasPeriodValue) / lasPeriodValue) * 100;
  const changeVsLastYear = ((value - lastYearValue) / lastYearValue) * 100;

  return (
    <div className={`card border-0 shadow ${className}`}>
      <div className="card-body">
        <div className="row d-block d-xxl-flex align-items-center">
          <div className="col-12 col-xl-4 text-xl-center mb-xl-0 d-flex align-items-center justify-content-xl-center">
            <div className={`icon icon-shape icon-md icon-shape-${faIconVariant} rounded me-sm-0 `}>
              <FontAwesomeIcon icon={faIcon(faIconName)} />
            </div>
          </div>
          <div className="col-12 col-xxl-8 pe-xxl-0">
            <h2 className="fs-5 fw-normal mb-1">{title}</h2>
            <div className={"d-flex"}>
              <h3 className="fw-extrabold mb-1">{valueFormatter(value)}</h3>
              {valueComment && <span className="small mt-2 ms-2">({valueComment})</span>}
            </div>
            <small className="d-flex align-items-center">
              <svg
                className="icon icon-xxs text-gray-400 me-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clipRule="evenodd"
                ></path>
              </svg>
              {periodDescription}
            </small>
            <div className="small d-flex mt-1">
              <div>
                <div className="d-flex align-items-center text-nowrap">
                  <PercentageChangeText value={changeVsLastPeriod} valueFormatter={(v) => v.toFixed(0)} />
                  <span className={"ms-2 text-action"}>vs previous period's {valueFormatter(lasPeriodValue)}</span>
                </div>
                <div className="d-flex align-items-center text-nowrap">
                  <PercentageChangeText value={changeVsLastYear} valueFormatter={(v) => v.toFixed(0)} />
                  <span className={"ms-2 text-info-emphasis"}>vs previous year's {valueFormatter(lastYearValue)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsCard;
