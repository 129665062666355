import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { AgGridReact } from "ag-grid-react";
import ExcelJS from "exceljs";
import {
  ColDef,
  ColumnState,
  ColumnVisibleEvent,
  GetRowIdParams,
  GridApi,
  SelectionChangedEvent,
} from "ag-grid-community";
import DropdownButtons from "../molecules/dropdownButtons";
import { Button } from "../atoms/button/Button";
import Select, { SelectOption } from "../molecules/select";
import MultiSelectDropdown, { MultiSelectDropdownItemProps } from "../molecules/multiSelectDropdown";
import SaveViewModal from "./saveViewModal";
import { Convert, GridViewObject } from "../../models/responses";
import { getColumnVisibilityOptions } from "../../utils/grid";
import { apiUrl } from "../../layouts/adminLayout";
import { errorPopup, successPopup } from "../../utils/alerts";
import { useUserContext } from "../../hooks/userUserContext";
import { fetchOptions } from "../../hooks/useAuth";
import { decodeErrorResponse } from "../../utils/errors";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";

export type AggregateFunction = "sum" | "avg";

export interface GridDropdownFilter {
  label: string;
  options: SelectOption[];
  selectedOption: string;
  onSelectionChanged: (selection: string) => void;
}

export interface GridButton {
  label: string;
  onClick: () => void;
  faIcon: IconDefinition;
}

export interface SelectedRowsActions {
  label: string;
  onClick: (rows: any[]) => void;
  faIcon: IconDefinition;
}

export interface GridProps<T> {
  columnFilterText?: string;
  initialRowData: T[];
  columnDefs: ColDef[];
  gridRef: AgGridReact | any;
  gridName: string;
  canExport?: boolean;
  buttonColors?: "primary" | "secondary" | "info" | "success" | "warning" | "danger" | "gray-700" | "gray-100";
  dropdownFilters?: GridDropdownFilter[];
  showAggregateFilter?: boolean;
  hideSavedViews?: boolean;
  buttons?: GridButton[];
  actions?: SelectedRowsActions[];
  getRowId?: (params: GetRowIdParams<T>) => string;
  columnsToAggregate?: {
    colDef: ColDef<T>;
    formatter: (x: string) => string;
    aggregateFunction?: AggregateFunction;
  }[];
}

const Grid = <T extends unknown>(props: GridProps<T>) => {
  const { selectedPeriod, selectedYear } = useUserContext().context;

  const [quickFilterText, setQuickFilterText] = useState("");
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [selectViewOptions, setSelectViewOptions] = useState<{ label: string; value: string }[]>([]);
  const [selectedView, setSelectedView] = useState<GridViewObject>();
  const [savedViews, setSavedViews] = useState<GridViewObject[]>([]);
  const [showSaveViewModal, setShowSaveViewModal] = useState(false);
  const [isViewModified, setIsViewModified] = useState(false);
  const [columnVisibilityOptions, setColumnVisibilityOptions] = useState<MultiSelectDropdownItemProps[]>(
    getColumnVisibilityOptions(props.columnDefs),
  );
  const columnVisibilityOptionsRef = useRef(columnVisibilityOptions);
  const exportedExcelName = `${props.gridName}-${selectedPeriod.toLowerCase()}-${selectedYear}`;
  const canExport = props.canExport === undefined ? true : props.canExport;
  const gridName = props.gridName.toLowerCase().replace(" ", "_");

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      filter: true,
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      gridApi.setGridOption("rowData", props.initialRowData);
    }
  }, [gridApi, props.initialRowData]);

  const applyColumnState = useCallback((view: GridViewObject) => {
    console.log("Applying column state: ", view);
    props.gridRef.current.api.applyColumnState({
      state: view.columnStates,
      applyOrder: true,
    });
    const newColumnVisibilityOptions = columnVisibilityOptionsRef.current.map((item) => {
      const colState = view.columnStates.find((colState) => colState.colId === item.input);
      if (colState) {
        return { ...item, isChecked: !colState.hide };
      } else {
        return item;
      }
    });
    setColumnVisibilityOptions(newColumnVisibilityOptions);
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/api/grid/views?gridName=${gridName}`, fetchOptions)
      .then((response) => {
        if (!response.ok) {
          return decodeErrorResponse(response);
        }
        return response.text();
      })
      .then((response) => {
        console.log("Batman");
        props.gridRef.current.api.resetColumnState();
        const defaultColumnState = props.gridRef.current.api.getColumnState();
        const defaultView = {
          id: 0,
          columnStates: defaultColumnState,
          viewName: "Default",
          gridName: gridName.toLowerCase(),
        };
        let savedViews = [defaultView, ...Convert.toGetSavedGridViewsResponse(response).views];
        setSavedViews(savedViews);
        let viewOptions: SelectOption[] = [];
        savedViews.forEach((view) => {
          viewOptions.push({ label: view.viewName, value: view.id.toString() });
        });
        setSelectViewOptions(viewOptions);

        const savedViewId = localStorage.getItem(`${gridName}-selectedViewId`);
        const selectedView = savedViews.find((view) => view.id === Number(savedViewId));
        console.log(selectedView);
        setSelectedView(selectedView || defaultView);
        if (selectedView) {
          applyColumnState(selectedView);
          setTimeout(() => {
            setIsViewModified(false);
          });
        }
      })
      .catch((error) => console.log(error));
  }, [applyColumnState, gridName, props.gridRef]);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const handleQuickFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuickFilterText(event.target.value);
  };

  const handleSelectionChanged = (event: SelectionChangedEvent) => {
    setSelectedRows(props.gridRef.current!.api.getSelectedRows());
  };

  function mapActions(
    actions: Array<{
      label: string;
      onClick: (rows: any[]) => void;
      faIcon: IconDefinition;
    }>,
  ): Array<{
    label: string;
    onClick: () => void;
    faIcon?: IconDefinition;
  }> {
    return actions.map((action) => {
      return {
        label: action.label,
        onClick: () => action.onClick(selectedRows),
        faIcon: action.faIcon,
      };
    });
  }

  const exportDataAsExcel = async () => {
    if (!gridApi) return;
    var rowData: any[] = [];
    gridApi.forEachNode(function (node) {
      rowData.push(node.data);
    });

    var colHeaders: string[] = props.columnDefs.map(function (colDef) {
      return colDef.headerName || "";
    });

    const colHeaderToColumnMap = new Map<string, ColDef>();
    const columnFieldToColumnMap = new Map<string, ColDef>();
    props.columnDefs.forEach((colDef) => {
      if (colDef.field && colDef.headerName) {
        colHeaderToColumnMap.set(colDef.headerName, colDef);
        columnFieldToColumnMap.set(colDef.field, colDef);
      }
    });

    var workbook = new ExcelJS.Workbook();
    var worksheet = workbook.addWorksheet(
      exportedExcelName.split("-")[0].charAt(0).toUpperCase() + exportedExcelName.split("-")[0].slice(1),
    );
    let maxHeaderLengths = colHeaders.map((header) => header.length);

    worksheet.columns = colHeaders.map((header, i) => {
      return { header, key: header, width: Math.max(maxHeaderLengths[i] + 2, 15) };
    });

    let headerRow = worksheet.getRow(1);
    headerRow.height = 50;
    headerRow.eachCell((cell) => {
      cell.font = { bold: true, size: 14 };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    rowData.forEach((row, rowIndex) => {
      let rowArray = colHeaders.map((header) => {
        return row[colHeaderToColumnMap.get(header)?.field || ""];
      });
      if (rowArray.length === 0) return;
      worksheet.addRow(rowArray);
      Object.keys(row).forEach((key) => {
        let colDef = columnFieldToColumnMap.get(key);
        if (colDef) {
          console.log(key);
          console.log(colDef?.type?.indexOf("euroAmount"));
          if (colDef?.type !== undefined && colDef?.type?.indexOf("euroAmount") !== -1) {
            let cell = worksheet.getCell(
              rowIndex + 2,
              colHeaders.indexOf(columnFieldToColumnMap.get(key)?.headerName || "") + 1,
            );
            if (cell.value != null) {
              let numericValue = cell.value as number;
              if (!isNaN(numericValue)) {
                cell.value = numericValue;
                cell.numFmt = "#,##0.00 €";
              }
            }
          } else if (colDef?.type !== undefined && colDef?.type?.indexOf("percentage") !== -1) {
            console.log("percentage");
            let cell = worksheet.getCell(
              rowIndex + 2,
              colHeaders.indexOf(columnFieldToColumnMap.get(key)?.headerName || "") + 1,
            );
            console.log("percentagea");
            if (cell.value != null) {
              let numericValue = (cell.value as number) / 100;
              if (!isNaN(numericValue)) {
                cell.value = numericValue;
                cell.numFmt = "0.00%";
              }
            }
          }
        }
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    // Create a Blob from the buffer
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    // Create a link element
    const link = document.createElement("a");
    // Set the link's href to a URL created from the Blob
    link.href = URL.createObjectURL(blob);
    // Set the download attribute to the desired file name
    link.download = `${exportedExcelName}.xlsx`;
    // Append the link to the body
    document.body.appendChild(link);
    // Programmatically click the link to start the download
    link.click();
    // Remove the link from the body
    document.body.removeChild(link);
  };

  function handleViewChange(event: React.ChangeEvent<HTMLSelectElement>) {
    console.log("view change");
    const viewSelection = savedViews.find((view) => view.id === Number(event.target.value));
    const viewId = Number(event.target.value);
    if (!viewSelection) {
      console.log("No saved view found: " + viewId);
      return;
    }
    applyColumnState(viewSelection);
    setSelectedView(viewSelection);
    localStorage.setItem(`${gridName}-selectedViewId`, event.target.value);
    setTimeout(() => {
      setIsViewModified(false);
    }, 0);
  }

  const updateColumnVisibility = (items: MultiSelectDropdownItemProps[]) => {
    setColumnVisibilityOptions(items);
    const newColState = props.gridRef.current.api.getColumnState().map((colState: ColumnState) => {
      const item = items.find((item) => item.input === colState.colId);
      if (item) {
        return { ...colState, hide: !item.isChecked };
      } else {
        return colState;
      }
    });
    props.gridRef.current.api.applyColumnState({ state: newColState, applyOrder: true });
  };

  const columnVisibilityChanged = (event: ColumnVisibleEvent) => {
    console.log("Column visibility changed: ", event);
    setIsViewModified(true);
    let col = event.column?.getColId();
    let newColumnVisibilityOptions = columnVisibilityOptions.map((item) => {
      if (item.input === col) {
        return { ...item, isChecked: event.visible || false };
      } else {
        return item;
      }
    });
    setColumnVisibilityOptions(newColumnVisibilityOptions);
  };

  function onSaveViewClick() {
    setShowSaveViewModal(true);
  }

  function saveView(viewName: string) {
    const columnState = props.gridRef!.current.api.getColumnState();
    const updatedView: GridViewObject | undefined = savedViews.find((view) => viewName === view?.viewName);
    fetch(`${apiUrl}/api/grid/views`, {
      method: "POST",
      credentials: "include",
      body: Convert.addSavedGridViewRequestToJson({
        columnStates: columnState,
        viewName: viewName,
        gridName: gridName,
        id: updatedView?.id || 0,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return decodeErrorResponse(response);
        } else {
          return response.text();
        }
      })
      .then((response) => {
        const newView = Convert.toAddSavedGridViewResponse(response).gridView;
        if (!updatedView) {
          setSavedViews([...savedViews, newView]);
          let viewOptions: SelectOption[] = [];
          savedViews.forEach((view) => {
            viewOptions.push({ label: view.viewName, value: view.id.toString() });
          });
          viewOptions.push({ label: newView.viewName, value: newView.id.toString() });
          setSelectViewOptions(viewOptions);
          setSelectedView(newView);
          setTimeout(() => {
            setIsViewModified(false);
          }, 0);
          successPopup(`View added successfully.`);
        } else {
          const updatedSavedViews = savedViews.map((view) => {
            if (view.id === newView.id) {
              return newView;
            } else {
              return view;
            }
          });
          setSavedViews(updatedSavedViews);
          successPopup(`View updated successfully.`);
        }
        setShowSaveViewModal(false);
      })
      .catch((error) => {
        errorPopup(error.message);
      });
  }

  function onDeleteSavedViewClick() {
    if (selectedView?.id === 0) {
      console.log("Cannot delete default view");
      return;
    }
    fetch(`${apiUrl}/api/grid/views/${selectedView?.id}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          return decodeErrorResponse(response);
        }
        return response.text();
      })
      .then((response) => {
        console.log(response);
        const newSavedViews = savedViews.filter((view) => view.id !== selectedView?.id);
        setSavedViews(newSavedViews);
        let viewOptions: SelectOption[] = [];
        newSavedViews.forEach((view) => {
          viewOptions.push({ label: view.viewName, value: view.id.toString() });
        });
        setSelectViewOptions(viewOptions);
        successPopup("View deleted successfully.");
        setSelectedView(savedViews[0]);
        props.gridRef!.current.api.resetColumnState();
        localStorage.setItem(`${gridName}-selectedViewId`, "0");
        setTimeout(() => {
          setIsViewModified(false);
        }, 0);
      })
      .catch((error) => errorPopup(error.message));
  }

  return (
    <div className="card border-0 shadow mb-4">
      <div className="card-header ">
        <div className="row d-flex justify-content-between">
          <div className="col-auto d-flex">
            {!props.hideSavedViews && (
              <div className="col-auto d-flex align-items-center">
                <div className="d-flex flex-column me-2">
                  <Select
                    color={"secondary"}
                    onChange={handleViewChange}
                    name={"View"}
                    value={selectedView?.id.toString() || "0"}
                    options={selectViewOptions}
                  />
                </div>
                <div className="col-auto d-flex align-items-center align-self-end">
                  <MultiSelectDropdown
                    color={"secondary"}
                    text={"Column Visibility"}
                    dropDownItems={columnVisibilityOptions}
                    onChanged={updateColumnVisibility}
                    extraClasses="me-2"
                  />
                </div>
                <div className="d-flex col-auto flex-column me-2 align-self-end">
                  <Button
                    disabled={!isViewModified}
                    variant={"secondary"}
                    onClick={onSaveViewClick}
                    children={"Save"}
                  />
                </div>
                <div className="d-flex col-auto flex-column me-2 align-self-end">
                  <Button
                    disabled={selectedView?.viewName === "Default"}
                    variant={"secondary"}
                    onClick={onDeleteSavedViewClick}
                    children={"Delete"}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="col-auto d-flex">
            {props.dropdownFilters &&
              props.dropdownFilters.map((filter) => (
                <div key={filter.label} className="d-flex flex-column me-2">
                  <Select
                    color={"secondary"}
                    onChange={(event) => filter.onSelectionChanged(event.target.value)}
                    name={filter.label}
                    value={filter.selectedOption}
                    options={filter.options}
                  />
                </div>
              ))}
          </div>
        </div>
        <SaveViewModal
          viewName={selectedView?.viewName || ""}
          show={showSaveViewModal}
          handleClose={() => setShowSaveViewModal(false)}
          handleSaveView={saveView}
        ></SaveViewModal>
      </div>
      <div className="card-body">
        <h2 className="h5 mb-5">{props.gridName}</h2>
        <div className="row justify-content-between mb-1 flex-column">
          <div className={"d-flex"}>
            {props.buttons &&
              props.buttons.length > 0 &&
              props.buttons.map((button) => (
                <div className="col-auto">
                  <div className="btn-group" role="group" aria-label="Grid Buttons">
                    <Button
                      variant={"secondary"}
                      children={button.label}
                      className={"d-inline-flex align-items-center animate-up-2 me-2"}
                      faIcon={button.faIcon}
                      onClick={button.onClick}
                    />
                  </div>
                </div>
              ))}
            {canExport && (
              <div className="col-auto mb-2 d-flex me-2">
                <Button
                  variant={"secondary"}
                  className={"d-inline-flex align-items-center animate-up-2"}
                  faIcon={faDownload}
                  onClick={() => exportDataAsExcel()}
                  children={"Export"}
                />
              </div>
            )}
            <div className="col-auto d-flex align-items-center">
              {props.actions && props.actions.length > 0 && (
                <DropdownButtons
                  color={props.buttonColors}
                  actions={mapActions(props.actions)}
                  dropDownLabel={"Actions"}
                  disabled={selectedRows.length === 0}
                  extraClasses={"mb-2"}
                />
              )}
            </div>
            <div className="col-auto d-flex mb-2" style={{ marginLeft: "auto" }}>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputIconRight"
                  placeholder="Search..."
                  aria-label="Search"
                  onChange={handleQuickFilterChange}
                />
                <span className="input-group-text" id="basic-addon2">
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          {selectedRows.length > 0 && (
            <>
              <span>Selected Rows: {selectedRows.length} </span>
              {props.columnsToAggregate?.map((column) => {
                let sumAndCount = selectedRows.reduce(
                  (acc, row) => {
                    if (typeof row[column.colDef.field] === "number") {
                      return { sum: acc.sum + row[column.colDef.field], count: acc.count + 1 };
                    } else {
                      return acc;
                    }
                  },
                  { sum: 0, count: 0 },
                );
                let aggregateFunc = column.aggregateFunction || "sum";
                let average = sumAndCount.count > 0 ? sumAndCount.sum / sumAndCount.count : 0;
                let value = aggregateFunc === "sum" ? sumAndCount.sum : average;
                let prefix = aggregateFunc === "sum" ? "" : "Avg";

                return (
                  <span>
                    {" "}
                    | {prefix} {column.colDef.headerName}: {column.formatter(value)}
                  </span>
                );
              })}
            </>
          )}
          <div className="ag-theme-quartz" style={{ height: 1000 }}>
            <AgGridReact<T>
              onGridReady={onGridReady}
              rowSelection={"multiple"}
              ref={props.gridRef}
              rowData={props.initialRowData}
              defaultColDef={defaultColDef}
              columnDefs={props.columnDefs}
              quickFilterText={quickFilterText}
              paginationPageSizeSelector={[25, 50, 100, 500]}
              onSelectionChanged={handleSelectionChanged}
              pagination={true}
              onSortChanged={() => setIsViewModified(true)}
              onColumnResized={(col) => {
                setIsViewModified(true);
                console.log("Column resized: ", col);
              }}
              onColumnVisible={() => setIsViewModified(true)}
              onColumnMoved={(col) => {
                // TODO: fix - I don't want my columns to be moved when gridOptions are changed.
                // I want to have it so that the selectedView drives the column order
                if (col.source === "gridOptionsChanged") {
                  console.log("gridOptionsChanged");
                  applyColumnState(selectedView || savedViews[0]);
                }
                setIsViewModified(true);
                console.log("Column moved: ", col);
              }}
              onColumnPinned={() => setIsViewModified(true)}
              onFilterChanged={() => setIsViewModified(true)}
              onColumnValueChanged={columnVisibilityChanged}
              enableCellTextSelection={true}
              getRowId={props.getRowId}
              animateRows={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Grid;
