interface CustomLabelProps {
    x?: number;
    y?: number;
    value?: number;
    width?: number;
    height?: number;
    barValueFormatter?: (value: number) => string;
    fontSize?: number;
    offsetX?: number;
}

const CustomLabel: React.FC<CustomLabelProps> = ({
                                                     x = 0,
                                                     y = 0,
                                                     width = 0,
                                                     height = 0,
                                                     value = 0,
                                                     barValueFormatter,
                                                     fontSize,
                                                     offsetX
                                                 }) => {
    const centerX = x + width / 2;
    const centerY = y + height / 2;

    if (!offsetX) {
        offsetX = 0;
    }

    return (
        <text x={centerX + offsetX} y={centerY} fill="#bbb" fontSize={fontSize} fontWeight="bold" textAnchor="middle">
            {barValueFormatter ? barValueFormatter(value) : value}
        </text>
    );
};

export default CustomLabel;