import React, { FC } from "react";

interface PercentageChangeTextProps {
  value: number;
  description?: string;
  valueFormatter?: (value: number) => string;
  fontWeight?: string;
  extraClasses?: string;
}

const PercentageChangeText: FC<PercentageChangeTextProps> = ({
  value,
  description = "",
  valueFormatter,
  extraClasses,
}) => {
  const switchValue = (value: number, greater: string, less: string, equal: string) => {
    if (value > 0) {
      return greater;
    } else if (value < 0) {
      return less;
    } else {
      return equal;
    }
  };

  const formatter = valueFormatter ? valueFormatter : (value: number) => value;

  const svgGreenArrowUp =
    "M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z";
  const svgRedArrowDown =
    "M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z";
  const svgBlueLine = "M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z";

  let svgPathData = switchValue(value, svgGreenArrowUp, svgRedArrowDown, svgBlueLine);
  let textColor = switchValue(value, "text-success", "text-danger", "text-gray-700");

  return (
    <>
      <div className="d-flex align-items-center justify-content-end gap-0">
        <svg
          className={`icon icon-xxs ${textColor}`}
          fill="currentColor"
          viewBox="0 0 22 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fillRule="evenodd" d={svgPathData} clipRule="evenodd"></path>
        </svg>
        {value !== 0 && <span className={`small ${textColor} ${extraClasses}`}> {formatter(Math.abs(value))}% </span>}
        {description && <span className="ms-2">{description}</span>}
      </div>
    </>
  );
};

export default PercentageChangeText;
