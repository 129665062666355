import {ValueFormatterParams} from 'ag-grid-community';
import {CustomCellRendererProps} from "ag-grid-react";


export function dateFormatter(params: ValueFormatterParams) {
    if (params.value) {
        return new Date(params.value).toLocaleDateString('en-GB');
    } else {
        return "";
    }
}

export function paramsEuroFormatter(params: ValueFormatterParams) {
    if (params.value) {
        return parseFloat(params.value).toFixed(0) + ' €';
    } else {
        return "-";
    }
}

export function euroFormatter(val: string) {
    if (val) {
        return parseFloat(val).toFixed(0) + ' €';
    } else {
        return "-";
    }
}

export function euroFormatterTwoDecimals(val: string) {
    if (val) {
        return parseFloat(val).toFixed(2) + ' €';
    } else {
        return "-";
    }
}

export function percentageFormatter(params: ValueFormatterParams) {
    if (params.value) {
        return parseFloat(params.value).toFixed(0) + ' %';
    } else {
        return "-";
    }
}

export function percentageFormatterMultipliedByHundred(params: ValueFormatterParams) {
    if (params.value) {
        return (parseFloat(params.value) * 100).toFixed(0) + '%';
    } else {
        return "-";
    }
}

function mixColors(color1: string, color2: string, ratio: number) {
    const hex = (color: string) => parseInt(color, 16);
    const padHex = (str: string) => (str.length < 2 ? '0' + str : str);
    const r = Math.round(hex(color1.substring(0, 2)) * ratio + hex(color2.substring(0, 2)) * (1 - ratio));
    const g = Math.round(hex(color1.substring(2, 4)) * ratio + hex(color2.substring(2, 4)) * (1 - ratio));
    const b = Math.round(hex(color1.substring(4, 6)) * ratio + hex(color2.substring(4, 6)) * (1 - ratio));
    return padHex(r.toString(16)) + padHex(g.toString(16)) + padHex(b.toString(16));
}


export const occupancyBarRenderer = (params: CustomCellRendererProps) => {
    const occupancy = params.value;
    const barPercentage = Math.min(100, Math.max(0, occupancy));

    // Define your primary and secondary colors
    const primaryColor = 'e02525'; // Replace with your primary color (without the # symbol)
    const secondaryColor = '10B981'; // Replace with your secondary color (without the # symbol)

    // Calculate the color
    const color = mixColors(secondaryColor, primaryColor, occupancy / 100);

    return (
        <div className="d-flex align-items-center" style={{height: '100%'}}>
            <div className="progress w-100" style={{height: '100%'}}>
                <div className="progress-bar" role="progressbar"
                     style={{width: `${barPercentage}%`, height: '100%', backgroundColor: `#${color}`}}
                     aria-valuenow={barPercentage} aria-valuemin={0} aria-valuemax={100}>
                    {occupancy.toFixed(0)}%
                </div>
            </div>
        </div>
    );
};

export const redOrGreenText = {
    'text-danger fw-bolder': negativeValue,
    'text-success fw-bolder': positiveValue
};

function negativeValue(params: ValueFormatterParams) {
    return params.value < 0;
}

function positiveValue(params: ValueFormatterParams) {
    return params.value >= 0;
}

export function twoDecimals(number: number | undefined) {
    if (!number) {
        return 0;
    }
    return parseFloat(number.toFixed(2));
}

export function overViewIncomeFormatter(number: number): string {

    return Math.floor(number).toLocaleString('de-DE') + '€';
}


export function overViewBarChartIncomeFormatter(number: number): string {
    if (number > 1000) {
        return (number / 1000).toFixed(1) + 'k';
    }
    return Math.floor(number).toLocaleString('de-DE') + ' €';
}


export function overViewBarChartAdrFormatter(number: number): string {
    let fixedNumber = parseFloat(number.toFixed(1));
    return fixedNumber.toLocaleString('de-DE') + ' €';
}


export function overViewAdrFormatter(number: number) {
    let fixedNumber = parseFloat(number.toFixed(1));
    return fixedNumber.toLocaleString('de-DE') + ' €';
}

export function reportGraphsIncomeFormatter(number: number | undefined) {
    if (!number) {
        return "0 €";
    }
    return Math.floor(number).toLocaleString('de-DE') + ' €';
}

export function reportGraphsAdrFormatter(number: number | undefined) {
    if (!number) {
        return "0";
    }
    return Math.floor(number);
}

export function reportGraphsOccupancyFormatter(number: number | undefined) {
    if (!number) {
        return "0%";
    }
    return Math.floor(number).toLocaleString('de-DE') + '%';
}

export function reportBarChartNumberFormatter(number: number) {
    return Math.floor(number).toString();
}