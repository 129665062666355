import { Reservation } from "../models/responses";
import { Expense } from "../models/";

export function generateRandomReservation(): Reservation {
  // Generate random data for each field
  return {
    guestId: "",
    sustainabilityFee: 0,
    temporaryStayDuty: 0,
    vat: 0,
    id: Math.floor(Math.random() * 100),
    reservationCode: Math.random().toString(36).substring(7),
    apartmentId: Math.floor(Math.random() * 100),
    platform: Math.random() < 0.5 ? 1 : 2,
    bookingDate: new Date(),
    startDate: new Date(),
    endDate: new Date(),
    actualDaysOfStay: Math.floor(Math.random() * 10) + 1,
    guestName: "Guest #" + Math.floor(Math.random() * 100),
    numberOfGuests: Math.floor(Math.random() * 5) + 1,
    receivableIncome: Math.random() * 1000,
    amountPaid: Math.random() * 1000,
    paymentDate: new Date(),
    commission: Math.random() * 100,
    cleanerId: Math.floor(Math.random() * 10),
    cleaningFee: Math.random() * 100,
    cleaningCost: Math.random() * 50,
    hostingExpense: Math.random() * 100,
    epsilon: Math.random() < 0.5,
    isTaxExempt: false,
    netIncome: Math.random() * 1000,
    netAdr: Math.random() * 1000,
    grossAdr: Math.random() * 1000,
  };
}

export function generateRandomExpense(): Expense {
  // Generate random data for each field
  return {
    id: Math.floor(Math.random() * 100),
    apartmentId: Math.floor(Math.random() * 100),
    date: new Date(),
    categoryId: Math.random() < 0.5 ? 1 : 2,
    amount: Math.random() * 1000,
    notes: "Lorem Ipsum",
  };
}

export function getPlatformName(platform: number): string {
  switch (platform) {
    case 1:
      return "Airbnb";
    case 2:
      return "Booking";
    default:
      return "Out";
  }
}
