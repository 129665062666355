import React from "react";
import "./LoginPage.css";
import kerosLogo from "../staticfiles/KH logo-01.png";
import { apiUrl } from "../layouts/adminLayout";
import { useAuth } from "../hooks/useAuth";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Convert } from "../models/responses";

type LoginPageProps = {
  logoutAndClearSessions?: boolean;
};

const LoginPage: React.FC<LoginPageProps> = ({ logoutAndClearSessions }) => {
  const { user, login, logout } = useAuth();
  useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isAuthenticated = queryParams.get("authenticated") === "true";

  if (logoutAndClearSessions) {
    logout();
    window.location.href = `${apiUrl}/api/logout`;
  }
  console.log(user);

  if (user) {
    return <Navigate to="/" />;
  }

  if (isAuthenticated) {
    fetch(`${apiUrl}/api/user?_=${new Date().getTime()}`, {
      credentials: "include",
    })
      .then((response) => {
        if (response) {
          return response.text();
        }
        throw new Error("Unauthorized");
      })
      .then((response) => {
        console.log(response);
        const user = Convert.toGetUserResponse(response).user;
        login(user);
      })
      .catch((error) => console.error("Error:", error));
  }

  const handleGoogleLogin = () => {
    // Redirect the user to Google's login page
    window.location.href = `${apiUrl}/api/login`;
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <div className="container">
          <div className="row justify-content-center form-bg-image">
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <a href="https://keroshospitality.com" target="_blank" rel="noreferrer">
                    <img src={kerosLogo} width="75%" height="auto" alt="Keros Hospitality Logo" />
                  </a>
                  <br />
                </div>
                <div className="d-grid">
                  <button className="gsi-material-button" onClick={handleGoogleLogin}>
                    <div className="gsi-material-button-state"></div>
                    <div className="gsi-material-button-content-wrapper">
                      <div className="gsi-material-button-icon">
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48 48"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          style={{ display: "block" }}
                        >
                          <path
                            fill="#EA4335"
                            d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                          ></path>
                          <path
                            fill="#4285F4"
                            d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                          ></path>
                          <path
                            fill="#FBBC05"
                            d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                          ></path>
                          <path
                            fill="#34A853"
                            d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                          ></path>
                          <path fill="none" d="M0 0h48v48H0z"></path>
                        </svg>
                      </div>
                      <span className="gsi-material-button-contents me-4">Sign in with Google</span>
                      <span style={{ display: "none" }}>Sign in with Google</span>
                    </div>
                  </button>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <a href="/privacy-policy">Privacy Policy</a>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default LoginPage;
