import React, { useEffect, useRef, useState } from "react";
import { apiUrl } from "../../layouts/adminLayout";
import { Convert, PayoutStat } from "../../models/responses";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import {
  euroFormatterTwoDecimals,
  paramsEuroFormatter,
  percentageFormatterMultipliedByHundred,
  redOrGreenText,
} from "../../utils/formatters";
import Grid, { GridDropdownFilter } from "../../lib/organisms/Grid";
import { AgGridReact } from "ag-grid-react";
import { AdminContextType, useUserContext } from "../../hooks/userUserContext";
import { fetchOptions } from "../../hooks/useAuth";
import { PeriodName } from "../../models";

interface PayoutsRow {
  apartment: string;
  daysBooked: number;
  daysAvailable: number;
  netAdr: number;
  grossIncome: number;
  cleaningFees: number;
  commissions: number;
  netIncome: number;
  taxes: number;
  payout: number;
  payoutRatio: number;
  managementFee: number;
  expenses: number;
  periodDeposit: number;
  cumulativeDeposit: number;
}

const PayoutsPage: React.FC = () => {
  const {
    selectedPeriod,
    selectedYear,
    apartmentIdMap,
    selectedGroup,
    periodOptions,
    setSelectedPeriod,
    groupOptions,
    setSelectedGroup,
    yearOptions,
    setSelectedYear,
  } = useUserContext().context as AdminContextType;

  const grossIncomeColumn: ColDef<PayoutsRow, any> = {
    headerName: "Gross Income",
    field: "grossIncome",
    valueFormatter: paramsEuroFormatter,
    type: ["rightAligned", "euroAmount"],
    cellDataType: "number",
  };
  const cleaningFeesColumn: ColDef<PayoutsRow, any> = {
    headerName: "Cleaning Fees",
    field: "cleaningFees",
    valueFormatter: paramsEuroFormatter,
    type: ["rightAligned", "euroAmount"],
    cellDataType: "number",
  };
  const commissionsColumn: ColDef<PayoutsRow, any> = {
    headerName: "Commissions",
    field: "commissions",
    valueFormatter: paramsEuroFormatter,
    type: ["rightAligned", "euroAmount"],
    cellDataType: "number",
  };
  const netIncomeColumn: ColDef<PayoutsRow, any> = {
    headerName: "Net Income",
    field: "netIncome",
    valueFormatter: paramsEuroFormatter,
    type: ["rightAligned", "euroAmount"],
    cellDataType: "number",
  };
  const taxesColumn: ColDef<PayoutsRow, any> = {
    headerName: "Taxes",
    field: "taxes",
    valueFormatter: paramsEuroFormatter,
    type: ["rightAligned", "euroAmount"],
    cellDataType: "number",
  };
  const payoutColumn: ColDef<PayoutsRow, any> = {
    headerName: "Payout",
    field: "payout",
    valueFormatter: paramsEuroFormatter,
    type: ["rightAligned", "euroAmount"],
    cellDataType: "number",
  };
  const managementFeeColumn: ColDef<PayoutsRow, any> = {
    headerName: "Management Fee",
    field: "managementFee",
    valueFormatter: paramsEuroFormatter,
    type: ["rightAligned", "euroAmount"],
    cellDataType: "number",
  };
  const expensesColumn: ColDef<PayoutsRow, any> = {
    headerName: "Expenses",
    field: "expenses",
    valueFormatter: paramsEuroFormatter,
    type: ["rightAligned", "euroAmount"],
    cellDataType: "number",
  };
  const cumulativeDepositColumn: ColDef<PayoutsRow, any> = {
    headerName: "Cumulative Deposit",
    field: "cumulativeDeposit",
    valueFormatter: paramsEuroFormatter,
    cellClassRules: redOrGreenText,
    type: ["rightAligned", "euroAmount"],
    cellDataType: "number",
  };
  const depositColumn: ColDef<PayoutsRow, any> = {
    headerName: "Period Deposit",
    field: "periodDeposit",
    valueFormatter: paramsEuroFormatter,
    cellClassRules: redOrGreenText,
    type: ["rightAligned", "euroAmount"],
    cellDataType: "number",
  };
  const daysBookedColumn: ColDef<PayoutsRow, any> = { headerName: "Days Booked", field: "daysBooked" };
  const columnDefinitions: ColDef[] = [
    { headerName: "Apartment", field: "apartment" },
    daysBookedColumn,
    { headerName: "Days Available", field: "daysAvailable" },
    {
      headerName: "Net ADR",
      field: "netAdr",
      valueFormatter: paramsEuroFormatter,
      type: ["rightAligned", "euroAmount"],
      cellDataType: "number",
    },
    grossIncomeColumn,
    cleaningFeesColumn,
    commissionsColumn,
    netIncomeColumn,
    taxesColumn,
    payoutColumn,
    {
      headerName: "PayoutRatio",
      field: "payoutRatio",
      valueFormatter: percentageFormatterMultipliedByHundred,
      cellDataType: "number",
      type: ["percentage"],
    },
    managementFeeColumn,
    expensesColumn,
    depositColumn,
    cumulativeDepositColumn,
  ];

  const gridName = "payouts";
  const [apartmentPayouts] = useState<PayoutsRow[]>([]);
  const gridRef = useRef<AgGridReact | any>();

  useEffect(() => {
    getAndSetPayouts(selectedYear.toString(), selectedPeriod, selectedGroup.toString());
  }, [selectedPeriod, selectedYear, selectedGroup, apartmentIdMap]);

  const getAndSetPayouts = (year: string, period: string, groupId: string): void => {
    let url = `${apiUrl}/api/admin/apartments/payout-stats?year=` + year + "&period=" + period + "&groupId=" + groupId;
    fetch(url, fetchOptions)
      .then((response) => {
        if (response) {
          return response.text();
        }
        throw new Error("Unauthorized");
      })
      .then((response) => {
        let payouts = Convert.toGetPayoutsReponse(response)
          .payoutStats.map((payout) => mapPayoutToRow(payout))
          .sort((a, b) => a.apartment.localeCompare(b.apartment));
        gridRef.current!.api.setGridOption("rowData", payouts);
      })
      .catch((error) => console.log(error));
  };

  function mapPayoutToRow(payout: PayoutStat): PayoutsRow {
    return {
      apartment: apartmentIdMap.get(payout.apartmentId)?.name || payout.apartmentId.toString(),
      daysBooked: payout.daysBooked,
      daysAvailable: payout.daysAvailable,
      netAdr: payout.netAdr,
      grossIncome: payout.grossIncome,
      cleaningFees: payout.cleaningFees,
      commissions: payout.commissions,
      netIncome: payout.netIncome,
      taxes: payout.taxes,
      payout: payout.payout,
      payoutRatio: payout.payoutRatio,
      managementFee: payout.managementFee,
      expenses: payout.expenses,
      periodDeposit: payout.periodDeposit,
      cumulativeDeposit: payout.cumulativeDeposit,
    };
  }

  const gridDropdownFilters: GridDropdownFilter[] = [
    {
      label: "Period",
      options: periodOptions,
      selectedOption: selectedPeriod,
      onSelectionChanged: (period) => setSelectedPeriod(period as PeriodName),
    },
    {
      label: "Year",
      options: yearOptions,
      selectedOption: selectedYear.toString(),
      onSelectionChanged: (year) => setSelectedYear(Number(year)),
    },
    {
      label: "Group",
      options: groupOptions,
      selectedOption: selectedGroup.toString(),
      onSelectionChanged: (apartment) => setSelectedGroup(Number(apartment)),
    },
  ];

  return (
    <Grid<PayoutsRow>
      columnDefs={columnDefinitions}
      gridName={gridName}
      dropdownFilters={gridDropdownFilters}
      initialRowData={apartmentPayouts}
      gridRef={gridRef}
      getRowId={(params) => params.data.apartment}
      columnsToAggregate={[
        { colDef: daysBookedColumn, formatter: (x) => x },
        { colDef: grossIncomeColumn, formatter: euroFormatterTwoDecimals },
        { colDef: cleaningFeesColumn, formatter: euroFormatterTwoDecimals },
        { colDef: commissionsColumn, formatter: euroFormatterTwoDecimals },
        { colDef: taxesColumn, formatter: euroFormatterTwoDecimals },
        { colDef: netIncomeColumn, formatter: euroFormatterTwoDecimals },
        { colDef: expensesColumn, formatter: euroFormatterTwoDecimals },
        { colDef: payoutColumn, formatter: euroFormatterTwoDecimals },
        { colDef: depositColumn, formatter: euroFormatterTwoDecimals },
        { colDef: cumulativeDepositColumn, formatter: euroFormatterTwoDecimals },
      ]}
    />
  );
};

export default PayoutsPage;
