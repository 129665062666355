import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { Alert } from "react-bootstrap";

export function decodeErrorResponse(response: Response): Promise<string> {
  return response.text().then((text) => {
    const data = JSON.parse(text);
    const errors = data.errors.map((error: { message: string }) => error.message).join(", ");
    throw new Error(errors);
  });
}

export const renderError = (error: FetchBaseQueryError | SerializedError) => {
  let errorMessage = "An unknown error occurred";

  if ("status" in error) {
    // This is a FetchBaseQueryError
    if (typeof error.status === "number") {
      errorMessage = `Error ${error.status}: ${error.data}`;
    } else {
      errorMessage = `Error: ${error.error}`;
    }
  } else {
    // This is a SerializedError
    errorMessage = error.message || errorMessage;
  }

  return <Alert variant="danger">{errorMessage}</Alert>;
};
