import React, { useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";

export interface DonutChartCardProps {
  data: { name: string; value: number; valueFormatter?: (val: number) => string }[];
  title: string;
  subtitle: string;
}

const COLORS = ["danger", "info", "secondary", "success", "warning", "primary"];

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);

  // Calculate position for the labels
  const labelX = cx + (outerRadius + 30) * cos;
  const labelY = cy + (outerRadius + 30) * sin;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <text
        x={labelX}
        y={labelY}
        textAnchor={textAnchor}
        fill="#333"
        dy={-15} // Adjust this value to move the text up or down
      >
        {`${payload.name}: ${payload.valueFormatter ? payload.valueFormatter(value) : value}`}
      </text>
      <text
        x={labelX}
        y={labelY}
        dy={10} // Adjust this value to control the spacing between lines
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const DonutChartCard: React.FC<DonutChartCardProps> = ({ data, title, subtitle }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

  return (
    <div className="card shadow-sm">
      <div className="card-body">
        <div className="mb-3">
          <div className="d-flex justify-content-between">
            <div>
              <h6 className="card-subtitle mb-1 text-muted">{title}</h6>
              <h5 className="card-title mb-0">{subtitle}</h5>
            </div>
            <div>
              {data.map((entry, index) => (
                <div key={entry.name} className="d-flex align-items-center text-right mb-2">
                  <span className={`shape-xs rounded-circle bg-${COLORS[index % COLORS.length]}`}></span>
                  <span className="ms-2 fw-normal small">
                    {entry.name}: {entry.valueFormatter ? entry.valueFormatter(entry.value) : entry.value}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <hr />
        <div style={{ width: "100%", height: 240, position: "relative" }}>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                data={data}
                cx="50%"
                cy="50%"
                innerRadius="50%"
                outerRadius="70%"
                fill="#8884d8"
                dataKey="value"
                onMouseEnter={onPieEnter}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={`var(--bs-${COLORS[index % COLORS.length]})`} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default DonutChartCard;
