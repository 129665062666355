// To parse this data:
//
//   import { Convert, AddApartmentBlockRequest, AddSavedGridViewRequest, AddSavedGridViewResponse, ApartmentStats, ApartmentStatsWithDate, GetAllUsersResponse, GetApartmentBlocks, GetApartmentGroupsResponse, GetApartmentOwnersResponse, GetApartmentStatTrends, GetApartmentsResponse, GetBookingPaceResponse, GetExpenseCategoriesResponse, GetExpensesResponse, GetGroupedByExpensesGroupResponse, GetGroupedByExpensesResponse, GetManagementFeeRatioResponse, GetManagementFeeRatiosResponse, GetOccupanciesResponse, GetOverviewStatsResponse, GetPayoutRatioResponse, GetPayoutRatiosResponse, GetPayoutStatsWithDates, GetPayoutsReponse, GetPeriodBreakdownStatsResponse, GetReservationsResponse, GetSavedGridViewsResponse, GetStatsForApartmentsResponse, GetStatsForApartmentsWithDates, GetStatsForGroupResponse, GetTotalDepositsWithDates, GetUserResponse, GetYearsInOperationResponse, GroupStatsWithDate, ImportExpensesResponse, ImportReservationsResponse, ManagementFeeRatio, PayoutRatio, PayoutStatsWithDate, TotalDepositsWithDate, UploadToMyDataResponse, User } from "./file";
//
//   const addApartmentBlockRequest = Convert.toAddApartmentBlockRequest(json);
//   const addSavedGridViewRequest = Convert.toAddSavedGridViewRequest(json);
//   const addSavedGridViewResponse = Convert.toAddSavedGridViewResponse(json);
//   const apartmentStats = Convert.toApartmentStats(json);
//   const apartmentStatsWithDate = Convert.toApartmentStatsWithDate(json);
//   const getAllUsersResponse = Convert.toGetAllUsersResponse(json);
//   const getApartmentBlocks = Convert.toGetApartmentBlocks(json);
//   const getApartmentGroupsResponse = Convert.toGetApartmentGroupsResponse(json);
//   const getApartmentOwnersResponse = Convert.toGetApartmentOwnersResponse(json);
//   const getApartmentStatTrends = Convert.toGetApartmentStatTrends(json);
//   const getApartmentsResponse = Convert.toGetApartmentsResponse(json);
//   const getBookingPaceResponse = Convert.toGetBookingPaceResponse(json);
//   const getExpenseCategoriesResponse = Convert.toGetExpenseCategoriesResponse(json);
//   const getExpensesResponse = Convert.toGetExpensesResponse(json);
//   const getGroupedByExpensesGroupResponse = Convert.toGetGroupedByExpensesGroupResponse(json);
//   const getGroupedByExpensesResponse = Convert.toGetGroupedByExpensesResponse(json);
//   const getManagementFeeRatioResponse = Convert.toGetManagementFeeRatioResponse(json);
//   const getManagementFeeRatiosResponse = Convert.toGetManagementFeeRatiosResponse(json);
//   const getOccupanciesResponse = Convert.toGetOccupanciesResponse(json);
//   const getOverviewStatsResponse = Convert.toGetOverviewStatsResponse(json);
//   const getPayoutRatioResponse = Convert.toGetPayoutRatioResponse(json);
//   const getPayoutRatiosResponse = Convert.toGetPayoutRatiosResponse(json);
//   const getPayoutStatsWithDates = Convert.toGetPayoutStatsWithDates(json);
//   const getPayoutsReponse = Convert.toGetPayoutsReponse(json);
//   const getPeriodBreakdownStatsResponse = Convert.toGetPeriodBreakdownStatsResponse(json);
//   const getReservationsResponse = Convert.toGetReservationsResponse(json);
//   const getSavedGridViewsResponse = Convert.toGetSavedGridViewsResponse(json);
//   const getStatsForApartmentsResponse = Convert.toGetStatsForApartmentsResponse(json);
//   const getStatsForApartmentsWithDates = Convert.toGetStatsForApartmentsWithDates(json);
//   const getStatsForGroupResponse = Convert.toGetStatsForGroupResponse(json);
//   const getTotalDepositsWithDates = Convert.toGetTotalDepositsWithDates(json);
//   const getUserResponse = Convert.toGetUserResponse(json);
//   const getYearsInOperationResponse = Convert.toGetYearsInOperationResponse(json);
//   const groupStatsWithDate = Convert.toGroupStatsWithDate(json);
//   const importExpensesResponse = Convert.toImportExpensesResponse(json);
//   const importReservationsResponse = Convert.toImportReservationsResponse(json);
//   const managementFeeRatio = Convert.toManagementFeeRatio(json);
//   const payoutRatio = Convert.toPayoutRatio(json);
//   const payoutStatsWithDate = Convert.toPayoutStatsWithDate(json);
//   const totalDepositsWithDate = Convert.toTotalDepositsWithDate(json);
//   const uploadToMyDataResponse = Convert.toUploadToMyDataResponse(json);
//   const user = Convert.toUser(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface AddApartmentBlockRequest {
  apartmentId: number;
  startDate: Date;
  endDate: Date;
  notes: string;
}

export interface AddSavedGridViewRequest {
  id?: number;
  viewName: string;
  gridName: string;
  columnStates: AddSavedGridViewRequestColumnState[];
}

export interface AddSavedGridViewRequestColumnState {
  aggFunc: null | string;
  colId: string;
  flex: number | null;
  hide: boolean;
  pinned: null | string;
  pivot: boolean;
  pivotIndex: number | null;
  rowGroup: boolean;
  rowGroupIndex: number | null;
  sort: null | string;
  sortIndex: number | null;
  width: number;

  [property: string]: any;
}

export interface AddSavedGridViewResponse {
  gridView: GridViewObject;

  [property: string]: any;
}

export interface GridViewObject {
  columnStates: GridViewColumnState[];
  gridName: string;
  id: number;
  viewName: string;

  [property: string]: any;
}

export interface GridViewColumnState {
  aggFunc: null | string;
  colId: string;
  flex: number | null;
  hide: boolean;
  pinned: null | string;
  pivot: boolean;
  pivotIndex: number | null;
  rowGroup: boolean;
  rowGroupIndex: number | null;
  sort: null | string;
  sortIndex: number | null;
  width: number;

  [property: string]: any;
}

export interface GetAllUsersResponse {
  users: User[];
}

export interface User {
  id: number;
  givenName: string;
  lastName: string;
  email: string;
  profileImageUri: string;
  isAdmin: boolean;
  visibleOwners: number[];
  lastVisited?: Date;
}

export interface GetApartmentBlocks {
  apartmentBlocks: ApartmentBlockCalendarElement[];
}

export interface ApartmentBlockCalendarElement {
  endDate: Date;
  id?: number;
  startDate: Date;
  title: string;
}

export interface GetApartmentGroupsResponse {
  groups: ApartmentGroup[];
}

export interface ApartmentGroup {
  description: string;
  id: number;
  name: string;
}

export interface GetApartmentOwnersResponse {
  owners: ApartmentOwner[];
}

export interface ApartmentOwner {
  fullName: string;
  id: number;
}

export interface GetApartmentStatTrends {
  apartmentStatTrends: ApartmentStatTrend[];
}

export interface ApartmentStatTrend {
  apartmentId: number;
  daysAvailable: number;
  daysAvailablePreviousPeriod: number;
  daysAvailablePreviousYear: number;
  daysBooked: number;
  daysBookedPreviousPeriod: number;
  daysBookedPreviousYear: number;
  grossAdr: number;
  grossAdrPreviousPeriod: number;
  grossAdrPreviousYear: number;
  grossIncome: number;
  grossIncomePreviousPeriod: number;
  grossIncomePreviousYear: number;
  netAdr: number;
  netAdrPreviousPeriod: number;
  netAdrPreviousYear: number;
  netIncome: number;
  netIncomePreviousPeriod: number;
  netIncomePreviousYear: number;
  numberOfVisits: number;
  occupancy: number;
  occupancyPreviousPeriod: number;
  occupancyPreviousYear: number;
  payout: number;
  payoutPreviousPeriod: number;
  payoutPreviousYear: number;
  payoutRatio: number;
  payoutRatioPreviousPeriod: number;
  payoutRatioPreviousYear: number;
}

export interface GetApartmentsResponse {
  apartments: Apartment[];
}

export interface Apartment {
  id: number;
  groupId: number;
  name: string;
  shortName: string;
  dateAdded: Date;
  ownerIds: number[];
}

export interface GetBookingPaceResponse {
  bookingPace: BookingPaceItem[];
  endDate: Date;
  startDate: Date;
}

export interface BookingPaceItem {
  apartmentId: number;
  groupId: number;
  platformId: number;
  grossIncome: number;
  netIncome: number;
  daysBooked: number;
  futureDaysBooked: number;
  bookingsReceived: number;
}

export interface GetExpenseCategoriesResponse {
  expenseCategories: ExpenseCategory[];
}

export interface ExpenseCategory {
  id: number;
  category: string;
  description: string;
}

export interface GetExpensesResponse {
  expenses: Expense[];
}

export interface Expense {
  id: number;
  apartmentId: number;
  categoryId: number;
  date: Date;
  amount: number;
  notes: string;
}

export interface GetGroupedByExpensesGroupResponse {
  expenses: GroupedGroupExpense[];
}

export interface GroupedGroupExpense {
  groupId: number;
  categoryId: number;
  amount: number;
}

export interface GetGroupedByExpensesResponse {
  expenses: GroupedExpense[];
}

export interface GroupedExpense {
  apartmentId: number;
  categoryId: number;
  amount: number;
}

export interface GetManagementFeeRatioResponse {
  managementFeeRatio: ManagementFeeRatioClass;
}

export interface ManagementFeeRatioClass {
  id: number;
  apartmentId: number;
  managementFeeRatio: number;
  effectiveFrom: Date;
}

export interface GetManagementFeeRatiosResponse {
  managementFeeRatios: ManagementFeeRatio[];
}

export interface ManagementFeeRatio {
  id: number;
  apartmentId: number;
  managementFeeRatio: number;
  effectiveFrom: Date;
}

export interface GetOccupanciesResponse {
  date: Date;
  occupancies: OccupancyItem[];
}

export interface OccupancyItem {
  date: Date;
  daysBooked: number;
  daysAvailable: number;
  occupancy: number;
}

export interface GetOverviewStatsResponse {
  overviewStats: OverviewStats;

  [property: string]: any;
}

export interface OverviewStats {
  groupStats: GroupStat[];
  totalStats: TotalStats;

  [property: string]: any;
}

export interface GroupStat {
  averageLengthOfStay: number;
  averageLengthOfStayPrevious: number;
  averageLengthOfStayPreviousYear: number;
  bookingsReceived: number;
  bookingsReceivedPrevious: number;
  bookingsReceivedPreviousYear: number;
  daysBooked: number;
  daysBookedPrevious: number;
  daysBookedPreviousYear: number;
  grossAdr: number;
  grossAdrPrevious: number;
  grossAdrPreviousYear: number;
  grossIncome: number;
  grossIncomePrevious: number;
  grossIncomePreviousYear: number;
  groupId: number;
  lidr: number;
  lidrPrevious: number;
  lidrPreviousYear: number;
  netAdr: number;
  netAdrPrevious: number;
  netAdrPreviousYear: number;
  netIncome: number;
  netIncomePrevious: number;
  netIncomePreviousYear: number;
  occupancy: number;
  occupancyPrevious: number;
  occupancyPreviousYear: number;

  [property: string]: any;
}

export interface TotalStats {
  averageLengthOfStay: number;
  averageLengthOfStayPrevious: number;
  averageLengthOfStayPreviousYear: number;
  bookingsReceived: number;
  bookingsReceivedPrevious: number;
  bookingsReceivedPreviousYear: number;
  daysBooked: number;
  daysBookedPrevious: number;
  daysBookedPreviousYear: number;
  grossAdr: number;
  grossAdrPrevious: number;
  grossAdrPreviousYear: number;
  grossIncome: number;
  grossIncomePrevious: number;
  grossIncomePreviousYear: number;
  lidr: number;
  lidrPrevious: number;
  lidrPreviousYear: number;
  netAdr: number;
  netAdrPrevious: number;
  netAdrPreviousYear: number;
  netIncome: number;
  netIncomePrevious: number;
  netIncomePreviousYear: number;
  occupancy: number;
  occupancyPrevious: number;
  occupancyPreviousYear: number;

  [property: string]: any;
}

export interface GetPayoutRatioResponse {
  payoutRatio: PayoutRatioClass;
}

export interface PayoutRatioClass {
  id: number;
  apartmentId: number;
  payoutRatio: number;
  effectiveFrom: Date;
}

export interface GetPayoutRatiosResponse {
  payoutRatios: PayoutRatio[];
}

export interface PayoutRatio {
  id: number;
  apartmentId: number;
  payoutRatio: number;
  effectiveFrom: Date;
}

export interface GetPayoutStatsWithDates {
  payoutStats: PayoutStatsWithDate[];
}

export interface PayoutStatsWithDate {
  apartmentId: number;
  date: Date;
  payout: number;

  [property: string]: any;
}

export interface GetPayoutsReponse {
  payoutStats: PayoutStat[];
}

export interface PayoutStat {
  apartmentId: number;
  cleaningFees: number;
  commissions: number;
  cumulativeDeposit: number;
  daysAvailable: number;
  daysBooked: number;
  expenses: number;
  grossIncome: number;
  managementFee: number;
  netAdr: number;
  netIncome: number;
  payout: number;
  payoutRatio: number;
  periodDeposit: number;
  taxes: number;
}

export interface GetPeriodBreakdownStatsResponse {
  periodBreakdownStats: PeriodBreakdownStats;

  [property: string]: any;
}

export interface PeriodBreakdownStats {
  apartmentStats: Array<ApartmentStats[]>;
  periodNames: string[];

  [property: string]: any;
}

export interface ApartmentStats {
  apartmentId: number;
  availableBookingDays: number;
  averageLengthOfStay: number;
  averageLidr: number;
  cleaningFees: number;
  daysBlocked: number;
  daysBooked: number;
  grossAdr: number;
  grossIncome: number;
  netAdr: number;
  netIncome: number;
  numberOfReservationsReceived: number;
  numberOfVisits: number;
  occupancy: number;
  platformCommissions: number;
  sustainabilityFee: number;
  temporaryStayDuty: number;
  vat: number;
}

export interface GetReservationsResponse {
  reservations: Reservation[];
}

export interface Reservation {
  actualDaysOfStay: number;
  amountPaid?: number;
  apartmentId: number;
  bookingDate: Date;
  cleanerId?: number;
  cleaningCost?: number;
  cleaningFee?: number;
  commission: number;
  endDate: Date;
  epsilon: boolean;
  grossAdr: number;
  guestId?: string;
  guestName: string;
  hostingExpense?: number;
  id: number;
  isTaxExempt: boolean;
  netAdr: number;
  netIncome: number;
  numberOfGuests: number;
  paymentDate?: Date;
  platform: number;
  receivableIncome: number;
  reservationCode: string;
  startDate: Date;
  sustainabilityFee: number;
  temporaryStayDuty: number;
  vat: number;
}

export interface GetSavedGridViewsResponse {
  views: ViewElement[];

  [property: string]: any;
}

export interface ViewElement {
  columnStates: ViewColumnState[];
  gridName: string;
  id: number;
  viewName: string;

  [property: string]: any;
}

export interface ViewColumnState {
  aggFunc: null | string;
  colId: string;
  flex: number | null;
  hide: boolean;
  pinned: null | string;
  pivot: boolean;
  pivotIndex: number | null;
  rowGroup: boolean;
  rowGroupIndex: number | null;
  sort: null | string;
  sortIndex: number | null;
  width: number;

  [property: string]: any;
}

export interface GetStatsForApartmentsResponse {
  apartmentStats: ApartmentStats[];
}

export interface GetStatsForApartmentsWithDates {
  apartmentStats: ApartmentStatsWithDate[];
}

export interface ApartmentStatsWithDate {
  apartmentId: number;
  availableBookingDays: number;
  averageLengthOfStay: number;
  averageLidr: number;
  cleaningFees: number;
  date: Date;
  daysBlocked: number;
  daysBooked: number;
  grossAdr: number;
  grossIncome: number;
  netAdr: number;
  netIncome: number;
  numberOfReservationsReceived: number;
  numberOfVisits: number;
  occupancy: number;
  platformCommissions: number;
  sustainabilityFee: number;
  temporaryStayDuty: number;
  vat: number;

  [property: string]: any;
}

export interface GetStatsForGroupResponse {
  groupStats: GroupStatsWithDate[];
}

export interface GroupStatsWithDate {
  availableBookingDays: number;
  averageLengthOfStay: number;
  averageLidr: number;
  cleaningFees: number;
  date: Date;
  daysBlocked: number;
  daysBooked: number;
  grossAdr: number;
  grossIncome: number;
  groupId: number;
  netAdr: number;
  netIncome: number;
  numberOfReservationsReceived: number;
  numberOfVisits: number;
  occupancy: number;
  platformCommissions: number;
  sustainabilityFee: number;
  temporaryStayDuty: number;
  vat: number;

  [property: string]: any;
}

export interface GetTotalDepositsWithDates {
  totalDeposits: TotalDepositsWithDate[];
}

export interface TotalDepositsWithDate {
  date: Date;
  deposit: number;

  [property: string]: any;
}

export interface GetUserResponse {
  user: User;

  [property: string]: any;
}

export interface GetYearsInOperationResponse {
  years: number[];
}

export interface ImportExpensesResponse {
  importExpensesResult: ImportExpensesResult;

  [property: string]: any;
}

export interface ImportExpensesResult {
  DeletedExpenseItems: number;
  newExpenseItems: number;

  [property: string]: any;
}

export interface ImportReservationsResponse {
  importReservationsResult: ImportReservationsResult;

  [property: string]: any;
}

export interface ImportReservationsResult {
  deletedReservations: number;
  newReservations: number;
  updatedReservations: number;

  [property: string]: any;
}

export interface UploadToMyDataResponse {
  myDataUploadResponse: MyDataUploadResponse[];
}

export interface MyDataUploadResponse {
  error: string;
  invoiceUUID: string;
  taxInvoiceUUID: string;

  [property: string]: any;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
  public static toAddApartmentBlockRequest(json: string): AddApartmentBlockRequest {
    return cast(JSON.parse(json), r("AddApartmentBlockRequest"));
  }

  public static addApartmentBlockRequestToJson(value: AddApartmentBlockRequest): string {
    return JSON.stringify(uncast(value, r("AddApartmentBlockRequest")), null, 2);
  }

  public static toAddSavedGridViewRequest(json: string): AddSavedGridViewRequest {
    return cast(JSON.parse(json), r("AddSavedGridViewRequest"));
  }

  public static addSavedGridViewRequestToJson(value: AddSavedGridViewRequest): string {
    return JSON.stringify(uncast(value, r("AddSavedGridViewRequest")), null, 2);
  }

  public static toAddSavedGridViewResponse(json: string): AddSavedGridViewResponse {
    return cast(JSON.parse(json), r("AddSavedGridViewResponse"));
  }

  public static addSavedGridViewResponseToJson(value: AddSavedGridViewResponse): string {
    return JSON.stringify(uncast(value, r("AddSavedGridViewResponse")), null, 2);
  }

  public static toApartmentStats(json: string): ApartmentStats {
    return cast(JSON.parse(json), r("ApartmentStats"));
  }

  public static apartmentStatsToJson(value: ApartmentStats): string {
    return JSON.stringify(uncast(value, r("ApartmentStats")), null, 2);
  }

  public static toApartmentStatsWithDate(json: string): ApartmentStatsWithDate {
    return cast(JSON.parse(json), r("ApartmentStatsWithDate"));
  }

  public static apartmentStatsWithDateToJson(value: ApartmentStatsWithDate): string {
    return JSON.stringify(uncast(value, r("ApartmentStatsWithDate")), null, 2);
  }

  public static toGetAllUsersResponse(json: string): GetAllUsersResponse {
    return cast(JSON.parse(json), r("GetAllUsersResponse"));
  }

  public static getAllUsersResponseToJson(value: GetAllUsersResponse): string {
    return JSON.stringify(uncast(value, r("GetAllUsersResponse")), null, 2);
  }

  public static toGetApartmentBlocks(json: string): GetApartmentBlocks {
    return cast(JSON.parse(json), r("GetApartmentBlocks"));
  }

  public static getApartmentBlocksToJson(value: GetApartmentBlocks): string {
    return JSON.stringify(uncast(value, r("GetApartmentBlocks")), null, 2);
  }

  public static toGetApartmentGroupsResponse(json: string): GetApartmentGroupsResponse {
    return cast(JSON.parse(json), r("GetApartmentGroupsResponse"));
  }

  public static getApartmentGroupsResponseToJson(value: GetApartmentGroupsResponse): string {
    return JSON.stringify(uncast(value, r("GetApartmentGroupsResponse")), null, 2);
  }

  public static toGetApartmentOwnersResponse(json: string): GetApartmentOwnersResponse {
    return cast(JSON.parse(json), r("GetApartmentOwnersResponse"));
  }

  public static getApartmentOwnersResponseToJson(value: GetApartmentOwnersResponse): string {
    return JSON.stringify(uncast(value, r("GetApartmentOwnersResponse")), null, 2);
  }

  public static toGetApartmentStatTrends(json: string): GetApartmentStatTrends {
    return cast(JSON.parse(json), r("GetApartmentStatTrends"));
  }

  public static getApartmentStatTrendsToJson(value: GetApartmentStatTrends): string {
    return JSON.stringify(uncast(value, r("GetApartmentStatTrends")), null, 2);
  }

  public static toGetApartmentsResponse(json: string): GetApartmentsResponse {
    return cast(JSON.parse(json), r("GetApartmentsResponse"));
  }

  public static getApartmentsResponseToJson(value: GetApartmentsResponse): string {
    return JSON.stringify(uncast(value, r("GetApartmentsResponse")), null, 2);
  }

  public static toGetBookingPaceResponse(json: string): GetBookingPaceResponse {
    return cast(JSON.parse(json), r("GetBookingPaceResponse"));
  }

  public static getBookingPaceResponseToJson(value: GetBookingPaceResponse): string {
    return JSON.stringify(uncast(value, r("GetBookingPaceResponse")), null, 2);
  }

  public static toGetExpenseCategoriesResponse(json: string): GetExpenseCategoriesResponse {
    return cast(JSON.parse(json), r("GetExpenseCategoriesResponse"));
  }

  public static getExpenseCategoriesResponseToJson(value: GetExpenseCategoriesResponse): string {
    return JSON.stringify(uncast(value, r("GetExpenseCategoriesResponse")), null, 2);
  }

  public static toGetExpensesResponse(json: string): GetExpensesResponse {
    return cast(JSON.parse(json), r("GetExpensesResponse"));
  }

  public static getExpensesResponseToJson(value: GetExpensesResponse): string {
    return JSON.stringify(uncast(value, r("GetExpensesResponse")), null, 2);
  }

  public static toGetGroupedByExpensesGroupResponse(json: string): GetGroupedByExpensesGroupResponse {
    return cast(JSON.parse(json), r("GetGroupedByExpensesGroupResponse"));
  }

  public static getGroupedByExpensesGroupResponseToJson(value: GetGroupedByExpensesGroupResponse): string {
    return JSON.stringify(uncast(value, r("GetGroupedByExpensesGroupResponse")), null, 2);
  }

  public static toGetGroupedByExpensesResponse(json: string): GetGroupedByExpensesResponse {
    return cast(JSON.parse(json), r("GetGroupedByExpensesResponse"));
  }

  public static getGroupedByExpensesResponseToJson(value: GetGroupedByExpensesResponse): string {
    return JSON.stringify(uncast(value, r("GetGroupedByExpensesResponse")), null, 2);
  }

  public static toGetManagementFeeRatioResponse(json: string): GetManagementFeeRatioResponse {
    return cast(JSON.parse(json), r("GetManagementFeeRatioResponse"));
  }

  public static getManagementFeeRatioResponseToJson(value: GetManagementFeeRatioResponse): string {
    return JSON.stringify(uncast(value, r("GetManagementFeeRatioResponse")), null, 2);
  }

  public static toGetManagementFeeRatiosResponse(json: string): GetManagementFeeRatiosResponse {
    return cast(JSON.parse(json), r("GetManagementFeeRatiosResponse"));
  }

  public static getManagementFeeRatiosResponseToJson(value: GetManagementFeeRatiosResponse): string {
    return JSON.stringify(uncast(value, r("GetManagementFeeRatiosResponse")), null, 2);
  }

  public static toGetOccupanciesResponse(json: string): GetOccupanciesResponse {
    return cast(JSON.parse(json), r("GetOccupanciesResponse"));
  }

  public static getOccupanciesResponseToJson(value: GetOccupanciesResponse): string {
    return JSON.stringify(uncast(value, r("GetOccupanciesResponse")), null, 2);
  }

  public static toGetOverviewStatsResponse(json: string): GetOverviewStatsResponse {
    return cast(JSON.parse(json), r("GetOverviewStatsResponse"));
  }

  public static getOverviewStatsResponseToJson(value: GetOverviewStatsResponse): string {
    return JSON.stringify(uncast(value, r("GetOverviewStatsResponse")), null, 2);
  }

  public static toGetPayoutRatioResponse(json: string): GetPayoutRatioResponse {
    return cast(JSON.parse(json), r("GetPayoutRatioResponse"));
  }

  public static getPayoutRatioResponseToJson(value: GetPayoutRatioResponse): string {
    return JSON.stringify(uncast(value, r("GetPayoutRatioResponse")), null, 2);
  }

  public static toGetPayoutRatiosResponse(json: string): GetPayoutRatiosResponse {
    return cast(JSON.parse(json), r("GetPayoutRatiosResponse"));
  }

  public static getPayoutRatiosResponseToJson(value: GetPayoutRatiosResponse): string {
    return JSON.stringify(uncast(value, r("GetPayoutRatiosResponse")), null, 2);
  }

  public static toGetPayoutStatsWithDates(json: string): GetPayoutStatsWithDates {
    return cast(JSON.parse(json), r("GetPayoutStatsWithDates"));
  }

  public static getPayoutStatsWithDatesToJson(value: GetPayoutStatsWithDates): string {
    return JSON.stringify(uncast(value, r("GetPayoutStatsWithDates")), null, 2);
  }

  public static toGetPayoutsReponse(json: string): GetPayoutsReponse {
    return cast(JSON.parse(json), r("GetPayoutsReponse"));
  }

  public static getPayoutsReponseToJson(value: GetPayoutsReponse): string {
    return JSON.stringify(uncast(value, r("GetPayoutsReponse")), null, 2);
  }

  public static toGetPeriodBreakdownStatsResponse(json: string): GetPeriodBreakdownStatsResponse {
    return cast(JSON.parse(json), r("GetPeriodBreakdownStatsResponse"));
  }

  public static getPeriodBreakdownStatsResponseToJson(value: GetPeriodBreakdownStatsResponse): string {
    return JSON.stringify(uncast(value, r("GetPeriodBreakdownStatsResponse")), null, 2);
  }

  public static toGetReservationsResponse(json: string): GetReservationsResponse {
    return cast(JSON.parse(json), r("GetReservationsResponse"));
  }

  public static getReservationsResponseToJson(value: GetReservationsResponse): string {
    return JSON.stringify(uncast(value, r("GetReservationsResponse")), null, 2);
  }

  public static toGetSavedGridViewsResponse(json: string): GetSavedGridViewsResponse {
    return cast(JSON.parse(json), r("GetSavedGridViewsResponse"));
  }

  public static getSavedGridViewsResponseToJson(value: GetSavedGridViewsResponse): string {
    return JSON.stringify(uncast(value, r("GetSavedGridViewsResponse")), null, 2);
  }

  public static toGetStatsForApartmentsResponse(json: string): GetStatsForApartmentsResponse {
    return cast(JSON.parse(json), r("GetStatsForApartmentsResponse"));
  }

  public static getStatsForApartmentsResponseToJson(value: GetStatsForApartmentsResponse): string {
    return JSON.stringify(uncast(value, r("GetStatsForApartmentsResponse")), null, 2);
  }

  public static toGetStatsForApartmentsWithDates(json: string): GetStatsForApartmentsWithDates {
    return cast(JSON.parse(json), r("GetStatsForApartmentsWithDates"));
  }

  public static getStatsForApartmentsWithDatesToJson(value: GetStatsForApartmentsWithDates): string {
    return JSON.stringify(uncast(value, r("GetStatsForApartmentsWithDates")), null, 2);
  }

  public static toGetStatsForGroupResponse(json: string): GetStatsForGroupResponse {
    return cast(JSON.parse(json), r("GetStatsForGroupResponse"));
  }

  public static getStatsForGroupResponseToJson(value: GetStatsForGroupResponse): string {
    return JSON.stringify(uncast(value, r("GetStatsForGroupResponse")), null, 2);
  }

  public static toGetTotalDepositsWithDates(json: string): GetTotalDepositsWithDates {
    return cast(JSON.parse(json), r("GetTotalDepositsWithDates"));
  }

  public static getTotalDepositsWithDatesToJson(value: GetTotalDepositsWithDates): string {
    return JSON.stringify(uncast(value, r("GetTotalDepositsWithDates")), null, 2);
  }

  public static toGetUserResponse(json: string): GetUserResponse {
    return cast(JSON.parse(json), r("GetUserResponse"));
  }

  public static getUserResponseToJson(value: GetUserResponse): string {
    return JSON.stringify(uncast(value, r("GetUserResponse")), null, 2);
  }

  public static toGetYearsInOperationResponse(json: string): GetYearsInOperationResponse {
    return cast(JSON.parse(json), r("GetYearsInOperationResponse"));
  }

  public static getYearsInOperationResponseToJson(value: GetYearsInOperationResponse): string {
    return JSON.stringify(uncast(value, r("GetYearsInOperationResponse")), null, 2);
  }

  public static toGroupStatsWithDate(json: string): GroupStatsWithDate {
    return cast(JSON.parse(json), r("GroupStatsWithDate"));
  }

  public static groupStatsWithDateToJson(value: GroupStatsWithDate): string {
    return JSON.stringify(uncast(value, r("GroupStatsWithDate")), null, 2);
  }

  public static toImportExpensesResponse(json: string): ImportExpensesResponse {
    return cast(JSON.parse(json), r("ImportExpensesResponse"));
  }

  public static importExpensesResponseToJson(value: ImportExpensesResponse): string {
    return JSON.stringify(uncast(value, r("ImportExpensesResponse")), null, 2);
  }

  public static toImportReservationsResponse(json: string): ImportReservationsResponse {
    return cast(JSON.parse(json), r("ImportReservationsResponse"));
  }

  public static importReservationsResponseToJson(value: ImportReservationsResponse): string {
    return JSON.stringify(uncast(value, r("ImportReservationsResponse")), null, 2);
  }

  public static toManagementFeeRatio(json: string): ManagementFeeRatio {
    return cast(JSON.parse(json), r("ManagementFeeRatio"));
  }

  public static managementFeeRatioToJson(value: ManagementFeeRatio): string {
    return JSON.stringify(uncast(value, r("ManagementFeeRatio")), null, 2);
  }

  public static toPayoutRatio(json: string): PayoutRatio {
    return cast(JSON.parse(json), r("PayoutRatio"));
  }

  public static payoutRatioToJson(value: PayoutRatio): string {
    return JSON.stringify(uncast(value, r("PayoutRatio")), null, 2);
  }

  public static toPayoutStatsWithDate(json: string): PayoutStatsWithDate {
    return cast(JSON.parse(json), r("PayoutStatsWithDate"));
  }

  public static payoutStatsWithDateToJson(value: PayoutStatsWithDate): string {
    return JSON.stringify(uncast(value, r("PayoutStatsWithDate")), null, 2);
  }

  public static toTotalDepositsWithDate(json: string): TotalDepositsWithDate {
    return cast(JSON.parse(json), r("TotalDepositsWithDate"));
  }

  public static totalDepositsWithDateToJson(value: TotalDepositsWithDate): string {
    return JSON.stringify(uncast(value, r("TotalDepositsWithDate")), null, 2);
  }

  public static toUploadToMyDataResponse(json: string): UploadToMyDataResponse {
    return cast(JSON.parse(json), r("UploadToMyDataResponse"));
  }

  public static uploadToMyDataResponseToJson(value: UploadToMyDataResponse): string {
    return JSON.stringify(uncast(value, r("UploadToMyDataResponse")), null, 2);
  }

  public static toUser(json: string): User {
    return cast(JSON.parse(json), r("User"));
  }

  public static userToJson(value: User): string {
    return JSON.stringify(uncast(value, r("User")), null, 2);
  }
}

function invalidValue(typ: any, val: any, key: any, parent: any = ""): never {
  const prettyTyp = prettyTypeName(typ);
  const parentText = parent ? ` on ${parent}` : "";
  const keyText = key ? ` for key "${key}"` : "";
  throw Error(`Invalid value${keyText}${parentText}. Expected ${prettyTyp} but got ${JSON.stringify(val)}`);
}

function prettyTypeName(typ: any): string {
  if (Array.isArray(typ)) {
    if (typ.length === 2 && typ[0] === undefined) {
      return `an optional ${prettyTypeName(typ[1])}`;
    } else {
      return `one of [${typ
        .map((a) => {
          return prettyTypeName(a);
        })
        .join(", ")}]`;
    }
  } else if (typeof typ === "object" && typ.literal !== undefined) {
    return typ.literal;
  } else {
    return typeof typ;
  }
}

function jsonToJSProps(typ: any): any {
  if (typ.jsonToJS === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => (map[p.json] = { key: p.js, typ: p.typ }));
    typ.jsonToJS = map;
  }
  return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
  if (typ.jsToJSON === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => (map[p.js] = { key: p.json, typ: p.typ }));
    typ.jsToJSON = map;
  }
  return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = "", parent: any = ""): any {
  function transformPrimitive(typ: string, val: any): any {
    if (typeof typ === typeof val) return val;
    return invalidValue(typ, val, key, parent);
  }

  function transformUnion(typs: any[], val: any): any {
    // val must validate against one typ in typs
    const l = typs.length;
    for (let i = 0; i < l; i++) {
      const typ = typs[i];
      try {
        return transform(val, typ, getProps);
      } catch (_) {}
    }
    return invalidValue(typs, val, key, parent);
  }

  function transformEnum(cases: string[], val: any): any {
    if (cases.indexOf(val) !== -1) return val;
    return invalidValue(
      cases.map((a) => {
        return l(a);
      }),
      val,
      key,
      parent,
    );
  }

  function transformArray(typ: any, val: any): any {
    // val must be an array with no invalid elements
    if (!Array.isArray(val)) return invalidValue(l("array"), val, key, parent);
    return val.map((el) => transform(el, typ, getProps));
  }

  function transformDate(val: any): any {
    if (val === null) {
      return null;
    }
    const d = new Date(val);
    if (isNaN(d.valueOf())) {
      return invalidValue(l("Date"), val, key, parent);
    }
    return d;
  }

  function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
    if (val === null || typeof val !== "object" || Array.isArray(val)) {
      return invalidValue(l(ref || "object"), val, key, parent);
    }
    const result: any = {};
    Object.getOwnPropertyNames(props).forEach((key) => {
      const prop = props[key];
      const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
      result[prop.key] = transform(v, prop.typ, getProps, key, ref);
    });
    Object.getOwnPropertyNames(val).forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(props, key)) {
        result[key] = transform(val[key], additional, getProps, key, ref);
      }
    });
    return result;
  }

  if (typ === "any") return val;
  if (typ === null) {
    if (val === null) return val;
    return invalidValue(typ, val, key, parent);
  }
  if (typ === false) return invalidValue(typ, val, key, parent);
  let ref: any = undefined;
  while (typeof typ === "object" && typ.ref !== undefined) {
    ref = typ.ref;
    typ = typeMap[typ.ref];
  }
  if (Array.isArray(typ)) return transformEnum(typ, val);
  if (typeof typ === "object") {
    return typ.hasOwnProperty("unionMembers")
      ? transformUnion(typ.unionMembers, val)
      : typ.hasOwnProperty("arrayItems")
        ? transformArray(typ.arrayItems, val)
        : typ.hasOwnProperty("props")
          ? transformObject(getProps(typ), typ.additional, val)
          : invalidValue(typ, val, key, parent);
  }
  // Numbers can be parsed by Date but shouldn't be.
  if (typ === Date && typeof val !== "number") return transformDate(val);
  return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
  return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
  return transform(val, typ, jsToJSONProps);
}

function l(typ: any) {
  return { literal: typ };
}

function a(typ: any) {
  return { arrayItems: typ };
}

function u(...typs: any[]) {
  return { unionMembers: typs };
}

function o(props: any[], additional: any) {
  return { props, additional };
}

function m(additional: any) {
  return { props: [], additional };
}

function r(name: string) {
  return { ref: name };
}

const typeMap: any = {
  AddApartmentBlockRequest: o(
    [
      { json: "apartmentId", js: "apartmentId", typ: 3.14 },
      { json: "startDate", js: "startDate", typ: Date },
      { json: "endDate", js: "endDate", typ: Date },
      { json: "notes", js: "notes", typ: "" },
    ],
    false,
  ),
  AddSavedGridViewRequest: o(
    [
      { json: "id", js: "id", typ: u(undefined, 3.14) },
      { json: "viewName", js: "viewName", typ: "" },
      { json: "gridName", js: "gridName", typ: "" },
      { json: "columnStates", js: "columnStates", typ: a(r("AddSavedGridViewRequestColumnState")) },
    ],
    false,
  ),
  AddSavedGridViewRequestColumnState: o(
    [
      { json: "aggFunc", js: "aggFunc", typ: u(null, "") },
      { json: "colId", js: "colId", typ: "" },
      { json: "flex", js: "flex", typ: u(0, null) },
      { json: "hide", js: "hide", typ: true },
      { json: "pinned", js: "pinned", typ: u(null, "") },
      { json: "pivot", js: "pivot", typ: true },
      { json: "pivotIndex", js: "pivotIndex", typ: u(0, null) },
      { json: "rowGroup", js: "rowGroup", typ: true },
      { json: "rowGroupIndex", js: "rowGroupIndex", typ: u(0, null) },
      { json: "sort", js: "sort", typ: u(null, "") },
      { json: "sortIndex", js: "sortIndex", typ: u(0, null) },
      { json: "width", js: "width", typ: 0 },
    ],
    "any",
  ),
  AddSavedGridViewResponse: o([{ json: "gridView", js: "gridView", typ: r("GridViewObject") }], "any"),
  GridViewObject: o(
    [
      { json: "columnStates", js: "columnStates", typ: a(r("GridViewColumnState")) },
      { json: "gridName", js: "gridName", typ: "" },
      { json: "id", js: "id", typ: 3.14 },
      { json: "viewName", js: "viewName", typ: "" },
    ],
    "any",
  ),
  GridViewColumnState: o(
    [
      { json: "aggFunc", js: "aggFunc", typ: u(null, "") },
      { json: "colId", js: "colId", typ: "" },
      { json: "flex", js: "flex", typ: u(0, null) },
      { json: "hide", js: "hide", typ: true },
      { json: "pinned", js: "pinned", typ: u(null, "") },
      { json: "pivot", js: "pivot", typ: true },
      { json: "pivotIndex", js: "pivotIndex", typ: u(0, null) },
      { json: "rowGroup", js: "rowGroup", typ: true },
      { json: "rowGroupIndex", js: "rowGroupIndex", typ: u(0, null) },
      { json: "sort", js: "sort", typ: u(null, "") },
      { json: "sortIndex", js: "sortIndex", typ: u(0, null) },
      { json: "width", js: "width", typ: 0 },
    ],
    "any",
  ),
  GetAllUsersResponse: o([{ json: "users", js: "users", typ: a(r("User")) }], false),
  User: o(
    [
      { json: "id", js: "id", typ: 3.14 },
      { json: "givenName", js: "givenName", typ: "" },
      { json: "lastName", js: "lastName", typ: "" },
      { json: "email", js: "email", typ: "" },
      { json: "profileImageUri", js: "profileImageUri", typ: "" },
      { json: "isAdmin", js: "isAdmin", typ: true },
      { json: "visibleOwners", js: "visibleOwners", typ: a(3.14) },
      { json: "lastVisited", js: "lastVisited", typ: u(undefined, Date) },
    ],
    false,
  ),
  GetApartmentBlocks: o(
    [{ json: "apartmentBlocks", js: "apartmentBlocks", typ: a(r("ApartmentBlockCalendarElement")) }],
    false,
  ),
  ApartmentBlockCalendarElement: o(
    [
      { json: "endDate", js: "endDate", typ: Date },
      { json: "id", js: "id", typ: u(undefined, 3.14) },
      { json: "startDate", js: "startDate", typ: Date },
      { json: "title", js: "title", typ: "" },
    ],
    false,
  ),
  GetApartmentGroupsResponse: o([{ json: "groups", js: "groups", typ: a(r("ApartmentGroup")) }], false),
  ApartmentGroup: o(
    [
      { json: "description", js: "description", typ: "" },
      { json: "id", js: "id", typ: 3.14 },
      { json: "name", js: "name", typ: "" },
    ],
    false,
  ),
  GetApartmentOwnersResponse: o([{ json: "owners", js: "owners", typ: a(r("ApartmentOwner")) }], false),
  ApartmentOwner: o(
    [
      { json: "fullName", js: "fullName", typ: "" },
      { json: "id", js: "id", typ: 3.14 },
    ],
    false,
  ),
  GetApartmentStatTrends: o(
    [{ json: "apartmentStatTrends", js: "apartmentStatTrends", typ: a(r("ApartmentStatTrend")) }],
    false,
  ),
  ApartmentStatTrend: o(
    [
      { json: "apartmentId", js: "apartmentId", typ: 3.14 },
      { json: "daysAvailable", js: "daysAvailable", typ: 3.14 },
      { json: "daysAvailablePreviousPeriod", js: "daysAvailablePreviousPeriod", typ: 3.14 },
      { json: "daysAvailablePreviousYear", js: "daysAvailablePreviousYear", typ: 3.14 },
      { json: "daysBooked", js: "daysBooked", typ: 3.14 },
      { json: "daysBookedPreviousPeriod", js: "daysBookedPreviousPeriod", typ: 3.14 },
      { json: "daysBookedPreviousYear", js: "daysBookedPreviousYear", typ: 3.14 },
      { json: "grossAdr", js: "grossAdr", typ: 3.14 },
      { json: "grossAdrPreviousPeriod", js: "grossAdrPreviousPeriod", typ: 3.14 },
      { json: "grossAdrPreviousYear", js: "grossAdrPreviousYear", typ: 3.14 },
      { json: "grossIncome", js: "grossIncome", typ: 3.14 },
      { json: "grossIncomePreviousPeriod", js: "grossIncomePreviousPeriod", typ: 3.14 },
      { json: "grossIncomePreviousYear", js: "grossIncomePreviousYear", typ: 3.14 },
      { json: "netAdr", js: "netAdr", typ: 3.14 },
      { json: "netAdrPreviousPeriod", js: "netAdrPreviousPeriod", typ: 3.14 },
      { json: "netAdrPreviousYear", js: "netAdrPreviousYear", typ: 3.14 },
      { json: "netIncome", js: "netIncome", typ: 3.14 },
      { json: "netIncomePreviousPeriod", js: "netIncomePreviousPeriod", typ: 3.14 },
      { json: "netIncomePreviousYear", js: "netIncomePreviousYear", typ: 3.14 },
      { json: "numberOfVisits", js: "numberOfVisits", typ: 3.14 },
      { json: "occupancy", js: "occupancy", typ: 3.14 },
      { json: "occupancyPreviousPeriod", js: "occupancyPreviousPeriod", typ: 3.14 },
      { json: "occupancyPreviousYear", js: "occupancyPreviousYear", typ: 3.14 },
      { json: "payout", js: "payout", typ: 3.14 },
      { json: "payoutPreviousPeriod", js: "payoutPreviousPeriod", typ: 3.14 },
      { json: "payoutPreviousYear", js: "payoutPreviousYear", typ: 3.14 },
      { json: "payoutRatio", js: "payoutRatio", typ: 3.14 },
      { json: "payoutRatioPreviousPeriod", js: "payoutRatioPreviousPeriod", typ: 3.14 },
      { json: "payoutRatioPreviousYear", js: "payoutRatioPreviousYear", typ: 3.14 },
    ],
    false,
  ),
  GetApartmentsResponse: o([{ json: "apartments", js: "apartments", typ: a(r("Apartment")) }], false),
  Apartment: o(
    [
      { json: "id", js: "id", typ: 0 },
      { json: "groupId", js: "groupId", typ: 0 },
      { json: "name", js: "name", typ: "" },
      { json: "shortName", js: "shortName", typ: "" },
      { json: "dateAdded", js: "dateAdded", typ: Date },
      { json: "ownerIds", js: "ownerIds", typ: a(0) },
    ],
    false,
  ),
  GetBookingPaceResponse: o(
    [
      { json: "bookingPace", js: "bookingPace", typ: a(r("BookingPaceItem")) },
      { json: "endDate", js: "endDate", typ: Date },
      { json: "startDate", js: "startDate", typ: Date },
    ],
    false,
  ),
  BookingPaceItem: o(
    [
      { json: "apartmentId", js: "apartmentId", typ: 3.14 },
      { json: "groupId", js: "groupId", typ: 3.14 },
      { json: "platformId", js: "platformId", typ: 3.14 },
      { json: "grossIncome", js: "grossIncome", typ: 3.14 },
      { json: "netIncome", js: "netIncome", typ: 3.14 },
      { json: "daysBooked", js: "daysBooked", typ: 3.14 },
      { json: "futureDaysBooked", js: "futureDaysBooked", typ: 3.14 },
      { json: "bookingsReceived", js: "bookingsReceived", typ: 3.14 },
    ],
    false,
  ),
  GetExpenseCategoriesResponse: o(
    [{ json: "expenseCategories", js: "expenseCategories", typ: a(r("ExpenseCategory")) }],
    false,
  ),
  ExpenseCategory: o(
    [
      { json: "id", js: "id", typ: 0 },
      { json: "category", js: "category", typ: "" },
      { json: "description", js: "description", typ: "" },
    ],
    false,
  ),
  GetExpensesResponse: o([{ json: "expenses", js: "expenses", typ: a(r("Expense")) }], false),
  Expense: o(
    [
      { json: "id", js: "id", typ: 0 },
      { json: "apartmentId", js: "apartmentId", typ: 0 },
      { json: "categoryId", js: "categoryId", typ: 0 },
      { json: "date", js: "date", typ: Date },
      { json: "amount", js: "amount", typ: 3.14 },
      { json: "notes", js: "notes", typ: "" },
    ],
    false,
  ),
  GetGroupedByExpensesGroupResponse: o([{ json: "expenses", js: "expenses", typ: a(r("GroupedGroupExpense")) }], false),
  GroupedGroupExpense: o(
    [
      { json: "groupId", js: "groupId", typ: 0 },
      { json: "categoryId", js: "categoryId", typ: 0 },
      { json: "amount", js: "amount", typ: 3.14 },
    ],
    false,
  ),
  GetGroupedByExpensesResponse: o([{ json: "expenses", js: "expenses", typ: a(r("GroupedExpense")) }], false),
  GroupedExpense: o(
    [
      { json: "apartmentId", js: "apartmentId", typ: 0 },
      { json: "categoryId", js: "categoryId", typ: 0 },
      { json: "amount", js: "amount", typ: 3.14 },
    ],
    false,
  ),
  GetManagementFeeRatioResponse: o(
    [{ json: "managementFeeRatio", js: "managementFeeRatio", typ: r("ManagementFeeRatioClass") }],
    false,
  ),
  ManagementFeeRatioClass: o(
    [
      { json: "id", js: "id", typ: 0 },
      { json: "apartmentId", js: "apartmentId", typ: 0 },
      { json: "managementFeeRatio", js: "managementFeeRatio", typ: 3.14 },
      { json: "effectiveFrom", js: "effectiveFrom", typ: Date },
    ],
    false,
  ),
  GetManagementFeeRatiosResponse: o(
    [{ json: "managementFeeRatios", js: "managementFeeRatios", typ: a(r("ManagementFeeRatio")) }],
    false,
  ),
  ManagementFeeRatio: o(
    [
      { json: "id", js: "id", typ: 0 },
      { json: "apartmentId", js: "apartmentId", typ: 0 },
      { json: "managementFeeRatio", js: "managementFeeRatio", typ: 3.14 },
      { json: "effectiveFrom", js: "effectiveFrom", typ: Date },
    ],
    false,
  ),
  GetOccupanciesResponse: o(
    [
      { json: "date", js: "date", typ: Date },
      { json: "occupancies", js: "occupancies", typ: a(r("OccupancyItem")) },
    ],
    false,
  ),
  OccupancyItem: o(
    [
      { json: "date", js: "date", typ: Date },
      { json: "daysBooked", js: "daysBooked", typ: 3.14 },
      { json: "daysAvailable", js: "daysAvailable", typ: 3.14 },
      { json: "occupancy", js: "occupancy", typ: 3.14 },
    ],
    false,
  ),
  GetOverviewStatsResponse: o([{ json: "overviewStats", js: "overviewStats", typ: r("OverviewStats") }], "any"),
  OverviewStats: o(
    [
      { json: "groupStats", js: "groupStats", typ: a(r("GroupStat")) },
      { json: "totalStats", js: "totalStats", typ: r("TotalStats") },
    ],
    "any",
  ),
  GroupStat: o(
    [
      { json: "averageLengthOfStay", js: "averageLengthOfStay", typ: 3.14 },
      { json: "averageLengthOfStayPrevious", js: "averageLengthOfStayPrevious", typ: 3.14 },
      { json: "averageLengthOfStayPreviousYear", js: "averageLengthOfStayPreviousYear", typ: 3.14 },
      { json: "bookingsReceived", js: "bookingsReceived", typ: 0 },
      { json: "bookingsReceivedPrevious", js: "bookingsReceivedPrevious", typ: 0 },
      { json: "bookingsReceivedPreviousYear", js: "bookingsReceivedPreviousYear", typ: 0 },
      { json: "daysBooked", js: "daysBooked", typ: 0 },
      { json: "daysBookedPrevious", js: "daysBookedPrevious", typ: 0 },
      { json: "daysBookedPreviousYear", js: "daysBookedPreviousYear", typ: 0 },
      { json: "grossAdr", js: "grossAdr", typ: 3.14 },
      { json: "grossAdrPrevious", js: "grossAdrPrevious", typ: 3.14 },
      { json: "grossAdrPreviousYear", js: "grossAdrPreviousYear", typ: 3.14 },
      { json: "grossIncome", js: "grossIncome", typ: 3.14 },
      { json: "grossIncomePrevious", js: "grossIncomePrevious", typ: 3.14 },
      { json: "grossIncomePreviousYear", js: "grossIncomePreviousYear", typ: 3.14 },
      { json: "groupId", js: "groupId", typ: 0 },
      { json: "lidr", js: "lidr", typ: 3.14 },
      { json: "lidrPrevious", js: "lidrPrevious", typ: 3.14 },
      { json: "lidrPreviousYear", js: "lidrPreviousYear", typ: 3.14 },
      { json: "netAdr", js: "netAdr", typ: 3.14 },
      { json: "netAdrPrevious", js: "netAdrPrevious", typ: 3.14 },
      { json: "netAdrPreviousYear", js: "netAdrPreviousYear", typ: 3.14 },
      { json: "netIncome", js: "netIncome", typ: 3.14 },
      { json: "netIncomePrevious", js: "netIncomePrevious", typ: 3.14 },
      { json: "netIncomePreviousYear", js: "netIncomePreviousYear", typ: 3.14 },
      { json: "occupancy", js: "occupancy", typ: 3.14 },
      { json: "occupancyPrevious", js: "occupancyPrevious", typ: 3.14 },
      { json: "occupancyPreviousYear", js: "occupancyPreviousYear", typ: 3.14 },
    ],
    "any",
  ),
  TotalStats: o(
    [
      { json: "averageLengthOfStay", js: "averageLengthOfStay", typ: 3.14 },
      { json: "averageLengthOfStayPrevious", js: "averageLengthOfStayPrevious", typ: 3.14 },
      { json: "averageLengthOfStayPreviousYear", js: "averageLengthOfStayPreviousYear", typ: 3.14 },
      { json: "bookingsReceived", js: "bookingsReceived", typ: 0 },
      { json: "bookingsReceivedPrevious", js: "bookingsReceivedPrevious", typ: 0 },
      { json: "bookingsReceivedPreviousYear", js: "bookingsReceivedPreviousYear", typ: 0 },
      { json: "daysBooked", js: "daysBooked", typ: 0 },
      { json: "daysBookedPrevious", js: "daysBookedPrevious", typ: 0 },
      { json: "daysBookedPreviousYear", js: "daysBookedPreviousYear", typ: 0 },
      { json: "grossAdr", js: "grossAdr", typ: 3.14 },
      { json: "grossAdrPrevious", js: "grossAdrPrevious", typ: 3.14 },
      { json: "grossAdrPreviousYear", js: "grossAdrPreviousYear", typ: 3.14 },
      { json: "grossIncome", js: "grossIncome", typ: 3.14 },
      { json: "grossIncomePrevious", js: "grossIncomePrevious", typ: 3.14 },
      { json: "grossIncomePreviousYear", js: "grossIncomePreviousYear", typ: 3.14 },
      { json: "lidr", js: "lidr", typ: 3.14 },
      { json: "lidrPrevious", js: "lidrPrevious", typ: 3.14 },
      { json: "lidrPreviousYear", js: "lidrPreviousYear", typ: 3.14 },
      { json: "netAdr", js: "netAdr", typ: 3.14 },
      { json: "netAdrPrevious", js: "netAdrPrevious", typ: 3.14 },
      { json: "netAdrPreviousYear", js: "netAdrPreviousYear", typ: 3.14 },
      { json: "netIncome", js: "netIncome", typ: 3.14 },
      { json: "netIncomePrevious", js: "netIncomePrevious", typ: 3.14 },
      { json: "netIncomePreviousYear", js: "netIncomePreviousYear", typ: 3.14 },
      { json: "occupancy", js: "occupancy", typ: 3.14 },
      { json: "occupancyPrevious", js: "occupancyPrevious", typ: 3.14 },
      { json: "occupancyPreviousYear", js: "occupancyPreviousYear", typ: 3.14 },
    ],
    "any",
  ),
  GetPayoutRatioResponse: o([{ json: "payoutRatio", js: "payoutRatio", typ: r("PayoutRatioClass") }], false),
  PayoutRatioClass: o(
    [
      { json: "id", js: "id", typ: 0 },
      { json: "apartmentId", js: "apartmentId", typ: 0 },
      { json: "payoutRatio", js: "payoutRatio", typ: 3.14 },
      { json: "effectiveFrom", js: "effectiveFrom", typ: Date },
    ],
    false,
  ),
  GetPayoutRatiosResponse: o([{ json: "payoutRatios", js: "payoutRatios", typ: a(r("PayoutRatio")) }], false),
  PayoutRatio: o(
    [
      { json: "id", js: "id", typ: 0 },
      { json: "apartmentId", js: "apartmentId", typ: 0 },
      { json: "payoutRatio", js: "payoutRatio", typ: 3.14 },
      { json: "effectiveFrom", js: "effectiveFrom", typ: Date },
    ],
    false,
  ),
  GetPayoutStatsWithDates: o([{ json: "payoutStats", js: "payoutStats", typ: a(r("PayoutStatsWithDate")) }], false),
  PayoutStatsWithDate: o(
    [
      { json: "apartmentId", js: "apartmentId", typ: 3.14 },
      { json: "date", js: "date", typ: Date },
      { json: "payout", js: "payout", typ: 3.14 },
    ],
    "any",
  ),
  GetPayoutsReponse: o([{ json: "payoutStats", js: "payoutStats", typ: a(r("PayoutStat")) }], false),
  PayoutStat: o(
    [
      { json: "apartmentId", js: "apartmentId", typ: 3.14 },
      { json: "cleaningFees", js: "cleaningFees", typ: 3.14 },
      { json: "commissions", js: "commissions", typ: 3.14 },
      { json: "cumulativeDeposit", js: "cumulativeDeposit", typ: 3.14 },
      { json: "daysAvailable", js: "daysAvailable", typ: 3.14 },
      { json: "daysBooked", js: "daysBooked", typ: 3.14 },
      { json: "expenses", js: "expenses", typ: 3.14 },
      { json: "grossIncome", js: "grossIncome", typ: 3.14 },
      { json: "managementFee", js: "managementFee", typ: 3.14 },
      { json: "netAdr", js: "netAdr", typ: 3.14 },
      { json: "netIncome", js: "netIncome", typ: 3.14 },
      { json: "payout", js: "payout", typ: 3.14 },
      { json: "payoutRatio", js: "payoutRatio", typ: 3.14 },
      { json: "periodDeposit", js: "periodDeposit", typ: 3.14 },
      { json: "taxes", js: "taxes", typ: 3.14 },
    ],
    false,
  ),
  GetPeriodBreakdownStatsResponse: o(
    [{ json: "periodBreakdownStats", js: "periodBreakdownStats", typ: r("PeriodBreakdownStats") }],
    "any",
  ),
  PeriodBreakdownStats: o(
    [
      { json: "apartmentStats", js: "apartmentStats", typ: a(a(r("ApartmentStats"))) },
      { json: "periodNames", js: "periodNames", typ: a("") },
    ],
    "any",
  ),
  ApartmentStats: o(
    [
      { json: "apartmentId", js: "apartmentId", typ: 3.14 },
      { json: "availableBookingDays", js: "availableBookingDays", typ: 3.14 },
      { json: "averageLengthOfStay", js: "averageLengthOfStay", typ: 3.14 },
      { json: "averageLidr", js: "averageLidr", typ: 3.14 },
      { json: "cleaningFees", js: "cleaningFees", typ: 3.14 },
      { json: "daysBlocked", js: "daysBlocked", typ: 3.14 },
      { json: "daysBooked", js: "daysBooked", typ: 3.14 },
      { json: "grossAdr", js: "grossAdr", typ: 3.14 },
      { json: "grossIncome", js: "grossIncome", typ: 3.14 },
      { json: "netAdr", js: "netAdr", typ: 3.14 },
      { json: "netIncome", js: "netIncome", typ: 3.14 },
      { json: "numberOfReservationsReceived", js: "numberOfReservationsReceived", typ: 3.14 },
      { json: "numberOfVisits", js: "numberOfVisits", typ: 3.14 },
      { json: "occupancy", js: "occupancy", typ: 3.14 },
      { json: "platformCommissions", js: "platformCommissions", typ: 3.14 },
      { json: "sustainabilityFee", js: "sustainabilityFee", typ: 3.14 },
      { json: "temporaryStayDuty", js: "temporaryStayDuty", typ: 3.14 },
      { json: "vat", js: "vat", typ: 3.14 },
    ],
    false,
  ),
  GetReservationsResponse: o([{ json: "reservations", js: "reservations", typ: a(r("Reservation")) }], false),
  Reservation: o(
    [
      { json: "actualDaysOfStay", js: "actualDaysOfStay", typ: 3.14 },
      { json: "amountPaid", js: "amountPaid", typ: u(undefined, 3.14) },
      { json: "apartmentId", js: "apartmentId", typ: 3.14 },
      { json: "bookingDate", js: "bookingDate", typ: Date },
      { json: "cleanerId", js: "cleanerId", typ: u(undefined, 3.14) },
      { json: "cleaningCost", js: "cleaningCost", typ: u(undefined, 3.14) },
      { json: "cleaningFee", js: "cleaningFee", typ: u(undefined, 3.14) },
      { json: "commission", js: "commission", typ: 3.14 },
      { json: "endDate", js: "endDate", typ: Date },
      { json: "epsilon", js: "epsilon", typ: true },
      { json: "grossAdr", js: "grossAdr", typ: 3.14 },
      { json: "guestId", js: "guestId", typ: u(undefined, "") },
      { json: "guestName", js: "guestName", typ: "" },
      { json: "hostingExpense", js: "hostingExpense", typ: u(undefined, 3.14) },
      { json: "id", js: "id", typ: 3.14 },
      { json: "isTaxExempt", js: "isTaxExempt", typ: true },
      { json: "netAdr", js: "netAdr", typ: 3.14 },
      { json: "netIncome", js: "netIncome", typ: 3.14 },
      { json: "numberOfGuests", js: "numberOfGuests", typ: 3.14 },
      { json: "paymentDate", js: "paymentDate", typ: u(undefined, Date) },
      { json: "platform", js: "platform", typ: 3.14 },
      { json: "receivableIncome", js: "receivableIncome", typ: 3.14 },
      { json: "reservationCode", js: "reservationCode", typ: "" },
      { json: "startDate", js: "startDate", typ: Date },
      { json: "sustainabilityFee", js: "sustainabilityFee", typ: 3.14 },
      { json: "temporaryStayDuty", js: "temporaryStayDuty", typ: 3.14 },
      { json: "vat", js: "vat", typ: 3.14 },
    ],
    false,
  ),
  GetSavedGridViewsResponse: o([{ json: "views", js: "views", typ: a(r("ViewElement")) }], "any"),
  ViewElement: o(
    [
      { json: "columnStates", js: "columnStates", typ: a(r("ViewColumnState")) },
      { json: "gridName", js: "gridName", typ: "" },
      { json: "id", js: "id", typ: 3.14 },
      { json: "viewName", js: "viewName", typ: "" },
    ],
    "any",
  ),
  ViewColumnState: o(
    [
      { json: "aggFunc", js: "aggFunc", typ: u(null, "") },
      { json: "colId", js: "colId", typ: "" },
      { json: "flex", js: "flex", typ: u(0, null) },
      { json: "hide", js: "hide", typ: true },
      { json: "pinned", js: "pinned", typ: u(null, "") },
      { json: "pivot", js: "pivot", typ: true },
      { json: "pivotIndex", js: "pivotIndex", typ: u(0, null) },
      { json: "rowGroup", js: "rowGroup", typ: true },
      { json: "rowGroupIndex", js: "rowGroupIndex", typ: u(0, null) },
      { json: "sort", js: "sort", typ: u(null, "") },
      { json: "sortIndex", js: "sortIndex", typ: u(0, null) },
      { json: "width", js: "width", typ: 0 },
    ],
    "any",
  ),
  GetStatsForApartmentsResponse: o(
    [{ json: "apartmentStats", js: "apartmentStats", typ: a(r("ApartmentStats")) }],
    false,
  ),
  GetStatsForApartmentsWithDates: o(
    [{ json: "apartmentStats", js: "apartmentStats", typ: a(r("ApartmentStatsWithDate")) }],
    false,
  ),
  ApartmentStatsWithDate: o(
    [
      { json: "apartmentId", js: "apartmentId", typ: 3.14 },
      { json: "availableBookingDays", js: "availableBookingDays", typ: 3.14 },
      { json: "averageLengthOfStay", js: "averageLengthOfStay", typ: 3.14 },
      { json: "averageLidr", js: "averageLidr", typ: 3.14 },
      { json: "cleaningFees", js: "cleaningFees", typ: 3.14 },
      { json: "date", js: "date", typ: Date },
      { json: "daysBlocked", js: "daysBlocked", typ: 3.14 },
      { json: "daysBooked", js: "daysBooked", typ: 3.14 },
      { json: "grossAdr", js: "grossAdr", typ: 3.14 },
      { json: "grossIncome", js: "grossIncome", typ: 3.14 },
      { json: "netAdr", js: "netAdr", typ: 3.14 },
      { json: "netIncome", js: "netIncome", typ: 3.14 },
      { json: "numberOfReservationsReceived", js: "numberOfReservationsReceived", typ: 3.14 },
      { json: "numberOfVisits", js: "numberOfVisits", typ: 3.14 },
      { json: "occupancy", js: "occupancy", typ: 3.14 },
      { json: "platformCommissions", js: "platformCommissions", typ: 3.14 },
      { json: "sustainabilityFee", js: "sustainabilityFee", typ: 3.14 },
      { json: "temporaryStayDuty", js: "temporaryStayDuty", typ: 3.14 },
      { json: "vat", js: "vat", typ: 3.14 },
    ],
    "any",
  ),
  GetStatsForGroupResponse: o([{ json: "groupStats", js: "groupStats", typ: a(r("GroupStatsWithDate")) }], false),
  GroupStatsWithDate: o(
    [
      { json: "availableBookingDays", js: "availableBookingDays", typ: 3.14 },
      { json: "averageLengthOfStay", js: "averageLengthOfStay", typ: 3.14 },
      { json: "averageLidr", js: "averageLidr", typ: 3.14 },
      { json: "cleaningFees", js: "cleaningFees", typ: 3.14 },
      { json: "date", js: "date", typ: Date },
      { json: "daysBlocked", js: "daysBlocked", typ: 3.14 },
      { json: "daysBooked", js: "daysBooked", typ: 3.14 },
      { json: "grossAdr", js: "grossAdr", typ: 3.14 },
      { json: "grossIncome", js: "grossIncome", typ: 3.14 },
      { json: "groupId", js: "groupId", typ: 3.14 },
      { json: "netAdr", js: "netAdr", typ: 3.14 },
      { json: "netIncome", js: "netIncome", typ: 3.14 },
      { json: "numberOfReservationsReceived", js: "numberOfReservationsReceived", typ: 3.14 },
      { json: "numberOfVisits", js: "numberOfVisits", typ: 3.14 },
      { json: "occupancy", js: "occupancy", typ: 3.14 },
      { json: "platformCommissions", js: "platformCommissions", typ: 3.14 },
      { json: "sustainabilityFee", js: "sustainabilityFee", typ: 3.14 },
      { json: "temporaryStayDuty", js: "temporaryStayDuty", typ: 3.14 },
      { json: "vat", js: "vat", typ: 3.14 },
    ],
    "any",
  ),
  GetTotalDepositsWithDates: o(
    [{ json: "totalDeposits", js: "totalDeposits", typ: a(r("TotalDepositsWithDate")) }],
    false,
  ),
  TotalDepositsWithDate: o(
    [
      { json: "date", js: "date", typ: Date },
      { json: "deposit", js: "deposit", typ: 3.14 },
    ],
    "any",
  ),
  GetUserResponse: o([{ json: "user", js: "user", typ: r("User") }], "any"),
  GetYearsInOperationResponse: o([{ json: "years", js: "years", typ: a(0) }], false),
  ImportExpensesResponse: o(
    [{ json: "importExpensesResult", js: "importExpensesResult", typ: r("ImportExpensesResult") }],
    "any",
  ),
  ImportExpensesResult: o(
    [
      { json: "DeletedExpenseItems", js: "DeletedExpenseItems", typ: 0 },
      { json: "newExpenseItems", js: "newExpenseItems", typ: 0 },
    ],
    "any",
  ),
  ImportReservationsResponse: o(
    [{ json: "importReservationsResult", js: "importReservationsResult", typ: r("ImportReservationsResult") }],
    "any",
  ),
  ImportReservationsResult: o(
    [
      { json: "deletedReservations", js: "deletedReservations", typ: 3.14 },
      { json: "newReservations", js: "newReservations", typ: 3.14 },
      { json: "updatedReservations", js: "updatedReservations", typ: 3.14 },
    ],
    "any",
  ),
  UploadToMyDataResponse: o(
    [{ json: "myDataUploadResponse", js: "myDataUploadResponse", typ: a(r("MyDataUploadResponse")) }],
    false,
  ),
  MyDataUploadResponse: o(
    [
      { json: "error", js: "error", typ: "" },
      { json: "invoiceUUID", js: "invoiceUUID", typ: "" },
      { json: "taxInvoiceUUID", js: "taxInvoiceUUID", typ: "" },
    ],
    "any",
  ),
};
