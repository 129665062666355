import React from "react";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { dateFormatter, euroFormatterTwoDecimals, paramsEuroFormatter } from "../utils/formatters";
import Grid, { GridButton, GridDropdownFilter, SelectedRowsActions } from "../lib/organisms/Grid";

export interface ReservationRow {
  id: number;
  apartment: string;
  platform: string;
  reservationCode: string;
  bookingDate: Date;
  startDate: Date;
  endDate: Date;
  actualDaysOfStay: number;
  guestName: string;
  numberOfGuests: number;
  receivableIncome: number;
  netIncome: number;
  grossAdr: number;
  netAdr: number;
  vat: number;
  sustainabilityFee: number;
  temporaryStayDuty: number;
  amountPaid?: number;
  paymentDate?: Date;
  commission: number;
  cleaningFee?: number;
  cleaningCost?: number;
  hostingExpense?: number;
  epsilon?: boolean;
}

export interface ReservationPageProps {
  reservations: ReservationRow[];
  gridDropdownFilters?: GridDropdownFilter[];
  gridButtons?: GridButton[];
  reservationActions?: SelectedRowsActions[];
  gridRef?: React.RefObject<any>;
  extraColumnDefinitions?: ColDef[];
}

export const ReservationsPage: React.FC<ReservationPageProps> = ({
  gridButtons,
  gridDropdownFilters,
  reservationActions,
  reservations,
  gridRef,
  extraColumnDefinitions = [],
}: ReservationPageProps) => {
  const gridName = "Reservations";

  const amountPaidColumn: ColDef = {
    field: "amountPaid",
    headerName: "Paid Amount",
    cellStyle: { textAlign: "right" },
    valueFormatter: paramsEuroFormatter,
    width: 120,
    cellDataType: "number",
    type: ["rightAligned", "euroAmount"],
  };
  const grossIncomeColumn: ColDef = {
    field: "receivableIncome",
    headerName: "Gross Income",
    cellStyle: { textAlign: "right" },
    valueFormatter: paramsEuroFormatter,
    width: 120,
    cellDataType: "number",
    type: ["rightAligned", "euroAmount"],
  };
  const cleaningFeeColumn = {
    field: "cleaningFee",
    headerName: "Cleaning Fee",
    cellStyle: { textAlign: "right" },
    valueFormatter: paramsEuroFormatter,
    width: 90,
    cellDataType: "number",
    type: ["rightAligned", "euroAmount"],
  };

  const actualDaysColumn: ColDef = { field: "actualDaysOfStay", headerName: "Actual Days" };
  const columnDefinitions: ColDef[] = [
    { field: "reservationCode", headerName: "Code", checkboxSelection: true },
    { field: "apartment", headerName: "Apartment" },
    { field: "platform", headerName: "Platform" },
    { field: "bookingDate", headerName: "Booking Date", valueFormatter: dateFormatter },
    { field: "startDate", headerName: "Start Date", valueFormatter: dateFormatter },
    { field: "endDate", headerName: "End Date", valueFormatter: dateFormatter },
    actualDaysColumn,
    { field: "guestName", headerName: "Guest" },
    { field: "numberOfGuests", headerName: "N. Guests" },
    grossIncomeColumn,
    amountPaidColumn,
    { field: "paymentDate", headerName: "Payment Date", valueFormatter: dateFormatter, width: 90 },
    {
      field: "commission",
      headerName: "Commission",
      cellStyle: { textAlign: "right" },
      valueFormatter: paramsEuroFormatter,
      width: 90,
      cellDataType: "number",
      type: ["rightAligned", "euroAmount"],
    },
    cleaningFeeColumn,
    { field: "epsilon", headerName: "Epsilon", width: 90 },
    { field: "netIncome", headerName: "Net Income", valueFormatter: paramsEuroFormatter },
    { field: "grossAdr", headerName: "Gross Adr", valueFormatter: paramsEuroFormatter },
    { field: "netAdr", headerName: "Net Adr", valueFormatter: paramsEuroFormatter },
    { field: "vat", headerName: "VAT", valueFormatter: paramsEuroFormatter },
    { field: "sustainabilityFee", headerName: "Sustainability Fee", valueFormatter: paramsEuroFormatter },
    { field: "temporaryStayDuty", headerName: "Temporary Stay Duty", valueFormatter: paramsEuroFormatter },
  ];

  let columnDefinitionsWithExtra = [...columnDefinitions, ...extraColumnDefinitions];

  let optionalProps = {};
  if (gridButtons) {
    optionalProps = { buttons: gridButtons };
  }
  if (reservationActions) {
    optionalProps = { ...optionalProps, actions: reservationActions };
  }

  return (
    <Grid<ReservationRow>
      columnDefs={columnDefinitionsWithExtra}
      dropdownFilters={gridDropdownFilters}
      gridName={gridName}
      gridRef={gridRef}
      initialRowData={reservations}
      {...optionalProps}
      columnsToAggregate={[
        { colDef: actualDaysColumn, formatter: (x) => x },
        { colDef: grossIncomeColumn, formatter: euroFormatterTwoDecimals },
        { colDef: amountPaidColumn, formatter: euroFormatterTwoDecimals },
      ]}
    />
  );
};

export default ReservationsPage;
