import React, {FC} from "react";


interface TextWithSvgProps {
    text: string;
    svgPathData: string;
    className?: string;
    svgClassName?: string;
}

const TextWithSvg: FC<TextWithSvgProps> = ({text, svgPathData, className, svgClassName}) => {
    return (
        <div className={`d-flex align-items-center ${className}`}>
            <svg className={`icon ${svgClassName}`} fill="currentColor"
                 viewBox="0 0 20 20"
                 xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd"
                      d={svgPathData}
                      clipRule="evenodd"></path>
            </svg>
            <span className="ms-2">{text}</span>
        </div>
    )
}

export default TextWithSvg;