import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { SelectOption } from "../lib/molecules/select";
import { Apartment, ApartmentGroup, ApartmentOwner, Convert, ExpenseCategory } from "../models/responses";
import { fetchOptions, useAuth } from "./useAuth";
import { PeriodName } from "../models";
import { apiUrl } from "../layouts/userLayout";

type LoggedInUserContextType = {
  context: AdminContextType | UserContextType;
};

export type AdminContextType = {
  selectedYear: number;
  setSelectedYear: (year: number) => void;
  selectedPeriod: string;
  setSelectedPeriod: (periodName: PeriodName) => void;
  selectedOwner: number;
  setSelectedOwner: (ownerId: number) => void;
  aggregateMethod: "sum" | "avg";
  setAggregateMethod: (method: "sum" | "avg") => void;
  aggregateMethodOptions: SelectOption[];
  periodOptions: SelectOption[];
  yearOptions: SelectOption[];
  ownerOptions: SelectOption[];
  ownerIdMap: Map<number, ApartmentOwner>;
  expenseCategoriesMap: Map<number, ExpenseCategory>;
  apartmentIdMap: Map<number, Apartment>;

  apartmentOptions: SelectOption[];
  groupIdMap: Map<number, ApartmentGroup>;
  selectedGroup: number;
  setSelectedGroup: (group: number) => void;
  groupOptions: SelectOption[];
  selectedApartment: number;
  setSelectedApartment: (apartment: number) => void;
};

export type UserContextType = {
  selectedYear: number;
  setSelectedYear: (year: number) => void;
  selectedPeriod: string;
  setSelectedPeriod: (periodName: PeriodName) => void;
  selectedOwner: number;
  setSelectedOwner: (ownerId: number) => void;
  aggregateMethodOptions: SelectOption[];
  aggregateMethod: "sum" | "avg";
  setAggregateMethod: (method: "sum" | "avg") => void;
  periodOptions: SelectOption[];
  yearOptions: SelectOption[];
  ownerOptions: SelectOption[];
  ownerIdMap: Map<number, ApartmentOwner>;
  expenseCategoriesMap: Map<number, ExpenseCategory>;
  apartmentIdMap: Map<number, Apartment>;
  apartmentOptions: SelectOption[];
  selectedApartment: number;
  setSelectedApartment: (apartment: number) => void;
};

const UserContext = createContext<LoggedInUserContextType>({
  context: {
    selectedYear: 0,
    setSelectedYear: () => {},
    selectedPeriod: "",
    setSelectedPeriod: () => {},
    selectedOwner: 0,
    setSelectedOwner: () => {},
    selectedApartment: 0,
    setSelectedApartment: () => {},
    aggregateMethodOptions: [],
    apartmentOptions: [],
    aggregateMethod: "sum",
    setAggregateMethod: () => {},
    periodOptions: [],
    yearOptions: [],
    ownerOptions: [],
    ownerIdMap: new Map(),
    apartmentIdMap: new Map(),
    expenseCategoriesMap: new Map(),
  },
});

export const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuth();
  const [selectedYear, setSelectedYear] = useState<number>(
    localStorage.getItem("selectedYear")
      ? parseInt(localStorage.getItem("selectedYear") as string)
      : new Date().getFullYear(),
  );
  const [selectedPeriod, setSelectedPeriod] = useState<PeriodName>(
    localStorage.getItem("selectedPeriod") ? (localStorage.getItem("selectedPeriod") as PeriodName) : PeriodName.Year,
  );

  function getSelectedOwner(): number {
    if (!user || !user.visibleOwners) return 0;
    let selectedOwner = localStorage.getItem("selectedOwner");
    let visibleOwners: number[] = user?.visibleOwners;
    if (selectedOwner) {
      if (visibleOwners.includes(parseInt(selectedOwner))) {
        return parseInt(selectedOwner);
      } else {
        return visibleOwners[0];
      }
    }
    return visibleOwners[0];
  }

  const [selectedOwner, setSelectedOwner] = useState<number>(getSelectedOwner());
  const [selectedGroup, setSelectedGroup] = useState<number>(
    localStorage.getItem("selectedGroup") ? parseInt(localStorage.getItem("selectedGroup") as string) : 0,
  );
  const [selectedApartment, setSelectedApartment] = useState<number>(
    localStorage.getItem("selectedApartment") ? parseInt(localStorage.getItem("selectedApartment") as string) : 0,
  );
  const [aggregateMethod, setAggregateMethod] = useState<"sum" | "avg">("sum");
  const [periodOptions, setPeriodOptions] = useState<SelectOption[]>([]);
  const [yearOptions, setYearOptions] = useState<SelectOption[]>([]);
  const [ownerOptions, setOwnerOptions] = React.useState<SelectOption[]>([]);
  const [ownerIdMap, setOwnerIdMap] = useState<Map<number, ApartmentOwner>>(new Map());
  const [groupIdMap, setGroupIdMap] = useState<Map<number, ApartmentGroup>>(new Map());
  const [apartmentGroupOptions, setApartmentGroupOptions] = useState<SelectOption[]>([]);
  const [apartmentIdMap, setApartmentIdMAp] = React.useState<Map<number, Apartment>>(new Map());
  const [apartmentOptions, setApartmentOptions] = React.useState<SelectOption[]>([]);
  const [expenseCategoriesMap, setExpenseCategoriesMap] = useState<Map<number, ExpenseCategory>>(new Map());

  const handleYearSelectionChanged = (year: number) => {
    setSelectedYear(year);
    localStorage.setItem("selectedYear", year.toString());
  };

  const handleGroupSelectionChanged = (group: number) => {
    setSelectedGroup(group);
    localStorage.setItem("selectedGroup", group.toString());
  };

  const handlePeriodSelectionChanged = (period: PeriodName) => {
    console.log(period);
    setSelectedPeriod(period);
    localStorage.setItem("selectedPeriod", period);
  };

  const handleOwnerSelectionChanged = (owner: number) => {
    setSelectedOwner(owner);
    localStorage.setItem("selectedOwner", owner.toString());
  };

  const handleApartmentSelectionChanged = (apartment: number) => {
    setSelectedApartment(apartment);
    localStorage.setItem("selectedApartment", apartment.toString());
  };

  const setYearsOptions = useCallback(() => {
    fetch(`${apiUrl}/api${user?.isAdmin ? "/admin/" : "/"}stats/years`, fetchOptions)
      .then((response) => {
        if (response) {
          return response.text();
        }
        throw new Error("Unauthorized");
      })
      .then((response) => {
        const years = Convert.toGetYearsInOperationResponse(response).years.sort((a, b) => a - b);
        const selectOptions = years.map((year) => ({
          value: year.toString(),
          label: year.toString(),
        }));
        setYearOptions(selectOptions);
      })
      .catch((error) => console.error("Error:", error));
  }, [user]);

  const setApartments = useCallback(() => {
    fetch(`${apiUrl}/api${user?.isAdmin ? "/admin/" : "/"}apartments`, fetchOptions)
      .then((response) => {
        if (response) {
          return response.text();
        }
        throw new Error("Unauthorized");
      })
      .then((response) => {
        const apartments = Convert.toGetApartmentsResponse(response).apartments;
        const apartmentOptions: SelectOption[] = [];
        const apartmentIdMap = new Map<number, Apartment>();
        for (let apartment of apartments) {
          apartmentOptions.push({
            value: apartment.id.toString(),
            label: apartment.name,
          });
          apartmentIdMap.set(apartment.id, apartment);
        }
        apartmentOptions.unshift({ value: "0", label: "All" });
        setApartmentOptions(apartmentOptions);
        setApartmentIdMAp(apartmentIdMap);
      })
      .catch((error) => console.error("Error:", error));
  }, [user]);

  const setOwners = useCallback(() => {
    fetch(`${apiUrl}/api${user?.isAdmin ? "/admin/" : "/"}owners`, fetchOptions)
      .then((response) => {
        if (response) {
          return response.text();
        }
        throw new Error("Unauthorized");
      })
      .then((response) => {
        const apartmentOwners = Convert.toGetApartmentOwnersResponse(response).owners;
        setOwnerIdMap(new Map(apartmentOwners.map((owner) => [owner.id, owner])));
        var owners = apartmentOwners
          .sort((o1, o2) => o1.fullName.localeCompare(o2.fullName))
          .map((owner) => ({
            value: owner.id.toString(),
            label: owner.fullName,
          }));
        setOwnerOptions(owners);
        if (owners.length > 0) {
          handleOwnerSelectionChanged(parseInt(owners[0].value));
        }
      })
      .catch((error) => console.error("Error:", error));
  }, [user]);

  const setExpenseCategories = useCallback(() => {
    fetch(`${apiUrl}/api/expense-categories`, fetchOptions)
      .then((response) => {
        if (response) {
          return response.text();
        }
        throw new Error("Unauthorized");
      })
      .then((response) => {
        const categories = Convert.toGetExpenseCategoriesResponse(response).expenseCategories;
        const categoriesMap = new Map<number, ExpenseCategory>();
        for (let category of categories) {
          categoriesMap.set(category.id, category);
        }
        setExpenseCategoriesMap(categoriesMap);
      })
      .catch((error) => console.error("Error:", error));
  }, [user]);

  const setGroups = useCallback(() => {
    fetch(`${apiUrl}/api/admin/groups`, fetchOptions)
      .then((response) => {
        if (response) {
          return response.text();
        }
        throw new Error("Unauthorized");
      })
      .then((response) => {
        let groups = Convert.toGetApartmentGroupsResponse(response).groups;
        setGroupIdMap(new Map(groups.map((group) => [group.id, group])));
        groups.unshift({ id: 0, name: "All", description: "All" });
        setApartmentGroupOptions(
          groups.map((group) => ({
            value: group.id.toString(),
            label: group.name,
          })),
        );
      })
      .catch((error) => console.error("Error:", error));
  }, [user]);

  useEffect(() => {
    setPeriodOptions(
      Object.keys(PeriodName).map((period) => ({
        value: period,
        label: PeriodName[period as keyof typeof PeriodName],
      })),
    );
    if (user) {
      setYearsOptions();
      setApartments();
      setOwners();
      setExpenseCategories();
      if (user.isAdmin) {
        setGroups();
      }
      // Other API calls here
    }
  }, [setApartments, setExpenseCategories, setOwners, setYearsOptions, user]);

  const value = useMemo(
    function () {
      let context = {
        selectedYear,
        setSelectedYear: handleYearSelectionChanged,
        selectedPeriod,
        setSelectedPeriod: handlePeriodSelectionChanged,
        selectedOwner,
        setSelectedOwner: handleOwnerSelectionChanged,
        selectedApartment: selectedApartment,
        setSelectedApartment: handleApartmentSelectionChanged,
        aggregateMethodOptions: [
          { value: "sum", label: "Sum" },
          { value: "avg", label: "Average" },
        ],
        aggregateMethod,
        setAggregateMethod,
        periodOptions,
        ownerOptions,
        yearOptions,
        ownerIdMap,
        apartmentOptions,
        apartmentIdMap,
        expenseCategoriesMap,
      };

      if (user?.isAdmin) {
        return {
          context: {
            ...context,
            selectedGroup: selectedGroup,
            setSelectedGroup: handleGroupSelectionChanged,
            groupOptions: apartmentGroupOptions,
            groupIdMap: groupIdMap,
          },
        };
      } else {
        return { context: context };
      }
    },
    [
      aggregateMethod,
      apartmentGroupOptions,
      apartmentIdMap,
      apartmentOptions,
      expenseCategoriesMap,
      groupIdMap,
      ownerIdMap,
      ownerOptions,
      periodOptions,
      selectedApartment,
      selectedGroup,
      selectedOwner,
      selectedPeriod,
      selectedYear,
      setGroups,
      user?.isAdmin,
      yearOptions,
    ],
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = (): LoggedInUserContextType => {
  return useContext<LoggedInUserContextType>(UserContext);
};
